import React from 'react';
import { Button, HTMLTable, NonIdealState } from "@blueprintjs/core";
import ScheduledMessageData from "data-modules/ScheduledMessageData"
import AppData from "data-modules/AppData"
import moment from 'moment';

export default class UserPendingNotifications extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pendingNotifications: [],
            offsetDuration: 15
        }

        this.componentDidMount = this.componentDidMount.bind(this);
        this.getPendingEventNotifications = this.getPendingEventNotifications.bind(this);
        // this.createNotification = this.createNotification.bind(this);    
        this.deleteNotification = this.deleteNotification.bind(this);
        this.setOffsetDuration = this.setOffsetDuration.bind(this)

        this.getPendingEventNotifications();
    }

    componentDidMount() {
        this.getPendingEventNotifications();
    }
    
    setOffsetDuration(evt) {
        this.setState({ offsetDuration: evt.target.value });
    }

    getPendingEventNotifications() {
        ScheduledMessageData.getPendingSelfNotifications().then(({ rows, count }) => {
            this.setState({ pendingNotifications: rows });
        }).catch((err) => { console.log(err) });
    }

    deleteNotification(id) { 
        if(confirm("Are you sure you want to delete this notification?")) {
            ScheduledMessageData.deleteNotification(id).then(() => {  
                this.getPendingEventNotifications(); 
            }).catch((err) => { console.log(err) });
        }
    }

    render() {
        let pendingNotificationElements = <tr><td colSpan="3"> <NonIdealState icon="search" title="No Pending Notifications" description="You have no messages/notifications scheduled to be sent"/> </td></tr>
        
        if(this.state.pendingNotifications.length > 0) {
            pendingNotificationElements = this.state.pendingNotifications.map((row, index) => {
                return (<tr key={`notification_${index}`}>
                    <td>{moment(row.send_date).tz(AppData.timezone()).format("YYYY-MM-DD HH:mm")}</td>
                    <td>{row.message_title}</td>
                    <td><Button style={{ margin: 4 }} intent="danger" icon="delete" onClick={this.deleteNotification.bind(null, row.id)}>Delete</Button></td>
                </tr>); 
             });
        }
        
        return <div style={{ width: "100%" }}>
            <HTMLTable style={{ width: "100%" }}>
                <thead>
                    <tr>
                        <th> Send On </th>
                        <th> Title </th>
                        <th> Remove </th>
                    </tr>
                </thead>
                <tbody>
                    {pendingNotificationElements}
                </tbody>
            </HTMLTable>
        </div>
    }
}
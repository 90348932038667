
'use strict';

import React from "react";
import CustomFormData from "data-modules/CustomFormData"
import moment from "moment";
import AppData from "data-modules/AppData";
import { Classes, Spinner, Button, InputGroup, FormGroup, TextArea, NumericInput, RadioGroup, Radio, Dialog, HTMLTable } from "@blueprintjs/core";

class MyServiceEntryList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            forms: [],
            status_filter: "received",
            search_value: ""
        };

        this.componentDidMount = this.componentDidMount.bind(this)
        this.updateStatusFilter = this.updateStatusFilter.bind(this)
        this.updateSearchValue = this.updateSearchValue.bind(this)
    }

    componentDidMount() {
        let self = this;
        CustomFormData.getMyForms("received").then((forms) => {
            self.setState({ forms: forms })
        });
    }

    updateStatusFilter(event) {
        let self = this;
        let new_value = event.target.value;
        CustomFormData.getMyForms(new_value).then((forms) => {
            self.setState({ forms: forms, status_filter: new_value })
        });
    }

    updateSearchValue(event) {
        let self = this;
        self.setState({ search_value: event.target.value });
    }

    openForm(form) {
        window.open(`#/form/detail/${form.id}`, "_blank");
    }

    render() {
        let self = this;
        let rows = self.state.forms.filter((form) => {
            if(self.state.search_value.length < 1) { return true; }
            let hasMatch = false;
            if(form.service.name.includes(self.state.search_value)) { hasMatch = true; }
            if (form.user.full_name.includes(self.state.search_value)) { hasMatch = true; }
            if (form.custom_form_config.config_data.title.includes(self.state.search_value)) { hasMatch = true; }
            if (form.processing_status.includes(self.state.search_value)) { hasMatch = true; }
            if (moment(form.createdAt).format("YYYY-MM-DD HH:mm").includes(self.state.search_value)) { hasMatch = true; }
            return hasMatch;
        }).map((form, index) => {
            return (<tr key={`row_${index}`} onClick={self.openForm.bind(null, form)}>
                <td> {form.id} </td>
                <td> {form.service.name} </td>
                <td> {form.user.full_name} </td>
                <td> {form.custom_form_config.config_data.title} </td>
                <td> {form.processing_status} </td>
                <td> {moment(form.createdAt).format("YYYY-MM-DD HH:mm")} </td>
            </tr>);
        })
        return (<div style={{ width: "100%", paddingLeft: "10%", paddingRight: "10%", paddingTop: 20 }}>
            <h2 className={Classes.HEADING}> My Submitted Forms </h2>
            <div>
                <RadioGroup inline={true} label="Filter by Processing Status" selectedValue={self.state.status_filter} onChange={self.updateStatusFilter}>
                    <Radio label="All Active" value="active" />
                    <Radio label="Received" value="received" />
                    <Radio label="In Process" value="in_process" />
                    <Radio label="Completed" value="completed" />
                    <Radio label="Archived" value="archived" />
                </RadioGroup>
            </div>
            <InputGroup style={{ width: "100%" }} placeholder="Filter for value..." type="text" onChange={self.updateSearchValue} value={self.state.search_value} leftIcon="filter" />
            <HTMLTable striped={true} interactive={true} style={{width: "100%"}}>
                <thead>
                    <tr>
                        <th> ID </th>
                        <th> Service </th>
                        <th> Submitter </th>
                        <th> Form </th>
                        <th> Status </th>
                        <th> Received </th>
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </HTMLTable>
        </div>)
    }
}
import withRouter from "lib/withRouter";
let Page = withRouter(MyServiceEntryList)
export default Page;

import React from 'react'
import AppData from 'data-modules/AppData'
import UserData from 'data-modules/UserData'
import { Dialog, H1, H2, AnchorButton, Classes, Callout } from "@blueprintjs/core"
import { DatePicker } from "@blueprintjs/datetime";
import EventData from "data-modules/EventData"
import ServiceRateData from "data-modules/ServiceRateData"
import MetricsData from "data-modules/MetricsData";
import DataManager from "data-modules/DataManager"
let DM = new DataManager();
import QueryString from "query-string"
import FullCalendar from '@fullcalendar/react'
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import interactionPlugin from '@fullcalendar/interaction';
import moment from "moment-timezone";
import "../common/calendar.css"
import EventDetailForm from "../common/eventDetailForm"
import csscolors from "css-color-names";
import hexRgb from "hex-rgb";
import { service_types } from "data-modules/Enum"


class GroupCalendarPage extends React.Component {
    constructor(props) {
        super(props)
        let query = QueryString.parse(props.location.search);
        this.calendarRef = React.createRef()
        this.state = {
            current_date: moment(),
            resources: [],
            events: [],
            group: { name: "Loading" },
            resource_map: {},
            slot_duration: "00:30:00",
            isCreating: false,
            isEditing: false,
            isError: false,
            isPickingDate: false,
            actionInProgress: false,
        }

        this.componentDidMount = this.componentDidMount.bind(this)
        this.eventsLoaded = this.eventsLoaded.bind(this);
        this.eventCreated = this.eventCreated.bind(this);
        this.eventUpdated = this.eventUpdated.bind(this);
        this.eventDeleted = this.eventDeleted.bind(this);
        this.dateClicked = this.dateClicked.bind(this);
        this.eventClicked = this.eventClicked.bind(this);
        this.onCreate = this.onCreate.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onError = this.onError.bind(this);
        this.fetchEvents = this.fetchEvents.bind(this);

        this.reloadInterval = null;
    }

    fetchEvents(new_current) {
        let params = this.props.params;
        if (new_current) {
            EventData.eventsForGroup(params.group_id, {
                start: moment(new_current).add(-2, "week"),
                end: moment(new_current).add(2, "week"),
                current: moment(new_current)
            }).catch((err) => {});
            this.setState({ current_date: moment(new_current) })
        } else {
            EventData.eventsForGroup(params.group_id, {
                start: moment(this.state.current_date).add(-1, "week"),
                end: moment(this.state.current_date).add(1, "week"),
                current: moment(this.state.current_date)
            }).catch((err) => {});
        }

    }

    componentDidMount() {
        let params = this.props.params;
        let self = this;
        
        DM.add(EventData.registerListener("EVENTS_LOADED", this.eventsLoaded), EventData);
        DM.add(EventData.registerListener("ORDER_CREATED", this.eventCreated), EventData);
        DM.add(EventData.registerListener("RECORD_UPDATED", this.eventUpdated), EventData);
        DM.add(EventData.registerListener("ORDER_DELETED", this.eventDeleted), EventData);
        // DM.add(EventData.registerListener("ORDER_CREATE_ERROR", this.onError), EventData);
        // DM.add(EventData.registerListener("ORDER_DELETE_ERROR", this.onError), EventData);
        this.fetchEvents();

        this.reloadInterval = setInterval(() => { self.fetchEvents(); }, (1 * 60 * 1000))
        MetricsData.recordClientMetric({
            metric: "component-mount",
            feature: "multi-services-calendar",
            numerical_value: 1,
            precision: 0,
            data: { group_id: params.group_id }
        })
    }
    componentWillUnmount() {
        clearInterval(this.reloadInterval);
        DM.clear();
    }

    eventsLoaded(results) {
        if(results.resources.length < 1) { return; }
        let smallest_duration = results.resources.reduce((smallest, current) => {
            if (current.duration < smallest) { return current.duration }
            return smallest;
        }, results.resources[0].duration)
        let pad = (string, target_size, padding) => {
            if(string.length < target_size) { 
                return `${Array(target_size - string.length).fill(padding).join("")}${string}`; 
            }
            return string;
        }
        let minutes = pad(`${Math.floor(smallest_duration % 60)}`, 2, "0")
        let hours = pad(`${Math.floor(smallest_duration / 60)}`, 2, "0")
        let slot_duration = `${hours}:${minutes}:00`
        if(smallest_duration > 60) { slot_duration = '01:00:00'}
        if (smallest_duration <= 15) { slot_duration = '00:15:00'  }
        this.setState({
            resources: results.resources,
            events: results.events,
            group: results.group,
            slot_duration: slot_duration,
            resource_map: results.resources.reduce((a, r, i) => {
                a[r.id] = i;
                return a;
            }, {})
        })
    }

    dateClicked(params) {
        const self = this;
        const resource = self.state.resources[self.state.resource_map[params.resource.id]];
        if(resource.service.type == service_types.WALKUP ) { return; }
        
        // This coersion fixes an issue with FullCalendar.io not having proper timezone support.
        let utcDate = moment(params.date).subtract(moment(params.date).tz(AppData.timezone()).utcOffset(), "minutes").toDate()
        params.date = utcDate
        // End Coersion

        EventData.canScheduleAtDate(params.date, resource.service).then(() => {
            return UserData.getRecord(AppData.get("userID"));
        }).then((user) => {
            return ServiceRateData.getRecordList(resource.service.id).then((rates) => {
                return { user: user, rates: rates };
            });
        }).then(({user, rates}) => {
            params.defaultUser = user;
            params.serviceRates = rates;
            params.defaultAccount = null;
            self.setState({ isCreating: params, isEditing: false, isError: false });
        }).catch((err) => {
            console.log(err)
            self.setState({ isCreating: false, isEditing: false, isError: err });
        })

        
    }

    eventClicked(params) {
        let self = this;
        EventData.getRecord(params.event.id).then((eventData) => {
            params.eventData = eventData
            return ServiceRateData.getRecordList(eventData.service.id).then((rates) => {
                params.serviceRates = rates;
                return params;
            })
        }).then((compiledParams) => {
            self.setState({ isEditing: compiledParams, isCreating: false, isError: false });
        }).catch((err) => {
            console.log(err)
            self.setState({ isCreating: false, isEditing: false, isError: err });
        })
        
    }

    onCreate(new_event_data) {
        this.setState({ actionInProgress: true })
        EventData.createRecord(new_event_data).then((event) => {
            if(event.service.reopen_event_on_create === 1) {
                this.eventClicked({ event: event })
            }
        }).catch((err) => {});
    }

    onUpdate(updated_event_data) {
        this.setState({ actionInProgress: true })
        EventData.updateRecord(updated_event_data).catch((err) => {});
    }

    onDelete(event) {
        if(confirm("Are you sure you want to delete this event?")) {
            this.setState({ actionInProgress: true })
            EventData.deleteRecord(event).catch((err) => {});
        }
        
    }

    eventCreated() {
        this.setState({ isCreating: false, isEditing: false, isError: false, actionInProgress: false });
        this.fetchEvents();
    }

    eventDeleted() {
        this.setState({ isCreating: false, isEditing: false, isError: false, actionInProgress: false });
        this.fetchEvents();
    }

    eventUpdated() {
        this.setState({ isCreating: false, isEditing: false, isError: false, actionInProgress: false });
        this.fetchEvents();
    }

    onError (error) {
        this.setState({ isCreating: false, isEditing: false, isError: { message: JSON.stringify(error) }, actionsInProgress: false });
        this.fetchEvents();
    }

    render() {
        let self = this;

        let displayElement = <div className="crs calendar container"> <H2> Loading Services... </H2> </div>
        if(self.state.group.name !== "Loading" && self.state.resources.length == 0) { return <Callout intent="warning" style={{ margin: 10 }}> No Scheduled Resources Available for this Group </Callout> }
        if(self.state.resources.length > 0) {
            
            let activeView = "resourceTimelineDay";
            if (self.state.resources.length < 8) { 
                activeView =  "resourceTimeGridDay";
                contentHeight = {};
            }
            
            displayElement = (<FullCalendar
                timeZone={AppData.timezone()}
                resourceOrder={"-displayWeight"}
                ref={this.calendarRef}
                initialView={activeView}
                height={"100%"}
                plugins={[resourceTimeGridPlugin, timeGridPlugin, interactionPlugin, resourceTimelinePlugin]}
                resources={self.state.resources}
                events={self.state.events}
                schedulerLicenseKey={AppData.get("fullcalendar_key")}
                dateClick={self.dateClicked}
                eventClick={self.eventClicked}
                slotDuration={self.state.slot_duration}
                contentHeight={"auto"}
                titleFormat={{
                    month: 'long',
                    year: 'numeric',
                    day: 'numeric',
                    weekday: 'long'
                }}
                customButtons={{
                    datePicker: {
                        text: "pick day",
                        click: () => { self.setState({ isPickingDate: true }); }
                    },
                    allServices: {
                        text: "all services",
                        click: () => { window.location = `#/group-calendar/${self.state.group.id}`; }
                    }
                }}
                headerToolbar={{
                    right: "today datePicker allServices prev,next"
                }}
                eventTimeFormat={{
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false
                }}
                datesSet={(info) => {
                    this.fetchEvents(moment(info.view.currentStart));
                }}
                resourceLaneContent={(arg) => {
                    let resource_color = arg.resource.extendedProps.service.color;
                    return <div style={{ backgroundColor: resource_color || "black", opacity: 0.25, width: "100%", height: "5px" }}></div>;
                }}
                resourceLabelContent={(arg) => {
                    
                    let resource_color = arg.resource.extendedProps.service.color;
                    
                    if (!RegExp("^#").test(resource_color)) {
                        resource_color = csscolors[resource_color] || "#ffffff";
                    }
                    let rgba = "#ffffff"
                    try {
                        rgba = hexRgb(resource_color.trim()+"66", { format: "css" });
                    } catch (error) {
                        alert("Invalid Color for Resource: " + info.resource.extendedProps.service.name);
                        console.log(resource_color, error);
                    }
                    return <AnchorButton minimal={true} style={{ padding: 5, backgroundColor: rgba, width: "100%", fontWeight: "bold" }} href={`#/service-calendar/${arg.resource.id}`}> {arg.resource.title} </AnchorButton>;
                }}
                
            />)
        }

        let contentHeight = ((self.state.resources.length + 2) * 45) + 200;

        return (<div style={{ width: `100%`, height: contentHeight }}>
            <div className="crs calendar title">
                <H1>{self.state.group.name} Services</H1>
            </div>
            <div className="crs calendar container">
                {displayElement}
            </div>
            <div>
                <Dialog isOpen={self.state.isCreating !== false}
                    title={"Create New Event"}
                    onClose={() => { self.setState({ isCreating: false }) }}>
                    <div className={Classes.DIALOG_BODY}>
                        <EventDetailForm 
                            clickParams={self.state.isCreating} 
                            resources={this.state.resources}
                            onCancel={() => { self.setState({ isCreating: false })}}
                            onSubmit={self.onCreate}
                            actionInProgress={self.state.actionInProgress}
                        />
                    </div>
                </Dialog>

                <Dialog 
                    title={(self.state.isEditing !== false ? `Editing ${self.state.isEditing.eventData.service.name} (${self.state.isEditing.eventData.order_id}-${self.state.isEditing.eventData.id})`: "Editing Event")} 
                    isOpen={self.state.isEditing !== false} 
                    onClose={() => { self.setState({ isEditing: false }) }}>
                    <div className={Classes.DIALOG_BODY}>
                        <EventDetailForm
                            clickParams={self.state.isEditing}
                            resources={this.state.resources}
                            onCancel={() => { self.setState({ isEditing: false }) }}
                            onSubmit={self.onUpdate}
                            onDelete={self.onDelete}
                            actionInProgress={self.state.actionInProgress}
                        />
                    </div>
                </Dialog>

                <Dialog isOpen={self.state.isError !== false} title="Event Edit/Creation Error" onClose={() => { self.setState({ isError: false }) }}>
                    <div className={Classes.DIALOG_BODY}>
                        {self.state.isError.message}
                    </div>
                </Dialog>

                <Dialog isOpen={self.state.isPickingDate !== false} title="Pick Date" onClose={() => { self.setState({ isPickingDate: false }) }}>
                    <div className={Classes.DIALOG_BODY}>
                        <DatePicker 
                            maxDate={moment().add(2, "years").toDate()} 
                            onChange={(newDate) => {
                                if(newDate !== null) {
                                    let calendarAPI = this.calendarRef.current.getApi()
                                    calendarAPI.gotoDate(newDate);
                                }
                            }}/>
                    </div>
                </Dialog>
            </div>
        </div>
        );
    }
}

import withRouter from "lib/withRouter";
let Page = withRouter(GroupCalendarPage)
export default Page;


'use strict';
import React from 'react';
import DataManager from "data-modules/DataManager"
import MetricsData from "data-modules/MetricsData"
let DM = new DataManager();
import GroupPermissionData from "data-modules/GroupPermissionData"
import UserData from "data-modules/UserData";
import { HTMLSelect, Button, Spinner, InputGroup, ProgressBar } from '@blueprintjs/core';
import DebounceManager from "lib/DebounceManager"

class ManagerGroupPermissionManager extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            permissionList: [],
            userSearchResults: [],
            pageSize: 20,
            currentPage: 1,
            recordCount: 0,
            listFilter: "",
            listLoading: false,
            userListLoading: false,
            pickingNewUser: false,
        }

        this.componentDidMount = this.componentDidMount.bind(this)
        this.componentWillUnmount = this.componentWillUnmount.bind(this)
        this.componentDidUpdate = this.componentDidUpdate.bind(this)
        this.onPermissionsLoaded = this.onPermissionsLoaded.bind(this)
        this.onPermissionsLoadError = this.onPermissionsLoadError.bind(this)
        this.onPermissionUpdated = this.onPermissionUpdated.bind(this)
        this.onPermissionUpdateError = this.onPermissionUpdateError.bind(this)
        this.updatePage = this.updatePage.bind(this);
        this.updateListFilter = this.updateListFilter.bind(this);
        this.beginUserAdd = this.beginUserAdd.bind(this);
        this.onUserSearchResultsLoaded = this.onUserSearchResultsLoaded.bind(this);
        this.addSelectedUser = this.addSelectedUser.bind(this);
        this.clearSearchResults = this.clearSearchResults.bind(this);
        this.getPermissionsList = this.getPermissionsList.bind(this);
    }
    componentDidMount() {
        DM.add(GroupPermissionData.registerListener("PERMISSIONS_LIST_LOADED", this.onPermissionsLoaded), GroupPermissionData);
        DM.add(GroupPermissionData.registerListener("PERMISSIONS_LIST_LOAD_ERROR", this.onPermissionsLoadError), GroupPermissionData);
        DM.add(GroupPermissionData.registerListener("PERMISSION_UPDATED", this.onPermissionUpdated), GroupPermissionData);
        DM.add(GroupPermissionData.registerListener("PERMISSION_UPDATE_ERROR", this.onPermissionUpdateError), GroupPermissionData);
        DM.add(UserData.registerListener("LIST_LOADED", this.onUserSearchResultsLoaded), UserData);
        this.getPermissionsList()
        MetricsData.recordClientMetric({
            metric: "component-mount",
            feature: "manager-group-permissions-management",
            numerical_value: 1,
            precision: 0,
          })
    }
    
    componentWillUnmount() {
        DM.clear()
    }

    componentDidUpdate(prevProps) {
        if (this.props.value !== prevProps.value) {
            this.getPermissionsList()
        }
    }

    getPermissionsList() {
        this.setState({ listLoading: true }, () => {
            DebounceManager.add("mgpm_gpl", 250, () => {
                let opts = { 
                    _page: this.state.currentPage,
                    _pageSize: this.state.pageSize,
                    where: {
                        "user.full_name": this.state.listFilter
                    }
                }
                if(this.state.listFilter.length < 1) { delete opts.where; }
                GroupPermissionData.loadGroupPermissions(this.props.group.id, opts);
            })
            
        })
    }

    onPermissionsLoaded(payload, action) {
        this.setState({ permissionList: payload.permissions, recordCount: payload.count, listLoading: false });
    }

    onPermissionsLoadError(error, action) {
        console.log(error, action);
    }

    updatePage(direction_count, pageMax) {
        let newPage = ((p) => { 
            if(p < 1) { return 1; }
            if (p >= pageMax) { return pageMax; }
            return p;
        })(this.state.currentPage + direction_count)
        this.setState({ currentPage: newPage }, this.getPermissionsList);
    }

    updateListFilter(event) {
        let newFilterValue = event.target.value;
        this.setState({ listFilter: newFilterValue, currentPage: 1 }, () => {
            if(newFilterValue.length > 3 || newFilterValue.length == 0) { this.getPermissionsList() }
        });
        if (this.state.pickingNewUser && newFilterValue.length > 3) { 
            this.setState({ userListLoading: true }, () => {
                DebounceManager.add("mgpm_ulf_gul", 250, () => { UserData.recordSearch("full_name", newFilterValue); });
            });
        }
    }

    updateGroupPermission(permission, event)  {
        let newValue = event.target.value;
        GroupPermissionData.updateGroupPermission(permission.group.id, permission.id, permission.user.id, newValue);
    }
    
    onPermissionUpdated(permission, action) {
        let self = this;
        let replace_index = self.state.permissionList.reduce((acc, item, index) => {
            if(permission.id == item.id) { return index; }
            return acc;
        }, -1);
        if(replace_index > -1) {
            let permissions = self.state.permissionList;
            permissions.splice(replace_index, 1, permission);
            self.setState({ permissionList: permissions });
        } else {
            let permissions = self.state.permissionList;
            permissions.unshift(permission);
            self.setState({ permissionList: permissions });
        }
    }
    onPermissionUpdateError(error, action) { alert("Error Updating Permission - Check developer console for details"); console.log(error); }

    onUserSearchResultsLoaded(users, action) {
        this.setState({ userSearchResults: users, userListLoading: false });
    }

    beginUserAdd(event) {
        let self = this;
        UserData.recordSearch("full_name", self.state.listFilter)
        self.setState({ pickingNewUser: true, userListLoading: true })
    }

    addSelectedUser(user, event) {
        GroupPermissionData.updateGroupPermission(this.props.group.id, -1, user.id, 10);
        this.clearSearchResults();
    }
    
    clearSearchResults() {
        let self = this;
        self.setState({ pickingNewUser: false, userSearchResults: [] }); 
    }
    render() {
        let self = this;
        let permissionRows = ((listLoading) => {
            if(listLoading) { return <tr><td colSpan={4}><ProgressBar/></td></tr>; }
            let cellStyle = {
                paddingBottom: 5,
            };
            return this.state.permissionList.map((row) => {
                return (<tr key={`permission_${row.id}`}>
                    <td></td>
                    <td style={cellStyle}>{row.user.id}</td>
                    <td style={cellStyle}>{`${row.user.last_name}, ${row.user.first_name} (${row.user.full_name})`}</td>
                    <td style={cellStyle}>{row.group.short_name}</td>
                    <td style={cellStyle}><HTMLSelect value={row.type.type} options={[
                        { label: "Disabled", value: 0 },
                        { label: "User", value: 10 },
                        { label: "After Hours User", value: 11 },
                        { label: "Manager", value: 20 }
                    ]} onChange={self.updateGroupPermission.bind(null, row)} /></td>
                    <td></td>
                </tr>);
            })
        })(this.state.listLoading);
        
        let addRowResults = ((isPicking, isLoading) => {
            if(!isPicking) { return []; }
            if(isLoading) { return <tr><td colSpan={6} style={{ textAlign: "center" }}><Spinner/></td></tr>; }
            let cellStyle = {
                paddingBottom: 5,
            };
            let searchResults = self.state.userSearchResults.map((user) => {
                return (<tr key={`user_select_${user.id}`}>
                    <td></td>
                    <td style={cellStyle}>{user.id}</td>
                    <td colSpan={2} style={cellStyle}>{`${user.last_name}, ${user.first_name} (${user.full_name})`}</td>
                    <td style={cellStyle}><Button intent="primary" icon="new-person" text="Add User" onClick={self.addSelectedUser.bind(null, user)}/></td>
                    <td></td>
                </tr>);
            })
            return searchResults;
        })(self.state.pickingNewUser, self.state.userListLoading);

        let userAddButton = ((isAdding) => {
            if (isAdding) { 
                return <Button minimal={true} intent="danger" icon="new-person" text="Cancel Add" onClick={self.clearSearchResults} />
            }
            if(self.state.listFilter.length < 3) {
                return <Button minimal={true} intent="primary" icon="new-person" text="Add User" disabled={true}/> 
            }
            return <Button minimal={true} intent="primary" icon="new-person" text="Add User" onClick={self.beginUserAdd} />
            
        })(self.state.pickingNewUser)

        let pageMax = Math.floor(this.state.recordCount / this.state.pageSize);
        let pageCountElement = <span>{ Math.floor(this.state.recordCount / this.state.pageSize) + 1 }</span>;
        if(self.state.listLoading) { pageCountElement = <span> Loading... </span>; }
        return (<div style={{ padding: 20 }}>
            <table style={{ width: "100%"}}>
                <thead>
                    <tr>
                        <th style={{ textAlign: "left" }}> <Button icon="arrow-left" onClick={this.updatePage.bind(null, -1, pageMax)}/> </th>
                        <th colSpan={4}>
                            <div style={{ textAlign: "center", width: "100%", paddingBottom: 10 }}>
                                <InputGroup 
                                    style={{ width: "100%" }} 
                                    placeholder="Search for existing user..." 
                                    type='text' value={self.state.listFilter} 
                                    leftIcon="search" 
                                    onChange={self.updateListFilter}
                                    rightElement={userAddButton}    
                                />
                            </div>
                        </th>
                        <th style={{ textAlign: "right" }}> <Button icon="arrow-right"  onClick={this.updatePage.bind(null, 1, pageMax)}/></th>
                    </tr>
                </thead>
                <tbody>
                    { addRowResults }
                    { permissionRows }
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan={6} style={{ textAlign: "center", width: "100%" }}>
                            <span style={{ fontWeight: "bold" }}>Page {this.state.currentPage} of {pageCountElement}</span>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>)
    }
}

export default ManagerGroupPermissionManager;


'use strict';

import React, { useState, useEffect, useReducer } from "react";
import CustomFormData from "data-modules/CustomFormData";
import AppData from "data-modules/AppData";
import { Classes, Spinner, Button, FormGroup } from "@blueprintjs/core";
import EntryDetailField from "../../CustomForm/EntryDetailField";
import isEmail from "validator/lib/isEmail";
import UserData from "data-modules/UserData";
import SearchPicker from "generic-components/SearchPicker";

export default function CustomDataForm({ service }) {

    const [formConfig, setFormConfig] = useState(null);
    const [formData, setFormData] = useState({});
    const [contentValid, setContentValid] = useState(false);
    const [pendingUser, setPendingUser] = useState(AppData.get("userID"));

    useEffect(() => {
        CustomFormData.getServiceFormConfig(service.id).then((config) => { 
            let data_template = dataTemplate(config)
            setFormConfig(config)
            setFormData(data_template)
        })
        UserData.getRecord(AppData.get("userID")).then((user) => { setPendingUser(user.id) }).catch(error => { console.log(error) })
    }, [service])

    const cacheFormState = () => {
        const form_id = service.id;
        const save_key = "crs_cfd_save_" + form_id;
        let existingSave = localStorage.getItem(save_key);
        if (existingSave == null) {
            localStorage.setItem(save_key, JSON.stringify(formData))
        } else {
            if(confirm("You are about to save over an existing save for this form, are you sure you want to replace the save?")) {
                localStorage.setItem(save_key, JSON.stringify(formData))
                alert("Form Data Saved");
            }
        }
    }

    const loadFormState = () => {
        const form_id = service.id;
        const save_key = "crs_cfd_save_" + form_id;
        let existingSave = localStorage.getItem(save_key);
        if(existingSave == null) { setFormData({}); }
        let data = JSON.parse(existingSave);
        if (confirm("You are about to load a saved state of this form. This will replace all current data. Are you sure you want to load the save?")) {
            const isValid = formIsValid(data);
            setFormData(data);
            setContentValid(isValid);
            alert("Saved form data loaded. You will still need to submit the form.");
        }
    }

    const dataTemplate  = (config) => {
        return config.config_data.fields.reduce((d, cfg) => {
            d[cfg.key] = ((c) => {
                if (c.default_value) { return c.default_value; }
                if (c.type == "number") { return undefined; }
                return "";
            })(cfg);
            return d;
        }, {})
    }
     
    const submitForm = () => {
        if(confirm("Are you sure you want to submit this form?")) {
            CustomFormData.submitData(formConfig, {...formData, _user: (pendingUser || "self") }).then((form) => {
                let data_template = dataTemplate(formConfig)
                if(form) { alert("The form has been submitted. Resetting the form.") }
                setFormData(data_template)
                setContentValid(false)
            }).catch((err) => {
                if (err.code == "EENVELOPE") {
                    alert(`Form Submitted - Invalid or Missing Signature Target Email. Unable to send signature request email. Please check/update submitted form for signature email for validity (${err.response})`)
                    setFormData(data_template)
                    setContentValid(false)
                } else {
                    alert(`Error sending request. Error: ${(err.message || JSON.stringify(err))}`);
                }
            })
        }
    }

    const getFieldValue = (field) => {
        return formData[field.key];
    }

    const optionIsChecked = (field, option) => {
        let data = {...formData};
        let field_data = data[field.key];
        if (field_data == null) { return false; }
        if (typeof field_data == "undefined") { return false; }
        if (field_data.length == 0) { return false; }
        let values = field_data.split(",")
        return values.includes(option.value);
    }

    const formIsValid = (new_data) => {
        let test_data = ((d) => {
            if(typeof d !== "undefined") { return d; }
            return data;
        })(new_data);
        let config = {...formConfig};
        let fields = config.config_data.fields;
        return fields.reduce((isValid, field, index) => {
            if (field.type == "markdown_block") { return isValid; }
            if (field.type == "section_divider") { return isValid; }
            if (field.required == "yes" || field.type == "signature_block") {
                let test_value = test_data[field.key];
                if (test_value == undefined) { return false; }
                if (field.type == "signature_block" && !isEmail(test_value)) { return false; }
                if (test_value.length < 1) { return false; }
                if (test_value.length > field.max) { return false; }
                if (test_value.length < (field.min || 0)) { return false; }
                if (test_value == "") { return false; }
            }
            return isValid;
        }, true)
    }

    const setFieldValue = (field, event) => {
        let data = {...formData};
        data[field.key] = event.target.value;
        setFormData(data);
        setContentValid(formIsValid(data))
    }

    const setCheckedOption = (field, option, _event) => {
        let data = {...formData};
        let values = data[field.key].split(",");
        let optionIndex = values.indexOf(option.value);
        if (optionIndex == -1) { values.push(option.value); }
        if (optionIndex > -1) { values.splice(optionIndex, 1); }
        data[field.key] = values.join(",")
        values.filter((v) => { return v.length > 0 });
        setFormData(data);
        setContentValid(formIsValid(data))
    }

    const generateHelperText = (field) => {
        let helper_text = (field.helper_text ? field.helper_text : "")
        let min = (field.min ? field.min : "Any");
        let max = (field.max ? field.max : "Any");
        if(field.type == "number") { return `${helper_text} [${min} to ${max}]` }
        return `${helper_text}`
    }

    let config = {...formConfig};
    if (formConfig == null) { return (<div> <Spinner/> </div>); }

    let fieldElements = formConfig.config_data.fields.map((field, index) => {
        return <EntryDetailField key={"field_"+index} field={field}
            getFieldValue={getFieldValue}
            setFieldValue={setFieldValue}
            optionIsChecked={optionIsChecked}
            setCheckedOption={setCheckedOption}
        />
    })

    let submitButton = ((valid) => {
        if(valid) { return <Button intent="primary" text="Submit" onClick={submitForm} /> }
        return (<Button intent="warning" text="(Invalid/Missing Fields)" disabled={true} />);
    })(contentValid)
    let isGroupManager = AppData.authorize(20, service.group_id);
    let manageButton = (() => {
        if(isGroupManager) {
            return <a href={`#/form/manage/${service.id}`} className={`${Classes.BUTTON} ${Classes.INTENT_PRIMARY}`}> Manage Submissions </a>
        } else {
            return <a href={`#/form/mine`} className={`${Classes.BUTTON} ${Classes.INTENT_PRIMARY}`}> My Submissions </a>
        }
    })()

    return (<div style={{ width: "100%", paddingLeft: "10%", paddingRight: "10%"}}> 
        <div style={{ borderRadius: 10, backgroundColor: "rgba(0,0,0,0.1)", padding: 10 }}>
            <div style={{ position: "relative" }}>
                <h2 className={Classes.HEADING}> {config.config_data.title} </h2>
                <div style={{ position: "absolute", display: "inline-block", top: 0, right: 0}}> {manageButton} </div>
            </div>
            {( isGroupManager ? <div>
                <FormGroup label="Submitting on behalf of">
                    <SearchPicker value={pendingUser} config={{ rowSelectKey: "id", rowDisplayKey: "full_name", dataSource : UserData, searchConfig : UserData.searchConfig, listMapping : UserData.listMapping }} onSelect={(newValue) => { setPendingUser(newValue) }} dataSource={UserData}/>
                </FormGroup>
            </div> : "" )}
            <div>
                <Button style={{ marginLeft: 5 }} intent="primary" text="Save form for later" onClick={cacheFormState} />
                <Button style={{ marginLeft: 5 }} intent="warning" text="Load saved form" onClick={loadFormState} />
            </div>
            <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "flex_start", paddingTop: 10 }}>
                {fieldElements}
            </div>
            <div>
                {submitButton} 
                <Button style={{ marginLeft: 5 }} intent="primary" text="Save form for later" onClick={cacheFormState} />
                <Button style={{ marginLeft: 5 }} intent="warning" text="Load saved form" onClick={loadFormState} />
            </div>
        </div>
    </div>)
}
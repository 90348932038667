
	'use strict';

	/*

		Event Module Event Bus

	*/

  import AppData from "./AppData";
	import Eventer from "../Eventer";
  import { get, post, put, patch, del, getErrorPayload  } from "data-modules/SystemREST";
  import queryString from 'query-string';
  import Holidays from "date-holidays";
  var EventData = Eventer.createBus("EVT");
  import { permission_types } from "data-modules/Enum"
  var moment = require('moment');
  const _ = require("lodash");

	EventData.listMapping = {
		title : function (event) { return `${event.service.name} Event`; },
		subtitle : function (event) { return `${moment(event.start_date).format("YYYY-MM-DD HH:mm:ss")}`; }
	};

	EventData.searchConfig = { options: [
		{value:"id", title : "ID" },
		{value:"title", title : "Title" },
		{value:"short_account", title : "Short Account" },
		{value:"pi_full_name", title : "PI" }
	]};

  EventData.intersectsBlockedDate = function (date, service) {
    let blockedDates = (service.custom_block_dates || "").split(",").filter(b => b.length > 0);
    return blockedDates.reduce((isDuringBlockedDate, block) => {
      if(moment(date).format("MM-DD") === block) { return true; } 
      if(moment(date).format("YYYY-MM-DD") === block) {  return true; } 
      return isDuringBlockedDate;
    }, false);
  }

  EventData.canScheduleAtDate = function (date, service) {

    let isManager = false
    if(service == undefined) { return false; } // If the service is undefined then it can't be scheduled on
    if(!service.group_id == undefined) { isManager = AppData.authorize(20, service.group_id) }
    const isAllAfterHoursService = (service.allow_after_hours_use == 2)
    const isAfterHoursTime = !EventData.isDuringOnHours(date, EventData.getDateBlockedTimes(date, service), false)
    const isAfterHoursUser = EventData.allowAfterHours(service)
    
    let reasons = [];
    return EventData.checkCollision({
      id: "new",
      service_id: service.id,
      start_date: date,
      end_date: moment(date).add(service.interval, "minutes")
    }).then((collisions) => {
      if (collisions.length > 0) { throw { message: `${JSON.stringify(collisions)}`, date: date };} // Collision Check (Mostly for Interevent Gap Testing)
      if (isManager) { return true; } else { reasons.push("Requesting user is not a manager") } // Allow all times if group or system admin
      if (isAllAfterHoursService) { return true; } else { reasons.push("Service does not allow all users after hours access") } // Allow all times if service allows after hours for all users
      if (isAfterHoursTime && isAfterHoursUser) { return true; } else { reasons.push("User is missing after hours access permissions") } // Allow after hours users to schedule during after hours times
      if (!isAfterHoursTime) { return true; } else { reasons.push("Requested time is after hours") } // Allow if during business hours
      reasons.unshift(`Event Create for ( ${service.name} ) on [ ${moment(date).format("YYYY-MM-DD HH:mm")} ]`)
      throw { message: `${reasons.join(", ")}`, date: date };

    }).catch((err) => {
      throw { message: (err.message || err), date: date };
    })
    
  }

	EventData.getDateBlockedTimes = function (date, service) {
		var weekDay = moment(date).day();
		var blockedTimes = null;
    switch(weekDay) {
      case 0 : {
				blockedTimes = service.sunday_blocked;
			} break; // Sunday
      case 1 : {
				blockedTimes = service.monday_blocked;
			} break;
      case 2 : {
				blockedTimes = service.tuesday_blocked;
			} break;
      case 3 : {
				blockedTimes = service.wednesday_blocked;
			} break;
      case 4 : {
				blockedTimes = service.thursday_blocked;
			} break;
      case 5 : {
				blockedTimes = service.friday_blocked;
			} break;
      case 6 : {
				blockedTimes = service.saturday_blocked;
			} break; // Saturday
    }
		if(blockedTimes !== null)  { 
      let result = blockedTimes.split(",").map((i) => {return parseInt(i, 10)});
      if(result.length == 1) return [];
      return result;
    } else { return []; }

  }

  EventData.getBusinessHours = (date, service) => {

    if(typeof service == "undefined") { return []; }

    const service_block_keys = ['sunday_blocked','monday_blocked','tuesday_blocked','wednesday_blocked','thursday_blocked','friday_blocked','saturday_blocked'];
    const service_blocked_times = service_block_keys.map((day_key) => {
      let day_block_config = service[day_key];
      if(day_block_config == null) { day_block_config = ""; }
      const blockedTimes = day_block_config.split(",").map((i) => { return parseInt(i, 10) });
      if(blockedTimes.length <= 1) { return []; }
      return blockedTimes;
    })    

    let appendFullDayBlock = function (config, day) {
      let fullDayBlock = {
        daysOfWeek: [day],
        startTime: "00:00",
        endTime: "00:00"
      };
      config.push(fullDayBlock); 
      return config;
    }

    // Bastard Brittle Patch for the UofU Calendar and nobody knowing if Veterans Day and Columbus Day are valid "bank" holidays.
    // #UofU
    let _isHoliday = (service, date) => { 
      let service_holiday_config = (service.holiday_region || AppData.defaultHolidayRegion()).split(":");
      let holidays = new Holidays({ country: service_holiday_config[0], state: service_holiday_config[1], region: service_holiday_config[2] }, { types: ['public', 'bank'] });
      
      let custom_holiday_config = (AppData.get("custom_holiday_list")).split(",").filter(i => i.length > 0);
      let holiday_inversions = [];
      custom_holiday_config.map((cfg) => { 
        let cfgComponents = cfg.split(":");
        if(cfgComponents.length == 2) {
          holidays.setHoliday(cfgComponents[0], cfgComponents[1])
        } else {
          if(cfg.startsWith("!")) { // date-holidays doesn't want to support the negation/removal of holiday rules as a direct integration
            holiday_inversions.push(cfg.slice(1));
          } else {
              holidays.setHoliday(cfg, cfg) 
          }
        }
      })

      let result = holidays.isHoliday(moment(date).toDate());
      if(holiday_inversions.includes(moment(date).format("YYYY-MM-DD"))) { return false } // So we need to check and see if the holiday in question is on the inversion list
      if(result === false) { return false; }
      if(!["Veterans Day", "Veterans Day (substitute day)", "Columbus Day"].includes(result[0]["name"])) { return result; }
      return false;
    }

    const service_business_hours = service_blocked_times.reduce((config, day_blocks, day) => {
      
      // Holiday Blocks Check  
      // Note: The Add Day is to ensure that TZ Shifted Dates are in the correct week. Do Not Remove
      let test_date = moment(date).add(1, "day").day(day);
      if(_isHoliday(service, test_date) && ((service.should_block_holidays == "all") || (service.should_block_holidays == "regional"))) { return appendFullDayBlock(config, day); }
      let intersectsBlockedDate = EventData.intersectsBlockedDate(test_date, service);
      if(intersectsBlockedDate && ((service.should_block_holidays == "all") || (service.should_block_holidays == "custom"))) { return appendFullDayBlock(config, day); }

      let blocks = _.chunk(day_blocks, 2);
      // All Hours On Hours
      if (blocks.length == 0) {
        config.push({
          daysOfWeek: [day],
          startTime: "00:00",
          endTime: "24:00"
        });
        return config;
      }

      

      if (blocks.length == 1) {
        let start_block = parseInt(blocks[0][0], 10);
        let end_block = parseInt(blocks[0][1], 10);

        // Note: The use of 1439 for the end of day spec/definition is to address the following fullcalendar.io issue : https://github.com/fullcalendar/fullcalendar/issues/4440

        // Single Full Day Block
        if(start_block == 0 && end_block == 1440) { 
          config.push({
            daysOfWeek: [day],
            startTime: "00:00",
            endTime: "00:00"
          });
          return config;
        }

        // Single Middle Block
        if(start_block !== 0 && end_block !== 1440) { 
          config.push({
            daysOfWeek: [day],
            startTime: moment().hours(0).minutes(0).seconds(0).format("HH:mm"),
            endTime: moment().hours(0).minutes(start_block).seconds(0).format("HH:mm")
          });
          config.push({
            daysOfWeek: [day],
            startTime: moment().hours(0).minutes(end_block).seconds(0).format("HH:mm"),
            endTime: moment().hours(0).minutes(1439).seconds(0).format("HH:mm")
          });
          return config;
        }

        // Single Starting Block
        if(start_block == 0 && end_block !== 1440) { 
          config.push({
            daysOfWeek: [day],
            startTime: moment().hours(0).minutes(end_block).seconds(0).format("HH:mm"),
            endTime: moment().hours(0).minutes(1439).seconds(0).format("HH:mm")
          });
          return config;
        }

        // Single Ending Block
        if(start_block !== 0 && end_block == 1440) {
          config.push({
            daysOfWeek: [day],
            startTime: moment().hours(0).minutes(0).seconds(0).format("HH:mm"),
            endTime: moment().hours(0).minutes(start_block).seconds(0).format("HH:mm")
          });
          return config;
        }
      }

      const end_of_last_block = parseInt(blocks[blocks.length - 1][1], 10);

      for (let i = 0; i < blocks.length - 1; i++) {
        const start_of_current_block = parseInt(blocks[i][0], 10);
        const end_of_current_block = parseInt(blocks[i][1], 10);
        const start_of_next_block = parseInt(blocks[i + 1][0], 10);
        

        if(i == 0 && start_of_current_block !== 0) {
          config.push({
            daysOfWeek: [day],
            startTime: moment().hours(0).minutes(0).seconds(0).format("HH:mm"),
            endTime: moment().hours(0).minutes(start_of_current_block).seconds(0).format("HH:mm")
          })
        }

        config.push({
          daysOfWeek: [day],
          startTime: moment().hours(0).minutes(end_of_current_block).seconds(0).format("HH:mm"),
          endTime: moment().hours(0).minutes(start_of_next_block).seconds(0).format("HH:mm")
        })
      }

      if(end_of_last_block !== 1440) { 
        config.push({
          daysOfWeek: [day],
          startTime: moment().hours(0).minutes(end_of_last_block).seconds(0).format("HH:mm"),
          endTime: moment().hours(0).minutes(1439).seconds(0).format("HH:mm")
        })
      }

      return config;
    }, [])

    return service_business_hours;
  }

  EventData.eventsForGroup = function (group_id, range) {
    return get("/events/forGroup/" + group_id + "/" + range.start.format("YYYY-MM-DD") + "/" + range.end.format("YYYY-MM-DD")).send().then(function (res) {
      var payload = res.body;
      if (payload.status) {
        let services = payload.services.sort(function (a, b) { return b.service.display_weight - a.service.display_weight; })
        var events = services.reduce(function (prev, service) { return prev.concat(service.events); }, []).map((e) => {
          return {
            id: e.id,
            resourceId: e.service_id,
            title: `${e.order.user.full_name} - ${e.rate_label} [${e.description}]`,
            start: e.start_date,
            end: e.end_date,
            backgroundColor: e.color,
            borderColor: e.color
          };
        });
        let resources = services.map(function (row) {
          return { 
            id: row.service.id, 
            title: row.service.name.split("/").join(" or "), 
            group: row.service.group_id,
            displayWeight: row.service.display_weight,
            businessHours: EventData.getBusinessHours(range.current, row.service),
            duration: row.service.interval,
            service: row.service,
          } 
        });
        EventData.notifyListeners("EVENTS_LOADED", { resources: resources, events: events, group: payload.group });
        return { resources: resources, events: events, group: payload.group };
      }
    }).catch((err) => {
      EventData.notifyListeners("EVENTS_LOAD_ERROR", getErrorPayload(err));
      throw(getErrorPayload(err));
    });
  }

  EventData.eventsForService = function (service_id, range) {
    return get("/events/forService/" + service_id + "/" + range.start.format("YYYY-MM-DD") + "/" + range.end.format("YYYY-MM-DD")).send().then(function (res) {
      var payload = res.body;
      if (payload.status) {
        var events = payload.events.map((e) => {
          return {
            id: e.id,
            title: `${e.order.user.full_name} - ${e.rate_label} [${e.description}]`,
            start: e.start_date,
            end: e.end_date,
            backgroundColor: e.color,
            borderColor: e.color
          };
        });
        
        let resource = {
          id: payload.service.id,
          title: payload.service.name.split("/").join(" or "),
          group: payload.service.group_id,
          displayWeight: payload.service.display_weight,
          businessHours: EventData.getBusinessHours(range.current, payload.service),
          duration: payload.service.interval,
          service: payload.service
        }
        
        EventData.notifyListeners("EVENTS_LOADED", { resource: resource, events: events, group: payload.group });
        return { resources: resource, events: events, group: payload.group };
      }
    }).catch((err) => {
      EventData.notifyListeners("EVENTS_LOAD_ERROR", getErrorPayload(err));
      throw getErrorPayload(err);
    });
  }
  
  // Check if the currently logged in user is allowed to use a service after hours. Depends on groupPermissions from the Session Data store.
	EventData.allowAfterHours = function (service) {
		var permissions = AppData.get("groupPermissions");
    if(service == undefined || service == null) { return false; }
		if(AppData.get('userType') >= permission_types.ADMINISTRATOR) { return true; }
		if(service.allow_after_hours_use == 2) { return true; }
		var isAfterHoursUser = permissions.reduce(function (prevValue, permission) {
			if(permission.group_id == service.group_id && permission.type == permission_types.APPROVED && service.allow_after_hours_use == 1) { return true; }
			if(permission.group_id == service.group_id && permission.type >= permission_types.MANAGER) { return true; }
			return prevValue;
		}, false);
		return isAfterHoursUser;
	}

	EventData.isDuringOnHours = function(time, blockedTimes, isEndDate) {
		if(typeof isEndDate == "undefined") { isEndDate = false }
		if(blockedTimes.length < 2) { return true; }
		var evaluationTime = moment(time);
		if(!isEndDate) { evaluationTime.add(1,"minute"); }
    var dayStart = moment(evaluationTime).hour(0).minute(0).second(0).millisecond(0);
    var dayMinutePosition = evaluationTime.diff(dayStart, "minutes");
    var topIndex = blockedTimes.reduce(function (prevIndex, blockTransition, index, sourceArray) {
      var transition = parseInt(blockTransition, 10);
      if(dayMinutePosition > transition) { return index; }
      return prevIndex;
    }, null);
    if(topIndex % 2 == 1) { return true; }
    return false;
  };
  
  EventData.isBeyondMaxLeadTime = function(time, service) {
    if( service.maximum_lead_time <= 0 ) { return false; }
    let leadTimeStart = moment().hour(0).minute(0).second(0).millisecond(0).add(service.maximum_lead_time, 'days');
    return time.isAfter(leadTimeStart);
  }

	EventData.checkCollision = function (event) {
		return get(`/events/checkCollision/${event.id}/${event.service_id}/${moment(event.start_date).format("YYYY-MM-DDTHH:mm:ss:Z")}/${moment(event.end_date).format("YYYY-MM-DDTHH:mm:ss:Z")}?rate_id=${(event.rate_id ? event.rate_id : -1)}`).send().then(function(res) {
      var payload = res.body;
      EventData.notifyListeners("COLLISIONS_LOADED", payload.collisions);
      return payload.collisions;
    }).catch(function (err) {
      EventData.notifyListeners("COLLISION_LOAD_ERROR", getErrorPayload(err));
      throw getErrorPayload(err)
    });
  };

	EventData.getRecordList = function (service_id, startDate, endDate) {
		return get("/events/forService/"+service_id+"/"+startDate.format("YYYY-MM-DD")+"/"+endDate.format("YYYY-MM-DD")).send().then(function(res) {
      var payload = res.body;
      EventData.notifyListeners("LIST_LOADED", payload.events);
    }, function (err) {
      EventData.notifyListeners("LIST_LOAD_ERROR", getErrorPayload(err));
    });
  };

	EventData.getGroupEventList = function (group_id, startDate, endDate) {
		if(typeof group_id == "undefined" || group_id == null) {
			EventData.notifyListeners("GROUP_EVENTS_LIST_LOADED", []);
      return;
		}
		return get("/events/forGroup/"+group_id+"/"+startDate.format("YYYY-MM-DD")+"/"+endDate.format("YYYY-MM-DD")).send().then(function(res) {
      var payload = res.body;
      var services = payload.services.map(function (service) {
        service.events = service.events.map(function (evt) {
          evt.start_date = moment(evt.start_date, "YYYY-MM-DD HH:mm:ss");
          evt.end_date = moment(evt.end_date, "YYYY-MM-DD HH:mm:ss");
          return evt;
        });
        return service;
      });
      EventData.notifyListeners("GROUP_EVENTS_LIST_LOADED", services);
    }, function (err) {
      EventData.notifyListeners("LIST_LOAD_ERROR", getErrorPayload(err));
      throw getErrorPayload(err)
    });
  };

	EventData.getRecord = function (record_id) {
		return get("/event/"+record_id).send().then(function(res) {
      var payload = res.body;
      EventData.notifyListeners("RECORD_LOADED", payload.event);
      return payload.event;
    }).catch(function (err) {
      EventData.notifyListeners("RECORD_LOAD_ERROR", getErrorPayload(err));
      throw getErrorPayload(err)
    });
  };

	EventData.updateRecord = function (recordData) {
		return post("/event/update").send({ 'event' : recordData }).then(function(res) {
      var payload = res.body;
      EventData.notifyListeners("RECORD_UPDATED", payload.event);
      return payload.event;
    }).catch(function (err) {
      EventData.notifyListeners("RECORD_UPDATE_ERROR", getErrorPayload(err));
      throw getErrorPayload(err)
    });
	};

	EventData.createRecord = function (recordData) {
		return post("/event/create").send({'event' : recordData}).then(function(res) {
      var payload = res.body;
      var evt = payload.event;
      evt.start_date = moment(evt.start_date, "YYYY-MM-DD HH:mm:ss");
      evt.end_date = moment(evt.end_date, "YYYY-MM-DD HH:mm:ss");
      EventData.notifyListeners("ORDER_CREATED", evt);
      return evt;
    }).catch((err) => {
      EventData.notifyListeners("ORDER_CREATE_ERROR", getErrorPayload(err));
      throw getErrorPayload(err)
    });
	};

	EventData.deleteRecord = function (recordData) {
    return del("/event/"+recordData.id).send().then(function(res) {
      EventData.notifyListeners("ORDER_DELETED", recordData);
    }, function (err) {
      EventData.notifyListeners("ORDER_DELETE_ERROR", getErrorPayload(err));
      throw getErrorPayload(err)
    });
	};

	EventData.removeRecord = function(record) {
		EventData.notifyListeners("RECORD_REMOVED", record);
	};

  EventData.startWalkupEvent = function(data) {
    
    return post("/walkup_event/start").send(data).then(function (res) {
      EventData.notifyListeners("WALKUP_EVENT_STARTED", res.body.record)
      return res.body.record
    }, function (err) {
      EventData.notifyListeners("WALKUP_EVENT_START_ERROR", err)
      throw getErrorPayload(err)
    })
  }
  
  EventData.endWalkupEvent = function(id) {
    return post(`/walkup_event/${id}/end`).send({}).then(function (res) {
      EventData.notifyListeners("WALKUP_EVENT_ENDED", res.body.event)
      return res.body.event
    }, function (err) {
      EventData.notifyListeners("WALKUP_EVENT_END_ERROR", err)
      throw getErrorPayload(err)
    })
  }

  EventData.forceEndWalkupEvent = function(id) {
    return post(`/walkup_event/${id}/end`).send({ __skip_interlock: true }).then(function (res) {
      EventData.notifyListeners("WALKUP_EVENT_ENDED", res.body.event)
      return res.body.event
    }, function (err) {
      EventData.notifyListeners("WALKUP_EVENT_END_ERROR", err)
      throw getErrorPayload(err)
    })
  }

  EventData.deleteWalkupEvent = function(id) {
    return del(`/walkup_event/${id}`).send({}).then(function (res) {
      EventData.notifyListeners("WALKUP_EVENT_DELETED", res.body.id)
      return res.body.id;
    }, function (err) {
      EventData.notifyListeners("WALKUP_EVENT_DELETE_ERROR", err)
      throw getErrorPayload(err)
    })
  }
  
  EventData.listWalkupEvents = function(service_id, opts) {
    var options = {};
    if(opts != undefined) { options = opts }
    let requestURL =`/walkup_events/${service_id}`
    if(queryString.stringify(options).length > 0) { requestURL = `${requestURL}?${queryString.stringify(options)}`}
    return get(requestURL).then((res) => {
      EventData.notifyListeners("WALKUP_EVENTS_LOADED", res.body)
      return res.body
    }, function(err) {
      EventData.notifyListeners("WALKUP_EVENTS_LOAD_ERROR", err)
      throw getErrorPayload(err)
    })
  }

  export default EventData;


import ServiceData from "data-modules/ServiceData"
import { TextArea, HTMLSelect, Tag, FormGroup, ControlGroup } from "@blueprintjs/core"

const areRequiredDataFieldsValid = (service, state) => {
    
    return [1,2,3,4,5].reduce((isValid, fieldIndex) => {
        if (!ServiceData.dataIsActive(service, fieldIndex)) { 
            return isValid; 
        }
        let labelConfig = service[`data_label_${fieldIndex}`] || `Data Label ${fieldIndex}`
        let fieldData = `${state[`data_${fieldIndex}`] || ""}`
        if(`${labelConfig}`.toLocaleLowerCase().includes(`::required`)) { 
            if(fieldData.length < 1 || fieldData == undefined || fieldData == null) {
                return false 
            } 
        }
        return isValid;
    }, true)
}

const DataInputField = ({ service, fieldIndex, value, onChange = () => {} }) => {
    
    let labelConfig = service[`data_label_${fieldIndex}`] || `Data Label ${fieldIndex}`
    let configComponents = labelConfig.split("::");
    let inputElement = <TextArea value={value} onChange={onChange} />
    let isRequired = false;
    let isValid = false;
    if(value == null) { value == "" }

    switch(configComponents.length) {
        case 2: { 
            if(`${configComponents[1]}`.toLocaleLowerCase() == "required") { 
                isRequired = true;
                inputElement = <TextArea value={value} onChange={onChange} />; 
                break; 
            }
            let options = (configComponents[1] || "").split(",")
            inputElement = <HTMLSelect value={value} onChange={onChange}>
                <option value={""} disabled={true}>-- Select Value --</option>
                {options.map((option, index) => <option key={index} value={option}>{option}</option>)}
            </HTMLSelect>
        } break;
        case 3: {
            let options = (configComponents[2] || "").split(",")
            isRequired = `${configComponents[1]}`.toLocaleLowerCase() == "required";
            inputElement = <HTMLSelect value={value} onChange={onChange}>
                <option value={""} disabled={true}>-- Select Value --</option>
                {options.map((option, index) => <option key={index} value={option}>{option}</option>)}
            </HTMLSelect>
        } break;
        default: { inputElement = <TextArea value={value} onChange={onChange} />} break;
    }

    isValid = value.length > 0;
    let validLabel = <Tag round={true} intent={((!isValid) ? "danger" : "success")}>Required</Tag>
    return <FormGroup label={configComponents[0]} labelInfo={(isRequired ? validLabel : "")}>
        <ControlGroup>
            {inputElement}
        </ControlGroup>
    </FormGroup>
}

export default DataInputField
export { areRequiredDataFieldsValid, DataInputField }

'use strict';

import React from 'react';
import { Route, Routes } from "react-router-dom"
import EntryDetailForm from "./EntryDetailForm"
import ServiceEntryList from "./ServiceEntryList"
import MyServiceEntryList from "./MyServiceEntryList"

class CustomFormPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {};
    }
    render() {
        var style = {
            container: {
                width: "100%"
            }
        };


        return (
            <div>
                <div style={style.container}>
                    <Routes>
                        <Route exact path={"/mine"} element={<MyServiceEntryList/>}/>
                        <Route exact path={"/detail/:entry_id"} element={<EntryDetailForm/>}/> 
                        <Route path={"/manage/:service_id"} element={<ServiceEntryList/>}/> 
                    </Routes>
                </div>
            </div>
        );
    }
}

export default CustomFormPage;

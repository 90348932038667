'use strict';
import React from 'react'


class SecureForm extends React.Component {
    constructor(props) {
        super(props);
        this.token_element = React.createRef();
        this.getOUC = this.getOUC.bind(this);
    }

    getOUC(event) {
        event.preventDefault()
        let self = this;
        let user_token = self.props.token;
        let target = event.currentTarget;

        fetch("/otc/generate", {
            method: "POST",
            headers: {
                'authorization': `Bearer ${user_token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                requested_route: self.props.action
            })
        }).then((response) => {
            switch(response.status) {
                case 200: return response.json();
                default: throw Error(`Invalid Response Code : ${response.status}`)
            }
            
        }).then((data) => {
            self.token_element.current.value = data.access_token;
            target.submit();
        }).catch((err) => {
            alert(err.message);
        })
    }

    render() {
        let self = this;
        let url = self.props.action;
        let method = self.props.method;

        return (<form style={{
            display: "inline-block"
        }} action={url} method={method} target={self.props.target || "_self"} onSubmit={self.getOUC}>
            <input type="hidden" name="access_token" ref={self.token_element} value=""/>
            { this.props.children }
        </form>);
    }
}

export default SecureForm;
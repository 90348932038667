
	'use strict';

	/*

		Messages Module Event Bus

	*/

	import Eventer from "../Eventer";
  import { get, post, put, patch, del, getErrorPayload  } from "data-modules/SystemREST";
	var MessageData = Eventer.createBus("MSG");
  // AppData.injectJWTErrorListener(MessageData);

	MessageData.sendMessage = function (users, subject, message) {
		let recipients = users.map((user, index) =>  { 
      return {
        full_name : user.full_name,
        email : user.email
		  };
     });
    post("/message/send").send({
      users : recipients,
      subject : subject,
      message : message
    }).then(function(res) {
      var payload = res.body;
      MessageData.notifyListeners("MESSAGE_SENT", payload.accounts);
    }, function (err) {
      MessageData.notifyListeners("MESSAGE_SEND_ERROR", getErrorPayload(err));
    });
  };

	MessageData.sendErrorReport = function (errorObject) {
    post("/message/auto_bug_report").send(errorObject).then(function(res) {
      var payload = res.body;
      MessageData.notifyListeners("BUG_REPORT_SENT", payload.accounts);
    }, function (err) {
      MessageData.notifyListeners("BUG_REPORT_SEND_ERROR", getErrorPayload(err));
    });
  };

  MessageData.checkUploadLimits = function (files, limit) {
    // Check Files Sizes Against Upload Limit
    let sizeCheck = [...files].reduce((acc, file) => {
      if(limit) {
        if(file.size > limit) { acc.status = false; acc.errors.push("File "+file.name+" size exceeds "+(limit/1024)+"kb upload limit. The file is "+(file.size/1024)+"kb."); }
      } else {
        if(file.size > 1024) { acc.status = false; acc.errors.push("File "+file.name+" size exceeds 1kb upload limit. The file is "+(file.size/1024)+"kb."); }
      }
      return acc;
    }, { status: true, errors: [] })

    return sizeCheck;
  }
MessageData.sendBroadcast = function (targets, subject, message, range, sender, attachments = []) {
  let broadcastAction = post("/message/broadcast");
  if(MessageData.checkUploadLimits(attachments, 1024 * 5000).status === false) { 
    MessageData.notifyListeners("MESSAGE_SEND_ERROR", { message: `"Attachments Larger than 1MB not allowed"` });
    return;
  }

  attachments.map((file) => { broadcastAction.attach("attachments", file) });
  let requestPayload = {
    targets: targets,
    subject: subject,
    message: message,
    range: range,
    sender: sender
  };
  Object.keys(requestPayload).map((key) => { broadcastAction.field(key, requestPayload[key]) });
  broadcastAction.then(function (res) {
    var payload = res.body;
    MessageData.notifyListeners("MESSAGES_SENT", payload.results);
  }, function (err) {
    MessageData.notifyListeners("MESSAGE_SEND_ERROR", getErrorPayload(err));
  });
};

MessageData.getRecipientList = function (targets, range) {
  return post("/message/recipient_list").send({
    targets: targets,
    range: range
  }).then(function (res) {
    var payload = res.body;
    MessageData.notifyListeners("GOT_RECIPIENT_LIST", payload);
    return payload;
  }, function (err) {
    MessageData.notifyListeners("ECIPIENT_LIST_FETCH_ERROR", getErrorPayload(err));
  });
};

MessageData.getTemplates = async function () {
  try {
    let response = await get("/message_template").send();
    MessageData.notifyListeners("MESSAGE_TEMPLATES_LOADED", response.body);
    return response.body;
  } catch (err) {
    MessageData.notifyListeners("MESSAGE_TEMPLATE_LOAD_ERROR")
    throw err;
  }
}

MessageData.createTemplate = async function (templateData) {
  try {
    let response = await post("/message_template").send(templateData);
    MessageData.notifyListeners("MESSAGE_TEMPLATE_CREATED", response.body);
    return response.body;
  } catch (err) {
    MessageData.notifyListeners("MESSAGE_TEMPLATE_CREATE_ERROR")
    throw err;
  }
}

MessageData.updateTemplate = async function (templateData) {
  try {
    let response = await put("/message_template").send(templateData);
    MessageData.notifyListeners("MESSAGE_TEMPLATE_UPDATED", response.body);
    return response.body;
  } catch (err) {
    MessageData.notifyListeners("MESSAGE_TEMPLATE_UPDATE_ERROR")
    throw err;
  }
}

MessageData.deleteTemplate = async function (templateData) {
  try {
    let response = await del("/message_template").send(templateData);
    MessageData.notifyListeners("MESSAGE_TEMPLATE_UPDATED", response.body);
    return response.body;
  } catch (err) {
    MessageData.notifyListeners("MESSAGE_TEMPLATE_UPDATE_ERROR")
    throw err;
  }
}

export default MessageData;

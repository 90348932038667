/*
  This is a really thin manager class for handling listeners for the Eventer class.
*/
class DataManager {
  constructor () {
    this._responders = [];

    this.add = this.add.bind(this);
    this.clear = this.clear.bind(this);

  }

  add(actionKey, dataSource) {
    this._responders.push({'actionKey' : actionKey, 'source' : dataSource});
  }

  clear() {
    this._responders.map(function (responder) {
      var dataSource = responder.source;
      dataSource.deregisterListener(responder.actionKey);
    });
  }
}

export default DataManager;

// ANCHOR User
export const user_types = {
    "DISABLED": 0,
    "REFERENCE": 1,
    "ORGANIZATION": 2,
    "BANNED": 3,
    "PENDING": 10,
    "APPROVED": 11,
    "AUDITOR": 19,
    "MANAGER": 20,
    "ADMINISTRATOR": 30
}

// ANCHOR Permissions
export const permission_types = {
    "ANY": 0,
    "DISABLED": 0,
    "BANNED": 3,
    "PENDING": 10,
    "APPROVED": 11,
    "AUDITOR": 19,
    "MANAGER": 20,
    "ADMINISTRATOR": 30
};

// ANCHOR Service  
export const service_types = {
    "SCHEDULED": 0,
    "LINKED": 1,
    "CUSTOM": 2,
    "LINE_ITEM": 3,
    "ORDER_FORM": 4,
    "GENOME_PROCESSING": 5,
    "BBSP": 6,
    "MSSP": 7,
    "DATA_FORM": 8,
    "FASTA_PROCESSING": 9,
    "WALKUP": 10
}

export const service_enable = {
    "ARCHIVED": -1,
    "NO": 0,
    "YES": 1
}

export const service_after_hours = {
    "NO": 0,
    "YES": 1,
    "ALL_USERS": 2
}

export const service_manager_only = {
    "NO": 0,
    "YES": 1
}

export const service_exclude_billing = {
    "NO": 0,
    "YES": 1
}

export const notification_verbosity = {
    "DELETE_ONLY": -1,
    "NONE": 0,
    "CREATE": 1,
    "CREATE_DELETE": 2,
    "ALL": 3
}

export const service_week_days = {
    "SUNDAY": 0,
    "MONDAY": 1,
    "TUESDAY": 2,
    "WEDNESDAY": 3,
    "THURSDAY": 4,
    "FRIDAY": 5,
    "SATURDAY": 6,
}

export const event_rate_types = {
    "DISABLED": -1,
    "PER_HOUR": 0,
    "PER_EVENT": 1
}

// ANCHOR Custom Service  
export const gsipt_preset_types = {
    "SINGLE": 0,
    "PLATE": 1,
    "PLATE_4": 2,
}

export const bbsp_preset_types = {
    "PROCESS": 0,
    "FEE": 1,
}

export const line_item_preset_types = {
    "EDITABLE": 0,
    "ADJUSTABLE": 1,
    "FIXED": 2
}

export const bbsp_processing_statuses = {
    "SUBMITTED": 0,
    "PROCESSING": 1,
    "COMPLETED": 2
}

export const mssp_processing_statuses = {
    "WAITING_RECEIPT": 0,
    "RECEIPT_VERIFICATION": 1,
    "EXTRACTION": 2,
    "INSTRUMENT": 3,
    "DATA_ANALYSIS": 4,
    "REPORT_GENERATION": 5,
    "REPORT_SENT": 6
}

export const dnapeptide_processing_statuses = {
    "PENDING": 0,
    "STARTED": 1,
    "FINISHED": 2
}

export const gsipt_processing_statuses = {
    "SUBMITTED": 0,
    "PROCESSING": 1,
    "COMPLETED": 2
}

export const gsipt_plate_processing_status = {
    "PENDING": 0,
    "IN_PROCESS": 10,
    "COMPLETED": 20
}

export const gsipt_plate_seal_types = {
    "SEPTA": 1,
    "HEAT": 0
}

// ANCHOR Order  
export const umerge_statuses = {
    "MERGED" : 1,
    "UNMERGED" : 0
}

export const order_billing_statuses = {
    "EXCLUDED": 0,
    "PENDING": 1,
    "APPROVED": 2,
    "BILLED": 3
}

// ANCHOR Group Config 
export const group_statuses = {
    "DISABLED": 0,
    "ENABLED": 1,
    "MANAGER_ONLY": 2
}

// ANCHOR Account Config 
export const account_types = {
    "INTERNAL": 0,
    "EDUCATION": 1,
    "COMMERCIAL": 2,
    "DUMMY": 3
}

export const  account_permission_types = {
    "DISABLED": 0,
    "ALLOWED": 1
}

export const account_statuses = {
    "ACTIVE": 0,
    "INACTIVE": 1,
    "DISABLED": 2,
}

export const account_billing_types = {
    "AUTOMATIC": 0,
    "MANUAL": 1
}

// ANCHOR News Config 
export const news_visible = {
    "PINNED": 2,
    "YES": 1,
    "NO": 0
}

export const news_types = {
    "GROUP": 0,
    "SERVICE": 1
}

import React from 'react';
import AppData from 'data-modules/AppData';

class LogoutPage extends React.Component {
  constructor () {
    super()
    this.componentDidMount = this.componentDidMount.bind(this);
  }

  componentDidMount () {
    let self = this;
    AppData.logout();
    self.setState({});
  }

  render () {
      return (
        <div style={{ width : "100%" }}>
          <div style={{ fontSize : "1.2rem", fontWeight : "bold", width : 500, margin : "auto", textAlign : "center", marginTop: 100 }}>
            You Have Successfully Logged Out. <br/>Please Click <a href="/auth/login">Here</a> to log back in.
          </div>
        </div>
      );
  }
}

export default LogoutPage;


  'use strict';

  import React from 'react';
  import OrderData from 'data-modules/OrderData';
  import AppData from 'data-modules/AppData';
  import DataManager from "data-modules/DataManager";
  let DM = new DataManager();
  import style from "./style";
  import {
    H1,
    H2,
    Tag,
    Intent,
    HTMLTable,
    Callout,
    Card,
    H5,
    FormGroup,
    ControlGroup,
    PopoverInteractionKind, Position, Button, InputGroup, H4, H3,
    Popover, Classes
  } from "@blueprintjs/core";
  import withRouter from "lib/withRouter";
  import UserAccountPicker from "../Generic/UserAccountPicker";
  import moment from "moment-timezone";
  import { account_types } from "data-modules/Enum"

  class OrderViewer extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        isLoading : true,
        order : null
      };

      this.componentDidMount = this.componentDidMount.bind(this)
      this.componentWillUnmount = this.componentWillUnmount.bind(this)
      this.orderLoaded = this.orderLoaded.bind(this)
    }
    componentDidMount() {
      DM.add(OrderData.registerListener("RECORD_LOADED", this.orderLoaded), OrderData);
      OrderData.getRecord(this.props.params.id);
    }
    componentWillUnmount() {
      DM.clear();
    }
    orderLoaded(order, action) {
      this.setState({'order' : order, isLoading : false});
    }

    eventCostEstimator(event, order) {
      let on_hours_rate = event.on_hours_rate
      let off_hours_rate = event.off_hours_rate
      if (order.account.type == account_types.COMMERCIAL) { 
        on_hours_rate = event.commercial_rate
        off_hours_rate = event.commercial_off_hours_rate
      }
      return (((event.on_hours_duration * on_hours_rate) + (event.off_hours_duration * off_hours_rate) + event.cost_modifier) * order.cost_multiplier).toFixed(2)
    }

    lineCostEstimator(line, order) {
      let rate = line.rate;
      if (order.account.type == account_types.COMMERCIAL) { 
        rate = line.commercial_rate
      }
      return (((line.quantity * rate) + line.cost_modifier) * order.cost_multiplier).toFixed(2)
    }

    render() {
      const self = this;
      if(this.state.isLoading)  { return <div style={style.container}> <H1> Loading Order : {this.props.params.id}... </H1></div>; }
      let order = this.state.order;
      let eventsList = <div></div>
      if(order.events) {
        eventsList = order.events.reduce(function (rows, event, index) {
          let dataFields = [1,2,3,4,5].reduce(function (prev, index) {
            if(event.service["data_label_"+index] == "" || event.service["data_label_"+index] == null) {
              prev.push(<Tag key={event.id+"-data-"+index} style={{ marginRight : 5 }} minimal={true}>({"Data Field "+index}) {event["data_"+index]}</Tag>);
            } else {
              prev.push(<Tag key={event.id+"-data-"+index} style={{ marginRight : 5 }} minimal={true}>({event.service["data_label_"+index] + " - " + "Data Field "+index}) {event["data_"+index]}</Tag>);
            }
            return prev;
          }, []);
          rows.push(<tr key={"event-"+index} style={{marginBottom : 5}}>
            <td style={{ verticalAlign: "middle", textAlign: "center" }}> <Tag round={true} intent={Intent.PRIMARY}>{event.id}</Tag> </td>
            <td style={{ verticalAlign: "middle" }}> <span> {event.rate_label} </span> </td>
            <td style={{ verticalAlign: "middle" }}> {moment(event.start_date).tz(AppData.timezone()).format("YYYY-MM-DD HH:mm (Z)")} to {moment(event.end_date).tz(AppData.timezone()).format("YYYY-MM-DD HH:mm (Z)")} ({event.total_duration} hours)  </td>
            <td style={{ verticalAlign: "middle" }}> ${self.eventCostEstimator(event, order)} </td>
          </tr>)
          rows.push(<tr key={"event-"+index+"-data"}>
            <td colSpan={3} style={{ verticalAlign: "middle" }}>
              <H4>Event Data Fields</H4>
              <div style={{marginBottom : 5, marginTop : 5}}>
                {dataFields}
              </div>
            </td>
          </tr>);
          return rows;
        }, []);
      }
      let linesList = <div></div>;
      if(order.lines) {
        linesList = order.lines.map(function (line, index) {
          let dataFields = [1,2,3,4,5].reduce(function (prev, index) {

            if(/^#\/dna_peptide\/order/.test(line.service.link) && index == 1) {
              prev.push(<Tag key={line.id + "-data-" + index} style={{ marginRight: 5 }} minimal={true}>{"Sequence : "} {line["data_" + index]}</Tag>);
              return prev;
            }
            if (/^#\/dna_peptide\/order/.test(line.service.link) && index > 1) { return prev; }


            if(line.service["data_label_"+index] == "" || line.service["data_label_"+index] == null) {
              prev.push(<Tag key={line.id+"-data-"+index} style={{ marginRight : 5 }} minimal={true}>({"Data Field "+index}) {line["data_"+index]}</Tag>);
            } else {
              prev.push(<Tag key={line.id+"-data-"+index} style={{ marginRight : 5 }} minimal={true}>({line.service["data_label_"+index] + " - " + "Data Field "+index}) {line["data_"+index]}</Tag>);
            }
            return prev;
          }, []);

          return (<tr key={"line-"+index} style={{marginBottom : 5}}>
            <td style={{ verticalAlign: "middle", textAlign: "center" }}> <Tag round={true} intent={Intent.PRIMARY}>{line.id}</Tag> </td>
            <td style={{ verticalAlign: "middle" }}> {line.description} </td>
            <td style={{ verticalAlign: "middle" }}> ${self.lineCostEstimator(line, order)} </td>
            <td style={{ verticalAlign: "middle" }}>
              <div style={{marginBottom : 5, marginTop : 5}}>
                {dataFields}
              </div>
            </td>
          </tr>);
        });
      }


      let totalElement = <div></div>
      if(order.order_billing_status == 3) {
        totalElement = <Callout style={{ marginBottom : 10, padding: 20 }}>
            <div style={{display: "flex"}}>
              <H4 style={{ marginRight: 16, marginBottom: 0}}>Billed Total</H4>
              <H5>$ {order.total_charged.toFixed(2)}</H5>
            </div>
        </Callout>
      }

      // Configuration for the user/account selector component
      let userAccountConfig = {
        elementWidth: 460,
      }

      let serviceDetailContent =
        serviceDetailContent = <div style={{ padding : 10 }}>
          <H2> Service </H2>
          <div>
            Name : { order.service.name }
          </div><div>
            Group : { order.service.group?.name || "" }
          </div><div>
            Contact Email : { order.service.contact_email || "" }
          </div><div>
            Location : { order.service.location || "" }
          </div>
        </div>

      let serviceElement = (<FormGroup>
        <H5>Service</H5>
        <ControlGroup>
          <Popover content={serviceDetailContent}
                    popoverClassName={Classes.POPOVER_CONTENT_SIZING}
                    interactionKind={PopoverInteractionKind.CLICK}
                    position={Position.BOTTOM_LEFT}>
            <Button icon="wrench" />
          </Popover>
          <InputGroup type="text" style={{ width : 460 }} value={order.service.name} readOnly={true} />
        </ControlGroup>

      </FormGroup>);

      return (<div style={style.container}>
        <H1 style={{marginBottom: 10}}>Order: {this.props.params.id}</H1>
        <Callout style={{ marginBottom : 10, padding: 20 }}>
          <H3 style={{marginBottom: 10}}>Details</H3>
          <Card style={{ width: "fit-content" }}>
            <UserAccountPicker displayOnly user={order.user} account={order.account} config={{ elementWidth: 460 }}  comparisonDate={new Date()}/>
            {serviceElement}
            <div style={{display: "flex"}} >
              <H5 style={{ marginRight: 12 }}>Order Date</H5>
              <span>{moment(order.order_date).format("YYYY-MM-DD HH:mm")}</span>
            </div>
          </Card>
        </Callout>

        {totalElement}

        {eventsList.length > 0 && <Callout style={{marginBottom : 5, padding: 20}}>
          <H3 style={{marginBottom: 10}}>Events</H3>
          <Card style={{ width: "fit-content" }}>
            <HTMLTable striped={true} bordered={true}>
              <thead>
                <tr>
                  <th>Event ID</th>
                  <th>Label</th>
                  <th>Duration</th>
                  <th>Cost Estimate</th>
                </tr>
              </thead>
              <tbody>
                {eventsList}
              </tbody>
            </HTMLTable>
          </Card>
        </Callout>}

        {linesList.length > 0 && <Callout style={{marginBottom : 5, padding: 20}}>
          <H3  style={{marginBottom: 10}}>Lines</H3>
          <Card style={{ width: "fit-content" }}>
            <HTMLTable striped={true} bordered={true}>
              <thead>
                <tr>
                  <th>Line ID</th>
                  <th>Label</th>
                  <th>Cost Estimate</th>
                  <th>Data Fields</th>
                </tr>
              </thead>
              <tbody>
                {linesList}
              </tbody>
            </HTMLTable>
          </Card>
        </Callout>}

      </div>);
    }
  }

  let Page = withRouter(OrderViewer);
  export default Page;

import React from 'react';

import SiteDialogData from "data-modules/SiteDialogData";
import AppData from 'data-modules/AppData';
import DataManager from "data-modules/DataManager"
import ErrorBoundary from "generic-components/ErrorBoundary"
let DM = new DataManager();


import { Dialog, Classes } from "@blueprintjs/core"

/*
  Master dialog popup container. Interacts with individual compoents via the SiteDialogData messaging bus to handle
  the display of messages across the site. Exists to unify notification across the app. Primarily a thin wrapper around
  the BlueprintJS Dialog element.
*/
export default class SiteDialogContainer extends React.Component {
 
    constructor(props) {
        super(props)
        this.componentDidMount = this.componentDidMount.bind(this);
        this.componentWillUnmount = this.componentWillUnmount.bind(this);
        this.userLogoutHandler = this.userLogoutHandler.bind(this);
        this.dialogOpened = this.dialogOpened.bind(this);
        this.dialogClosed = this.dialogClosed.bind(this);
        this.dialogCleared = this.dialogCleared.bind(this);  
    }
    componentDidMount() {
        DM.add(AppData.registerListener("USER_LOGGED_OUT", this.userLogoutHandler), AppData);
        DM.add(SiteDialogData.registerListener("DIALOG_OPENED", this.dialogOpened), SiteDialogData);
        DM.add(SiteDialogData.registerListener("DIALOG_CLOSED", this.dialogClosed), SiteDialogData);
        DM.add(SiteDialogData.registerListener("DIALOG_CLEARED", this.dialogCleared), SiteDialogData);
    }
    componentWillUnmount() {
        DM.clear();
    }
    userLogoutHandler() {
        this.setState({});
    }
    dialogOpened() {
        this.setState({});
    }
    dialogClosed() {
        this.setState({});
    }
    dialogCleared() {
        this.setState({});
    }

    render() {
        return (<div> 
            <Dialog isOpen={SiteDialogData.isOpen()} onClose={SiteDialogData.close} title={SiteDialogData.title()}>
                <ErrorBoundary module="Site Dialog Container">
                    <div className={Classes.DIALOG_BODY}>
                        {SiteDialogData.message()}
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>  
                        {SiteDialogData.actionButtons()}
                    </div>
                </ErrorBoundary>
            </Dialog>
        </div>);
    }// End Site Dialog Container Render
}// End Site Dialog Container
import React from "react"
import DataManager from "data-modules/DataManager"
import AppData from "data-modules/AppData"
let DM = new DataManager();
import { Button, Intent, H3, H4, InputGroup, ControlGroup } from "@blueprintjs/core"
import UserData from "data-modules/UserData"

export class PasswordManager extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      new_password : "",
      confirm_password : "",
      didUpdate : false,
      showText : false
    }
    this.passwordUpdated = this.passwordUpdated.bind(this);
  }

  componentDidMount () {
    DM.add(UserData.registerListener("PASSWORD_UPDATED", this.passwordUpdated), UserData);
  }

  passwordUpdated (user, action) {
    this.setState({ new_password : "", confirm_password : "", didUpdate : true });
    setTimeout(()=>{ this.setState({ didUpdate : false }); }, 3000);
  }

  componentWillUnmount () {
    DM.clear();
  }

  render () {
    let minLength = 12;
    let passwordsMatch = this.state.new_password == this.state.confirm_password;
    let meetsMinLength = this.state.new_password.length >= minLength;
    // let hasUpper = /[A-Z]/g.test(this.state.new_password);
    // let hasLower = /[a-z]/g.test(this.state.new_password);
    // let hasNumber = /[0-9]/g.test(this.state.new_password);
    // let hasCharacter = /\W/g.test(this.state.new_password);
    let hasSpace = / /g.test(this.state.new_password);
    let isValid = (meetsMinLength && !hasSpace);

    let changeButton = <Button disabled={true}> Change Password </Button>
    if(isValid && passwordsMatch) {changeButton = <Button intent={Intent.PRIMARY} onClick={()=>{ UserData.updatePassword(this.props.record, this.state.new_password) }}> Change Password </Button>}
    if(this.state.didUpdate) { changeButton = <Button intent={Intent.SUCESS} onClick={()=>{}}> Password Updated </Button> }

    let externalAuthRegex = new RegExp(AppData.get("ldap_auth_regex"), "gi");
    let isSSOUser = (record) => {
      if(record.tags !== null && `${record.tags}`.split(",").includes("sso")) { return true; }
      return externalAuthRegex.test(record.username)
    }
    if(isSSOUser(this.props.record)) {
      return <div> <H4 style={{ color: "orange" }}> SSO Auth Provider - Local Password Management Not Available </H4> </div>
    }

    let complianceMessageParts = [];

    if(!passwordsMatch) { complianceMessageParts.push("Passwords Do Not Match") }
    // if(!hasUpper) { complianceMessageParts.push("No Upper Case Letter") }
    // if(!hasLower) { complianceMessageParts.push("No Lower Case Letter") }
    // if(!hasNumber) { complianceMessageParts.push("No Number") }
    // if(!hasCharacter) { complianceMessageParts.push("No Special Characters") }
    if(hasSpace) { complianceMessageParts.push("No Spaces Allowed") }
    if(!meetsMinLength) { complianceMessageParts.push("Not Long Enough ("+minLength+" Required)") }

    let newPasswordStatusStyle = (isValid ? Intent.NONE : Intent.DANGER);
    let confirmPasswordStatusStyle = ((isValid && passwordsMatch) ? Intent.NONE : Intent.DANGER);

    return (<div> <H3> Update Password </H3>
      <div style={{ marginBottom : 5, marginTop:6, width : 300 }}>
        <ControlGroup>
          <InputGroup fill={true} intent={newPasswordStatusStyle} type={(this.state.showText ? "text" : "password")} placeholder="New Password..." value={this.state.new_password} onChange={(event)=>{ this.setState({ new_password : event.target.value }) }}/>
          <Button intent={Intent.PRIMARY} icon={(this.state.showText ? "eye-open" : "eye-off")} onClick={() => { this.setState({ showText : !this.state.showText })}}></Button>
        </ControlGroup>
      </div>
      <div style={{ marginBottom : 5, width : 300 }}>
        <ControlGroup>
          <InputGroup fill={true} intent={confirmPasswordStatusStyle} type={(this.state.showText ? "text" : "password")} placeholder="Confirm Password..." value={this.state.confirm_password} onChange={(event)=>{ this.setState({ confirm_password : event.target.value }) }}/>
          <Button intent={Intent.PRIMARY} icon={(this.state.showText ? "eye-open" : "eye-off")} onClick={() => { this.setState({ showText : !this.state.showText })}}></Button>
        </ControlGroup>
      </div>
      <div style={{ marginBottom : 5 }}>
        <span style={{ color : "red", fontWeight: "bold" }}>{(this.state.new_password == "" ? "" : complianceMessageParts.join(" - "))}</span>
      </div>
      <div style={{ marginBottom : 5 }}>
        {changeButton}
      </div>
    </div>)
  }
}


'use strict';

import React from 'react';

class RecordRow extends React.Component {
  constructor(props) {
    super(props)

    this.onClick = this.onClick.bind(this)
  }
  onClick(event) {
    if(typeof this.props.clickHandler != "undefined") {
      this.props.clickHandler(event, this.props.rowId, this.props.record);
    }
  }
  render() {
    let style = {
      container : {
        width: "100%",
        padding: 2,
        borderBottom : "1px solid #c6c6c6",
        ":hover" : {
          backgroundColor : "#c6c6c6"
        },
        ":active" : {
          backgroundColor : "#d8d8d8"
        }
      },
      title : {
        fontSize: "1rem",
        fontWeight: "bold",
        color : "black",
        marginBottom: 5
      },
      subtitle : {
        fontSize: "0.8rem",
        color : "gray"
      }
    };
    let title = this.props.title;
    let subtitle = this.props.subtitle;
    let rowStyle = {};
    if(typeof this.props.title == "function") { title = this.props.title(this.props.record); }
    if(typeof this.props.subtitle == "function") { subtitle = this.props.subtitle(this.props.record); }
    if(typeof this.props.rowStyle == "function") { rowStyle = this.props.rowStyle(this.props.record); }



    let element = <tr style={rowStyle} onClick={this.onClick}>
        <td style={style.title}>{title}</td>
    </tr>;
    if(typeof subtitle !== "undefined") {
      element = <tr style={rowStyle} onClick={this.onClick}>
        <td>
          <div style={style.title}>{title}</div>
          <div style={style.subtitle}>{subtitle}</div>
        </td>
      </tr>
    }
    return element;
  }
}

export default RecordRow;

import React from "react";
import NewsData from "data-modules/NewsData";
import { Callout, H5, Button, TextArea, InputGroup, ControlGroup, HTMLSelect, Icon } from "@blueprintjs/core"
import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from "rehype-sanitize";
import MarkdownIt from "markdown-it";
let markdown = new MarkdownIt({
    html: true
});

class NewsItem extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      isEditing: false,
      pendingContent: this.props.item.content,
      pendingTitle: this.props.item.title,
      responseMessage: null
    };
    this.startEditing = this.startEditing.bind(this);
    this.endEditing = this.endEditing.bind(this);
    this.cancelEditing = this.cancelEditing.bind(this);
    this.setVisibility = this.setVisibility.bind(this);
  }

  startEditing () { 
    this.setState({ isEditing: true, pendingContent: this.props.item.content, pendingTitle: this.props.item.title }); 
  }
  endEditing () { 
    let updateData = {
      id: this.props.item.id,
      timestamp: this.props.item.timestamp,
      title: this.state.pendingTitle,
      visible: this.props.item.visible,
      content: this.state.pendingContent
    };
    NewsData.updateRecord(updateData).then((response) => {
      this.setState({ isEditing: false });
      if(typeof this.props.onEndEdit == "function") { this.props.onEndEdit({ newTitle: this.state.pendingTitle, newContent: this.state.pendingContent }); }
    }).catch((err) => {
      console.log(err);
    })
    
  }
  cancelEditing() {
    this.setState({ isEditing: false, pendingContent: "", pendingTitle: "" });
  }
  setVisibility (event) {
    if (event.target.value == 0 && !confirm("Are you sure you want to hide this news item")) { return; }
    if (event.target.value == 2 && !confirm("Are you sure you want to pin this news item")) { return; }
    let updateData = {
      id: this.props.item.id,
      timestamp: this.props.item.timestamp,
      title: this.state.pendingTitle,
      visible: event.target.value,
      content: this.state.pendingContent
    };
    NewsData.updateRecord(updateData).then((response) => {
      this.setState({ isEditing: false });
      if(typeof this.props.onEndEdit == "function") { this.props.onEndEdit({ newTitle: this.state.pendingTitle, newContent: this.state.pendingContent }); }
    }).catch((err) => {
      console.log(err);
    })
  }

  render() {
    var title = this.props.item.title; 
    var message = this.props.item.content || "";
    var messageHTML = markdown.render(message);
    let controls = (<span></span>)
    if(this.props.showControls) {
      controls = (<ControlGroup> 
        <Button icon="edit" onClick={this.endEditing} intent="primary" text="Save"/><HTMLSelect value={this.props.item.visible} onChange={this.setVisibility}>
          <option value={0}> Hidden </option>
          <option value={1}> Visible </option>
          <option value={2}> Pinned </option>
        </HTMLSelect><Button icon="cross" onClick={this.cancelEditing} intent="danger"  text="Cancel"/> 
      </ControlGroup>)
    }
    
    if(![1,2].includes(this.props.item.visible)) { return <Callout style={{ backgroundColor: "#aaaaaa", marginBottom: 5 }}> <H5 style={{ textDecoration: "underline" }}> <Icon icon="pin" size={24} style={{ color: "#BE0000"}}/> {title} <Button icon="eye-off" minimal={true} onClick={this.setVisibility} intent="warning"/> </H5> </Callout>; }

    return ( <Callout style={{ backgroundColor: "#cecece" }}>
      { this.state.isEditing ? (<div><H5>Title</H5><InputGroup type="text" fill={true} style={{ marginBottom: 5}} onChange={(evt) => { this.setState({ pendingTitle: evt.target.value })}} value={this.state.pendingTitle}/></div>) : (<H5 style={{ textDecoration: "underline" }}> <Icon icon="pin" size={24} style={{ color: "#BE0000"}}/> {title} <Button icon="edit" onClick={this.startEditing} minimal={true} intent="primary"/> </H5>) } 
      
      <Callout style={{ backgroundColor: "#efefef" }}>
        { this.state.isEditing ? (<div><H5>Content</H5><MDEditor onChange={(value) => { this.setState({ pendingContent: value })}} value={this.state.pendingContent} previewOptions={{ rehypePlugins: [[rehypeSanitize]], }}/></div>) : (<div dangerouslySetInnerHTML={{ __html: messageHTML }}/> ) }
        { this.state.isEditing ? (<div style={{ paddingTop: 5 }}>{controls}</div>) : (<span></span>)}
      </Callout>
    </Callout> );
  }
}

export default NewsItem;

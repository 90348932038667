
	'use strict';

	/*

		Service Rate Event Bus

	*/

	import Eventer from "../Eventer";
  import { get, post, del, put, patch, getErrorPayload } from "./SystemREST";
  var ServiceRateData = Eventer.createBus("SERVRATE");
  
	ServiceRateData.getRecordList = function (service_id) {
    return get("/service/"+service_id+"/rates").send().then(function(res) {
      var payload = res.body;
      ServiceRateData.notifyListeners("LIST_LOADED", payload.rates);
      return payload.rates;
    }).catch (function (err) {
      ServiceRateData.notifyListeners("LIST_LOAD_ERROR", getErrorPayload(err))
      throw err
    });
  };

	ServiceRateData.updateRecord = function (service_id, rate) {
    post("/service/"+service_id+"/rate/update").send({rate : rate}).then(function(res) {
      var payload = res.body;
      ServiceRateData.notifyListeners("RECORD_UPDATED", payload.rate);
    }, function (err) {
      ServiceRateData.notifyListeners("RECORD_UPDATE_ERROR", getErrorPayload(err));
    });
  };

	ServiceRateData.createRecord = function (service_id, rate_type) {
    post("/service/"+service_id+"/rate/add").send({rate_type : rate_type}).then(function(res) {
      var payload = res.body;
      ServiceRateData.notifyListeners("RECORD_CREATED", payload.rate);
    }, function (err) {
      ServiceRateData.notifyListeners("RECORD_CREATE_ERROR", getErrorPayload(err));
    });
  };

	ServiceRateData.removeRecord = function (service_id, rate) {
    post("/service/"+service_id+"/rate/remove").send({rate : rate}).then(function(res) {
      var payload = res.body;
      ServiceRateData.notifyListeners("RECORD_REMOVED", payload.rate);
    }, function (err) {
      ServiceRateData.notifyListeners("RECORD_REMOVE_ERROR", getErrorPayload(err));
    });
  };

  export default ServiceRateData;

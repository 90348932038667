import { useState } from "react"
import { Button, HTMLSelect, InputGroup, ControlGroup, Tag, FormGroup } from "@blueprintjs/core"
let MultiPhoneField = ({ value, onChange, eventMode }) => {

    let [pendingValue, setPendingValue] = useState("");
    let [pendingType, setPendingType] = useState("mobile");

    let deleteAction = (index) => {
        let valueList = `${value}`.split(",");
        valueList.splice(index, 1);
        onChange({ target: { value: valueList.join(",") } });
    }
    
    let plainChangeAction = (index, event) => {
        let valueList = `${value}`.split(",");
        valueList.splice(index, 1, event.target.value);
        onChange({ target: { value: valueList.join(",") } });
    }
    let compositeChangeAction = (index, componentIndex, event) => {
        let valueList = `${value}`.split(",");
        let number = valueList[index];
        let numberComponents = `${number}`.split(":");
        if(componentIndex == 0) { valueList.splice(index, 1, `${event.target.value}:${numberComponents[1]}`); }
        if(componentIndex == 1) { valueList.splice(index, 1, `${numberComponents[0]}:${event.target.value}`); }
        onChange({ target: { value: valueList.join(",") } });
    }

    let addAction = (e) => {
        if(pendingValue.length < 1) { return; } 
        onChange({ target: { value: `${value}${(value.length > 0 ? "," : "")}${(pendingType === "" ? "" : `${pendingType}:`)}${pendingValue}` } });
        setPendingValue("");
    }

    let phoneNumberTypeOptions = [
        <option key="pnto-main" value={"main"}>Main</option>,
        <option key="pnto-lab" value={"lab"}>Lab</option>,
        <option key="pnto-mobile" value={"mobile"}>Cell/Mobile</option>,
        <option key="pnto-home" value={"home"}>Home</option>,
        <option key="pnto-other" value={""}>Custom/Other</option>
    ]

    let valueElements = `${value}`.split(",").map((v,i) => {
        let numberComponents = `${v}`.split(":");
        if (numberComponents.length == 1) { 
            return (<ControlGroup key={`phn_${i}`}><InputGroup type="text" value={v} onChange={plainChangeAction.bind(null, i)}/><Button intent="danger" icon="delete" onClick={deleteAction.bind(null,i )}/></ControlGroup>); 
        }
        if (numberComponents.length == 2) { 
            return (<ControlGroup key={`phn_${i}`}><HTMLSelect value={numberComponents[0]} onChange={compositeChangeAction.bind(null, i, 0)}>{phoneNumberTypeOptions}</HTMLSelect><InputGroup type="text" value={numberComponents[1]} onChange={compositeChangeAction.bind(null, i, 1)}/><Button intent="danger" icon="delete" onClick={deleteAction.bind(null,i )}/></ControlGroup>);
        }
        return <Tag key={`phn_${i}`} intent="danger" onRemove={deleteAction.bind(null, i)}> Invalid Phone Number Config : {v} </Tag>;
    })
    return <div style={{ display: "flex", flexDirection:"column", gap: "5px" }}>
        <FormGroup label="Contact Numbers" >
            <div style={{ display: "flex", flexDirection:"column", gap: "5px" }}>
                {valueElements}
            </div>
        </FormGroup>
        <FormGroup label="Add New Contact Number"><ControlGroup><HTMLSelect value={pendingType} onChange={(e) => setPendingType(e.target.value)}>{phoneNumberTypeOptions}</HTMLSelect><InputGroup type="text" value={pendingValue} onKeyPress={(e) => { if(e.key == "Enter") { addAction(); }; }} onChange={(e) => setPendingValue(e.target.value)}/><Button intent="primary" icon="add" onClick={addAction}/></ControlGroup></FormGroup>
    </div>
}
export default MultiPhoneField

export let recordFormShim = (props) => {
    return <MultiPhoneField value={props.value} onChange={(e) => { props.onChange(e.target.value, props.fieldKey) }}/>;
}

'use strict';

/*

    // ScheduledMessageData Module Event Bus. Thin passthrough to API.

*/

const { get, post, del, getErrorPayload }  = require('./SystemREST')
import AppData from "data-modules/AppData";
import moment from "moment-timezone";
import Eventer from "../Eventer";
var ScheduledMessageData = Eventer.createBus("SMD");
// AppData.injectJWTErrorListener(ScheduledMessageData);

ScheduledMessageData.getPendingEventNotifications = function (event) {
    return get(`/scheduled_messages/event/${event.id}`).send().then((res) => {
        let payload = res.body;
        ScheduledMessageData.notifyListeners("EVENT_NOTIFICATIONS_LOADED", payload);
        return Promise.resolve(payload);
    }).catch((err) => {
        ScheduledMessageData.notifyListeners("EVENT_NOTIFICATIONS_LOAD_ERROR", getErrorPayload(err));
        return Promise.reject(getErrorPayload(err));
    })
}

ScheduledMessageData.getPendingSelfNotifications = function (event) {
    return get(`/scheduled_messages/user/self`).send().then((res) => {
        let payload = res.body;
        ScheduledMessageData.notifyListeners("EVENT_NOTIFICATIONS_LOADED", payload);
        return Promise.resolve(payload);
    }).catch((err) => {
        ScheduledMessageData.notifyListeners("EVENT_NOTIFICATIONS_LOAD_ERROR", getErrorPayload(err));
        return Promise.reject(getErrorPayload(err));
    })
}

ScheduledMessageData.createEventNotification = function (event, notificationOffset) {
    if(typeof notificationOffset == "undefined") { notificationOffset = 15;}
    return post(`/scheduled_messages`).send({
        send_date: moment(event.start_date).subtract(notificationOffset, "minutes").format("YYYY-MM-DD HH:mm:ss"),
        message_title: `Event on ${event.service.name} scheduled to start in ${notificationOffset} minutes`,
        message_template: `The following event is scheduled to begin in ${notificationOffset} minutes: \n\n Service: ${event.service.name} \n Start Date: ${moment(event.start_date).tz(AppData.timezone()).format("YYYY-MM-DD HH:mm:ss")}`,
        message_data: {},
        target_addresses: `@self`,
        tags: `event::${event.id},offset::${notificationOffset}`
    }).then((res) => {
        let payload = res.body;
        ScheduledMessageData.notifyListeners("EVENT_NOTIFICATION_CREATED", payload);
        return Promise.resolve(payload);
    }).catch((err) => {
        ScheduledMessageData.notifyListeners("EVENT_NOTIFICATION_CREATE_ERROR", getErrorPayload(err));
        return Promise.reject(getErrorPayload(err));
    })
}

ScheduledMessageData.deleteNotification = function (id) {
    return del(`/scheduled_messages/${id}`).send().then((res) => {
        let payload = res.body;
        ScheduledMessageData.notifyListeners("EVENT_NOTIFICATION_DELETED", payload);
        return Promise.resolve(payload);
    }).catch((err) => {
        ScheduledMessageData.notifyListeners("EVENT_NOTIFICATION_DELETE_ERROR", getErrorPayload(err));
        return Promise.reject(getErrorPayload(err));
    })
}

export default ScheduledMessageData;


'use strict';

import React from "react"
import { Button, InputGroup } from "@blueprintjs/core"
import { account_types } from "data-modules/Enum"
/*
  There are a lot of validation checks and UI components that need to be run for the individual lines. 
  This is a line item UI element to standarize that checking.
*/
class StaticOrderLine extends React.Component {
  constructor(props) {
    super(props)
    this.onChange = this.onChange.bind(this);
    this.removeAction = this.removeAction.bind(this);
  }

  onChange(key, evt) {
    let newValue = evt.target.value;
    this.props.onChange(this.props.index, key, newValue);
  }

  removeAction() {
    this.props.onRemove(this.props.index);
  }

  render() {
    let style={
      container : {display : "block", margin : 5, width : "100%" },
      input : { display : "inline-block", minWidth: 150, paddingLeft: 5 },
      total : { display : "inline-block", minWidth: 80 },
      inputTitle : { fontWeight : "bold", fontSize : "0.75rem", marginBottom : 5, color: "gray" },
      inputTitleError : { fontWeight : "bold", fontSize : "0.75rem", marginBottom : 5, color : "red" }
    }

    let self = this;
    let type = this.props.type;
    let data = this.props.data;
    let index = this.props.index;
    let account = this.props.account;
    let preset = this.props.preset;
    
    let optionalElements = this.props.optionals.map(function (optional, index) {
      return (<div key={"optional_"+self.props.index+"_"+index} style={style.input}>
        <div style={style.inputTitle}> {optional.label} </div>
        <textarea style={{ marginRight: 5 }} value={data[optional.key]} placeholder="(Optional)" onChange={self.onChange.bind(null, optional.key)}></textarea>
      </div>);
    });

    let descriptionValid = true;
    let rateValid = true;
    let commercialValid = true;
    let quantityValid = true;
    let errorMessages = [];
  
    if(parseFloat(data.quantity) < parseFloat(preset.min_quantity)) { quantityValid = false; errorMessages.push(` Quantities less than ${preset.min_quantity} not allowed.`); }
    if(parseFloat(preset.max_quantity) !== 0 && parseFloat(data.quantity) > parseFloat(preset.max_quantity)) { quantityValid = false; errorMessages.push(` Quantities greater than ${preset.max_quantity} not allowed.`); }
    if(data.commercial_rate == 0) { commercialValid = false; }
    if(data.rate == 0) { rateValid = false; }
    if(data.description.length < 1) { descriptionValid = false; }
    /* The line type is based on the form type and a specification of the line. All data comes from a line_preset object
      Type 0 : Manager only freeform field
      Type 1 : User editable quantity field
      Type 2 : Un-editable fee field
    */
    switch(type) {
      case 0 : {
        return (<div style={style.container}>
          <div style={style.input}>
            <div style={((descriptionValid) ? style.inputTitle : style.inputTitleError)}> Description </div>
            <InputGroup style={{ marginRight: 5 }} type="text" value={data.description} onChange={this.onChange.bind(null, 'description')} placeholder="Item Description"/>
          </div>
          <div style={style.input}>
            <div style={((quantityValid) ? style.inputTitle : style.inputTitleError)}> Amount </div>
            <InputGroup style={{ marginRight: 5 }} type="text" value={data.quantity} onChange={this.onChange.bind(null, 'quantity')}/>
          </div>
          <div style={style.input}>
            <div style={((rateValid) ? style.inputTitle : style.inputTitleError)}> Rate </div>
            <InputGroup style={{ marginRight: 5 }} type="text" value={data.rate} onChange={this.onChange.bind(null, 'rate')}/>
          </div>
          <div style={style.input}>
            <div style={((commercialValid) ? style.inputTitle : style.inputTitleError)}> Commercial Rate </div>
            <InputGroup style={{ marginRight: 5 }} type="text" value={data.commercial_rate} onChange={this.onChange.bind(null, 'commercial_rate')}/>
          </div>
          
          <div style={style.total}>
            <div style={style.inputTitle}> Line Total </div>
            <span style={{ color: "green" }}> ${(account && account.type == account_types.COMMERCIAL) ? `${(data.commercial_rate * data.quantity).toFixed(2)}` : (data.rate * data.quantity).toFixed(2)} </span>
          </div>
          <Button key={"remove_index_"+index} style={{ marginBottom : 10 }} onClick={this.removeAction} text={"Remove"}/>
          <div>
            {optionalElements}
          </div>
        </div>);
      } break;
      case 1 : {
        let rateElement = <span>${parseFloat(data.rate).toFixed(2)}</span>
        if(account && account.type == account_types.COMMERCIAL) { rateElement = <span>${data.commercial_rate.toFixed(2)}</span>}
        return (<div style={style.container}>
          <div style={style.input}>
            <div style={style.inputTitle}> Description </div>
            <span>{data.description}</span>
          </div>
          <div style={{ marginTop: 5 }}>
            <div style={style.input}>
              <div style={((quantityValid) ? style.inputTitle : style.inputTitleError)}> Amount {`[${preset.min_quantity}-${(preset.max_quantity !== 0 ? preset.max_quantity : "any" )}]` } </div>
              <InputGroup style={{ marginRight: 5 }} type="text" value={data.quantity} onChange={this.onChange.bind(null, 'quantity')}/>
            </div>
            <div style={style.input}>
              <div style={style.inputTitle}> Rate </div>
              {rateElement}
            </div>
            <div style={style.total}>
              <div style={style.inputTitle}> Line Total </div>
              <span style={{ color: "green" }}> ${(account && account.type == account_types.COMMERCIAL) ? `${(data.commercial_rate * data.quantity).toFixed(2)}` : (data.rate * data.quantity).toFixed(2)} </span>
            </div>
          </div>
          <div>
            {optionalElements}
          </div>
        </div>);
      } break;
      case 2 : {
        let rateElement = <span>${parseFloat(data.rate).toFixed(2)}</span>
        if(account && account.type == account_types.COMMERCIAL) { rateElement = <span>${data.commercial_rate.toFixed(2)}</span>}
        return (<div style={style.container}>
          <div style={style.input}>
            <div style={style.inputTitle}> Description </div>
            <span>{data.description}</span>
          </div>
          <div style={style.input}>
            <div style={style.inputTitle}> Amount </div>
            <span>{data.quantity}</span>
          </div>
          <div style={style.input}>
            <div style={style.inputTitle}> Rate </div>
            {rateElement}
          </div>
          {optionalElements}
          <div style={style.total}>
            <div style={style.inputTitle}> Line Total </div>
            <span style={{ color: "green" }}> ${(account && account.type == account_types.COMMERCIAL) ? `${(data.commercial_rate * data.quantity).toFixed(2)}` : (data.rate * data.quantity).toFixed(2)} </span>
          </div>
        </div>);
      } break;
      default : {
        return (<div style={style.container}> Invalid Rate/Row Type </div>)
      } break;
    }
  }
}

export default StaticOrderLine;

import React from 'react' ;
import moment from "moment" ;
import DataManager from "data-modules/DataManager"
let DM = new DataManager();
import ServiceData from "data-modules/ServiceData" ;
import LineItemData from "data-modules/LineItemData" ;
import OrderForm from "./OrderForm";
import CustomDataForm from "./CustomDataForm";
import SiteDialogData from "data-modules/SiteDialogData";

class ServicePage extends React.Component {
  constructor(props) {
    super(props);

    let sessionDate = moment( sessionStorage.getItem("scheduler_referenceDate"));
    let refDate = sessionDate.isValid() ? sessionDate : moment();

    this.state = {
      service : {},
      events : [],
      units : [],
      referenceDate : refDate,
      rates : []
    }

    this.componentDidMount = this.componentDidMount.bind(this)
    this.componentDidUpdate = this.componentDidUpdate.bind(this)
    this.componentWillUnmount = this.componentWillUnmount.bind(this)
    this.onServiceLoaded = this.onServiceLoaded.bind(this);
    this.submitForm = this.submitForm.bind(this)
  }
  componentDidMount() {
    let params = this.props.params;

    DM.add(ServiceData.registerListener("RECORD_LOADED", this.onServiceLoaded), ServiceData);
    DM.add(LineItemData.registerListener("ORDER_CREATED", this.onOrderCreated), LineItemData);
    DM.add(LineItemData.registerListener("ORDER_CREATE_ERROR", this.onOrderError), LineItemData);

    ServiceData.getRecord(params.service_id);
  }
  componentDidUpdate(prevProps) {
    let params = this.props.params;
    if(params.service_id != prevProps.params.service_id) {
      ServiceData.getRecord(params.service_id);
    }
  }
  componentWillUnmount() {
    DM.clear();
  }
  onServiceLoaded(payload, action) {
    if(payload.type == 2 || payload.type == 1) {
      window.location.hash = payload.link;
    } else {
      this.setState({service : payload});
    }
  }
  onOrderCreated(payload, action) {
    SiteDialogData.open("Order Created", "Your order has been created with ID : "+payload+". Returning to service selection list.", function () {
      window.history.back();
    });
  }
  onOrderError(payload, action) {
    SiteDialogData.open("Error Creating Order", "There was an error creating the order: "+payload.message, function () {
      // window.history.back();
    });
  }
  submitForm(orderData) {
    LineItemData.createOrder(orderData);
    return false;
  }
  render() {

      let style={
        container : {
          backgroundColor : "white",
          padding: 5
        }
      }

      let id = this.state.service.id;
      let serviceElement = (<div> Unknown Service Type : {id} </div>);
      let type = this.state.service.type;

      /*
        Service Types
        0 - Scheduled
        1 - Linked
        2 - Custom
        3 - Line Item
        4 - Form Template
      */
      switch(type) {
        case 0 : { serviceElement = (<div> Scheduler II </div>); } break;
        case 1 : { serviceElement = (<div> Linked Item </div>); } break;
        case 2 : { serviceElement = (<div> Custom </div>); } break;
        case 3 : { serviceElement = (<div> <OrderForm service={this.state.service} onFormSubmit={this.submitForm}/> </div>); } break;
        case 4 : { serviceElement = (<div> <OrderForm service={this.state.service} onFormSubmit={this.submitForm}/> </div>); } break;
        case 8 : { serviceElement = (<div> <CustomDataForm service={this.state.service}/> </div>); } break;
        default : { serviceElement = (<div> Unknown Service Type </div>) } break;
      }

      return (
        <div style={style.container}>
            {serviceElement}
        </div>
      );
  }
}

import withRouter from "lib/withRouter";
let Page = withRouter(ServicePage);
export default Page;

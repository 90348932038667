
'use strict';

import React from 'react'
import { debounce  } from 'lodash';
import RecordList from '../RecordList'
import { Button, InputGroup, HTMLSelect, ControlGroup, ProgressBar } from "@blueprintjs/core";
import DataManager from "data-modules/DataManager"
let DM = new DataManager();

class SearchableList extends React.Component{
  
  constructor(props) {
    super(props)
    let defaultPageSize = 10;
    if(this.props.defaultPageSize) { defaultPageSize = this.props.defaultPageSize; }
  
    this.state = {
      listData: [],
      listIsLoading: false,
      recordCount: 0,
      filterValue : "",
      filterKey : this.props.searchConfig.options[0].value,
      pageSize : defaultPageSize,
      currentPage : 1
    };

    this.onRowClick = this.onRowClick.bind(this);
    this.onListLoaded = this.onListLoaded.bind(this);
    this.onListLoadError = this.onListLoadError.bind(this);
    this.onAjaxError = this.onAjaxError.bind(this);
    this.updateFilterKey = this.updateFilterKey.bind(this);
    this.updateFilterValue = this.updateFilterValue.bind(this);
    this.setPageSize = this.setPageSize.bind(this);
    this.advancePage = this.advancePage.bind(this);
    this.retreatPage = this.retreatPage.bind(this);
    this.getRecords = this.getRecords.bind(this);

  }

  componentDidMount () {
    let dataSource = this.props.dataSource;

    DM.add(dataSource.registerListener("LIST_LOADED", this.onListLoaded), dataSource);
    DM.add(dataSource.registerListener("RECORD_UPDATED", this.getRecords), dataSource);
    DM.add(dataSource.registerListener("RECORD_CREATED", this.getRecords), dataSource);
    DM.add(dataSource.registerListener("LIST_LOAD_ERROR", this.onListLoadError), dataSource);  
    this.setState({ listIsLoading: true })
    dataSource.getRecordList(Object.assign({
      _page: this.state.currentPage,
      _pageSize: this.state.pageSize
    }, dataSource.searchConfig.urlAppends || {} )).then(({ records, count }) => {
      this.setState({ listData : records, recordCount: count, listIsLoading: false });
    }); // Insert any instance specific URL appends to the query definition object
    
  }
  componentWillUnmount () {
    DM.clear();
  }

  onListLoaded ({records, count}, action) {
    this.setState({ listData : records, recordCount: count, listIsLoading: false });
  }
  onListLoadError (payload, action) {
    this.setState({ listData : [], recordCount: 0, listIsLoading: false });
  }
  onAjaxError (payload, action) {
    this.setState({responseMessage : JSON.stringify(payload)});
    console.log(payload, action);
  }

  onRowClick(event, rowId, record) {
    if(typeof this.props.onRowClick != "undefined") {
      this.props.onRowClick(event, rowId, record);
    }
  }

  debouncedRecordFetch = debounce(() => {
    this.getRecords()
  }, 500)

  getRecords() {
    let dataSource = this.props.dataSource;
    let queryOptions = {
      _page: this.state.currentPage,
      _pageSize: this.state.pageSize,
      where: {}
    }
    queryOptions.where[this.state.filterKey] = this.state.filterValue;
    this.setState({ listIsLoading: true });
    dataSource.getRecordList(Object.assign(queryOptions, dataSource.searchConfig.urlAppends || {} )).then(({ records, count }) => {
      this.setState({ listData : records, recordCount: count, listIsLoading: false });
    }); // Insert any instance specific URL appends to the query definition object
    
  }

  updateFilterKey(event) {
    let newValue = event.target.value;
    this.setState({filterKey : newValue, currentPage : 1 }, this.getRecords);
  }
  updateFilterValue(event) {
    let newValue = event.target.value;
    this.setState({filterValue : newValue, currentPage : 1, listIsLoading : true }, this.debouncedRecordFetch);
  }

  setPageSize(newSize, event) {
    this.setState({ pageSize : newSize }, this.getRecords);
  }

  advancePage() {
    let pageCount = Math.ceil(this.state.recordCount/this.state.pageSize);
    let new_page = (this.state.currentPage + 1 > pageCount ? this.state.currentPage : this.state.currentPage + 1);
    this.setState({ currentPage : new_page  }, this.getRecords)
  }
  retreatPage() {
    let new_page = (this.state.currentPage <= 1 ? this.state.currentPage : this.state.currentPage - 1);
    this.setState({ currentPage : new_page }, this.getRecords);
  }

  render() {
    let style = {
      container : {
        width: "100%"
      },
      searchField : {
        fontSize : "1rem",
        borderTop : "0px solid black",
        borderLeft : "0px solid black",
        borderRight : "0px solid black",
        borderBottom : "1px solid black",
        width: "50%"
      },
      searchTitle : {
        marginLeft : 5,
        fontSize : "1rem"
      },
      positionHeader: {
        fontSize : "1rem",
        color: "gray"
      },
      searchKey : {
        fontSize : "1rem"
      },
      pageSizeHeader : {
        display : "inline-block",
        float : "right"
      },
      pageSizeSelector : {
        fontSize : "1rem",
        marginLeft : 5,
        cursor : "default"
      },
      pageSizeSelectorActive : {
        fontSize : "1rem",
        textDecoration : "underline",
        marginLeft : 5,
        cursor : "default",
        fontWeight : "bold",
        color : "red"
      }
    };

    let optionElements = this.props.searchConfig.options.map(function (keyData, index) {
      return (<option key={index} value={keyData.value}> {keyData.title} </option>)
    });

    let pageSize = this.state.pageSize;
    let pageCount = Math.ceil(this.state.recordCount/pageSize);

    return (
      <div style={style.container}>
        <ControlGroup>
          <HTMLSelect value={this.state.filterKey} onChange={this.updateFilterKey}>
            {optionElements}
          </HTMLSelect>
          <InputGroup type="text" value={this.state.filterValue} onChange={this.updateFilterValue} fill={true}/>
        </ControlGroup>
        <div style={{ marginTop : "10px", marginBottom : "10px", width: "100%" }}>
          <Button icon="arrow-left" minimal={true} disabled={this.state.currentPage <= 1} style={{ width: "15%", textAlign: "center", float : "left", display : "inline-block" }} onClick={this.retreatPage}/>
          <span style={{ width: "60%", textAlign: "center", display : "inline-block" }}> Page {this.state.currentPage} of {pageCount} </span>
          <Button icon="arrow-right" minimal={true} disabled={this.state.currentPage >= pageCount} style={{ width: "15%", textAlign: "center", float : "right", display : "inline-block" }} onClick={this.advancePage}/>
        </div>
        { this.state.listIsLoading ? <ProgressBar/> : <RecordList dataSource={this.state.listData} rowTitle={this.props.rowTitle} rowSubtitle={this.props.rowSubtitle} rowStyle={this.props.rowStyle} onRowClick={this.onRowClick}/> }
      </div>
    );
  }
}

export default SearchableList;

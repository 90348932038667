
'use strict';

import React from 'react';
import AppData from 'data-modules/AppData';
import UserData from 'data-modules/UserData';
import DataManager from "data-modules/DataManager"
import MetricsData from "data-modules/MetricsData";
let DM = new DataManager();;
import moment from "moment";
import style from "./style";
import { HTMLTable, NonIdealState, Classes, InputGroup, AnchorButton, Button, Intent, Tag, Callout, H2, ProgressBar, FormGroup } from "@blueprintjs/core"
import { DateRangeInput } from "@blueprintjs/datetime";
import SecureForm from "generic-components/SecureForm"

class MyOrdersModule extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      orders : [],
      invoices: [],
      pi_orders: [],
      pi_charges_loading: true,
      invoice_filter_value : "",
      order_filter_value : "",
      range: [moment().add(-1, 'months').toDate(), moment().toDate()]
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.componentWillUnmount = this.componentWillUnmount.bind(this);
    this.ordersLoaded = this.ordersLoaded.bind(this);
    this.invoicesLoaded = this.invoicesLoaded.bind(this);
    this.onRangeUpdate = this.onRangeUpdate.bind(this);
    this.pendingChargesLoaded = this.pendingChargesLoaded.bind(this);
  }
  componentDidMount () {
    DM.add(UserData.registerListener("MY_INVOICES_LIST_LOADED", this.invoicesLoaded), UserData);
    DM.add(UserData.registerListener("ORDER_LIST_LOADED", this.ordersLoaded), UserData);
    DM.add(UserData.registerListener("PENDING_CHARGES_LOADED", this.pendingChargesLoaded), UserData);
    UserData.getOrders(AppData.get("userID"), [moment().add(-1, 'months').toDate(), moment().toDate()]);
    UserData.getMyInvoices([moment().add(-1, 'months').toDate(), moment().toDate()]);
    UserData.getMyPendingCharges();
    MetricsData.recordClientMetric({
      metric: "component-mount",
      feature: "my-orders",
      numerical_value: 1,
      precision: 0,
    })
    this.setState({ pi_charges_loading: true })
  }
  componentWillUnmount () {
    DM.clear();
  }
  ordersLoaded (orders, action) {
    this.setState({ 'orders' : orders });
  }
  pendingChargesLoaded(orders, action) {
    this.setState({ pi_orders : orders, pi_charges_loading : false})
  }
  invoicesLoaded (invoices, action) {
    this.setState({ 'invoices' : invoices });
  }
  onRangeUpdate (range) {
    if(range[0] !== null && range[1] !== null) {
      UserData.getOrders(AppData.get("userID"), range)
      UserData.getMyInvoices(range);
    }
    this.setState({ 'range' : range });
  }
  render () {
    if (this.state.orders.length < 1 && this.state.invoices.length < 1) { return <div style={style.container}> 
      <h1 className={Classes.HEADING}>My Orders</h1>
      <FormGroup label="Search Range">
        <DateRangeInput 
          minDate={moment().add(-5, "years").toDate()} 
          maxDate={moment().add(1, "years").toDate()} 
          formatDate={(date) => moment(date).format("YYYY-MM-DD")}
          parseDate={(date) => moment(date, "YYYY-MM-DD").toDate()}
          value={this.state.range} 
          onChange={this.onRangeUpdate}/>
      </FormGroup>
      <NonIdealState icon="disable" title="No Results Available" description="You have no orders or invoices for the specified time period."/> 
    </div> }
  
    let orderList = this.state.orders.filter((row) => {
      let fv = this.state.order_filter_value;
      if (fv.length < 1) { return true; }
      row.service_name = row.service.name;
      return Object.keys(row).reduce((acc, key) => {
        let cv = row[key];
        if (`${cv}`.toLowerCase().includes(fv.toLowerCase())) { return true; }
        return acc;
      }, false)
    }).map(function (order, index) {
      let orderLinkUrl = "/#/order/" + order.id;
      return (<tr key={index} style={{ marginBottom : 5 }}>
        <td> <AnchorButton small={true} style={{ marginRight: 5, width: 100, fontWeight: "bold", display: "inline-block" }} href={orderLinkUrl}> {order.id} </AnchorButton> </td>
        <td> <Tag style={{ marginRight : 5 }} round={true} intent={Intent.SUCCESS}>{order.service.name}</Tag> </td>
        <td> <Tag style={{ marginRight : 5 }} round={true}>{moment(order.order_date).format("YYYY-MM-DD HH:mm")}</Tag> </td>
        </tr>);
    });
  
    let invoiceList = this.state.invoices.filter((row) => {
      let fv = this.state.invoice_filter_value;
      if (fv.length < 1) { return true; }
      return Object.keys(row).reduce((acc, key) => {
        let cv = row[key];
        if (`${cv}`.toLowerCase().includes(fv.toLowerCase())) { return true; }
        return acc;
      }, false)
    }).map(function (row, index) {
      let invoiceTagStyle = ((row) => {
        if(row.order_owner !== row.account_pi) { return Intent.PRIMARY; }
        return Intent.SUCCESS;
      })(row)
      return (<tr key={index} style={{ marginBottom: 5 }}>
        <td> <SecureForm method="post" action={"/invoice/print/" + row.invoice_id + ".pdf"} token={AppData.get("authToken")}>
          <Button type="submit" small={true} style={{ marginRight: 5 }}> View </Button>
        </SecureForm> </td>
        <td> <Tag style={{ marginRight: 5 }} round={true} intent={invoiceTagStyle}>{`${row.PROJECT || ""}${row.ACTCODE || ""}`}</Tag> </td>
        <td> <Tag style={{ marginRight: 5 }} round={true} intent={invoiceTagStyle}>{row.account_title}</Tag> </td>
        <td> <Tag style={{ marginRight: 5 }} round={true}>{moment(row.invoice_date).format("YYYY-MM-DD")}</Tag> </td>
      </tr>);
    });

    let pendingTotal = 0;
    let piPendingRows = (this.state.pi_charges_loading ? <tr><td colSpan={5}><ProgressBar/></td></tr> : this.state.pi_orders.map((o, i) => {
      pendingTotal += parseFloat(o.estimated_total);
      let orderLinkUrl = "/#/order/" + o.id;
      return <tr key={`pior_${o.id}}`}>
        <td><AnchorButton small={true} style={{ marginRight: 5, width: 100, fontWeight: "bold", display: "inline-block" }} href={orderLinkUrl}> {o.id} </AnchorButton></td>
        <td>{o.service.name}</td>
        <td>{o.account.title}</td>
        <td>{o.user.full_name}</td>
        <td>${o.estimated_total}</td>
      </tr>
    }))

    return (<div style={style.container}>
      <h1 className={ Classes.HEADING }>My Orders</h1>
      
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ margin: 5 }} className={Classes.CALLOUT}>
          <H2> My Orders </H2>
          <br/>
          <FormGroup label="Search Range">
            <DateRangeInput 
              minDate={moment().add(-5, "years").toDate()} 
              maxDate={moment().add(1, "years").toDate()} 
              formatDate={(date) => moment(date).format("YYYY-MM-DD")}
              parseDate={(date) => moment(date, "YYYY-MM-DD").toDate()}
              value={this.state.range} 
              onChange={this.onRangeUpdate}/>
          </FormGroup>
          <div>
            <InputGroup leftIcon="search" value={this.state.order_filter_value} placeholder="Filter Orders..." onChange={(evt) => { this.setState({ order_filter_value: evt.target.value }) }} />
          </div>
          <HTMLTable compact={true} striped={true} bordered={true} style={{ width: "100%" }}>
            <thead>
              <tr>
                <th> Order ID </th>
                <th> Service </th>
                <th> Order Date </th>
              </tr>
            </thead>
            <tbody>
              {orderList}
            </tbody>
          </HTMLTable>
        </div>
        <div style={{ margin: 5 }} className={Classes.CALLOUT}>
          <H2> My Invoices </H2>
          <br/>
          <FormGroup>
            <InputGroup leftIcon="search" value={this.state.invoice_filter_value} placeholder="Filter Invoices..." onChange={(evt) => { this.setState({ invoice_filter_value : evt.target.value })}}/>
          </FormGroup>
          <div style={{paddingTop: 10}}>
            <Tag style={{ marginRight: 5 }} round={true} intent={Intent.SUCCESS}>My Orders</Tag><Tag style={{ marginRight: 5 }} round={true} intent={Intent.PRIMARY}>I'm Account Owner (PI)</Tag>
          </div>
          <HTMLTable compact={true} striped={true} bordered={true} style={{ width: "100%" }}>
            <thead>
              <tr>
                <th> Invoice ID </th>
                <th> Project/Activity </th>
                <th> Account Title </th>
                <th> Invoice Date </th>
              </tr>
            </thead>
            <tbody>
              {invoiceList}
            </tbody>
          </HTMLTable>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row", flexWrap:"wrap", justifyContent: "space-around"}}>
        <div style={{ flex: "auto", padding: 5 }}>
          <H2> Pending charges on accounts I own </H2>
          <Callout style={{ marginBottom : 10 }}>
          <HTMLTable compact={true} striped={true} bordered={true} style={{ width: "100%" }}>
            <thead>
              <tr>
                <th> Order ID</th>
                <th> Service </th>
                <th> Account Title </th>
                <th> Charging User </th>
                <th> Estimated Total {this.state.pi_charges_loading ? "" : `($${pendingTotal.toFixed(2)})`} </th>
              </tr>
            </thead>
            <tbody>
              {piPendingRows}
            </tbody>
          </HTMLTable>
          </Callout>
        </div>
      </div>
      
      
    </div>);
  }
}

export default MyOrdersModule;

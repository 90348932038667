
'use strict';

import React from 'react';
import moment from "moment";
import ServiceButton from "./ServiceButton";
import NewsItem from "./NewsItem";
import ManageNewsItems from "./ManageNewsItems";
import AppData from "data-modules/AppData";
import GroupData from "../Data/GroupData";
import DataManager from "data-modules/DataManager"
let DM = new DataManager();
import { Button, Dialog, H2, H3, Callout, Icon } from "@blueprintjs/core";
import ManagerGroupPermissionsEditor from "generic-components/ManagerGroupPermissionsEditor";
import ServiceUserTagManager from 'generic-components/ServiceUserTagManager';
import ServicesTree from './ServicesTree';

class GroupPage extends React.Component {
  constructor () {
    super();
    this.state = {
      services : [],
      news : [],
      group : {},
      showing_permission_manager: false,
      showing_tag_manager: false,
      show_hidden_news: false
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.componentWillUnmount = this.componentWillUnmount.bind(this);
    this.onGroupLoaded = this.onGroupLoaded.bind(this);
    this.onServicesLoaded = this.onServicesLoaded.bind(this);
    this.onNewsLoaded = this.onNewsLoaded.bind(this);
    this.onLoadError = this.onLoadError.bind(this);
    this.onAjaxError = this.onAjaxError.bind(this);
    this.openPermissionManager = this.openPermissionManager.bind(this);
    this.openServiceTagManager = this.openServiceTagManager.bind(this);
  }
  componentDidMount () {
    let self = this;
    let params = this.props.params;
    DM.add(GroupData.registerListener("RECORD_LOADED", this.onGroupLoaded), GroupData);
    DM.add(GroupData.registerListener("RECORD_LOAD_ERROR", this.onLoadError), GroupData);
    DM.add(GroupData.registerListener("SERVICES_LOADED", this.onServicesLoaded), GroupData);
    DM.add(GroupData.registerListener("SERVICES_LOAD_ERROR", this.onLoadError), GroupData);
    DM.add(GroupData.registerListener("NEWS_LOADED", this.onNewsLoaded), GroupData);
    DM.add(GroupData.registerListener("NEWS_LOAD_ERROR", this.onLoadError), GroupData);
    GroupData.getGroup(params.id);
    GroupData.getGroupServices(params.id);
    GroupData.getGroupNews(params.id, self.state.show_hidden_news);
  }
  componentWillUnmount () {
    DM.clear();
  }
  onGroupLoaded (payload, action) {
    this.setState({ group : payload});
  }
  onServicesLoaded (payload, action) {
    this.setState({ services : payload});
  }
  onNewsLoaded (payload, action) {
    this.setState({ news : payload });
  }
  onLoadError (payload, action) {
    this.setState({ services : [] });
  }
  onAjaxError (payload, action) {
    this.setState({ services : [] });
  }
  openPermissionManager() {
    this.setState({ showing_permission_manager: true });
  }
  openServiceTagManager(service) { 
    this.setState({ showing_tag_manager: service });
  }

  render () {

      var style={
        container : {
          padding: 5
        },
        service_container : {
          display : "flex",
          flexDirection : "column",
          width : "33%",
          padding: 5,
        },
        news_container : {
          display : "flex",
          flexDirection : "column",
          width: "66%",
          gap: 10,
          paddingTop: 5
        },
        title : {
          fontSize : "2.0rem",
          fontWeight : "bold",
          padding: "10px 20px",          
          backgroundColor : "#cecece",
          display : "flex",
          alignItems : "center",
          gap: 10,
          textShadow : "2px 2px 0px rgba(0, 0, 0, 0.25)",
          justifyContent : "space-between"
        }
      }

    if (this.state.group.status == 0) {
      return <div>
        <div style={style.container}>
            <center><H2> This group has been disabled. All related services are unavailable at this time. </H2></center>
        </div>
      </div>
    }

    

      let self = this;
      let hasScheduledService = false;
      let isManager = AppData.authorize(20, self.state.group.id);
      // this.state.services.sort(function (a, b) {
      //   var displayWeight = b.display_weight - a.display_weight;
      //   if(displayWeight == 0) { return a.id-b.id; }
      //   return displayWeight;
      // }).map(function (service, index) {
      //   var shouldDisplay = true;
      //   if(parseInt(service.enabled,10) < 1) {shouldDisplay = false;}
      //   if(service.is_manager_only > 0 && !AppData.authorize(20, service.group_id)) { shouldDisplay = false; }
      //   if(service.type == 0) { hasScheduledService = true; }
      //   if(shouldDisplay) {
      //     serviceElements.push(<ServiceButton key={index} service={service} color={"gray"} onOpenTagManager={self.openServiceTagManager} />);
      //   }
      // });

      let _insertToPath = (root, pathComponents, service, depth) => {
        if(pathComponents.length == 0) { root._services.push(service); }
        if(pathComponents.length > 0 ) { root[pathComponents[0]] = _insertToPath((root[pathComponents[0]] || { _services: [] }), pathComponents.slice(1), service, depth + 1); }
        return root;
      }

      let serviceCount = 0;
      let servicesTree = this.state.services.sort(function (a, b) {
        var displayWeight = b.display_weight - a.display_weight;
        if(displayWeight == 0) { return a.id-b.id; }
        return displayWeight;
      }).filter((service) =>  {
        var shouldDisplay = true;
        if(parseInt(service.enabled,10) < 1) {shouldDisplay = false;}
        if(service.is_manager_only > 0 && !AppData.authorize(20, service.group_id)) { shouldDisplay = false; }
        if(service.type == 0) { hasScheduledService = true; }
        return shouldDisplay;
      }).reduce((tree, service, index) => {
        serviceCount++;
        let servicePath = service.display_path || "/";
        let filteredPathComponents = servicePath.split("/").filter(c => c.length > 0);
        return _insertToPath(tree, filteredPathComponents, service, 0);
      }, { _services: [] })
    
      // if(hasScheduledService) {
      //   serviceElements.unshift(<ServiceButton key={"gsv-button"} service={{ 
      //     id: self.state.group.id,
      //     group_id: self.state.group.id,
      //     type: "group-scheduled",
      //     name: "All Scheduled Resources",
      //     location: ""
      //   }}/>)
      // }

      var newsElements = [];
      if (this.state.news.length < 1) {
        var noNewsItem = {
          title : "No News",
          date : moment(),
          visible : 1,
          content : "This group has no news."
        };
        newsElements.push(<NewsItem key={0} item={noNewsItem}/>);
      } else {
        
        this.state.news.map(function (news_item, index) {
          newsElements.push(<NewsItem key={index} item={news_item} showControls={isManager} onEndEdit={() => { GroupData.getGroupNews(self.state.group.id, self.state.show_hidden_news); }}/>);
        });
      }

      let permissionManager = <span></span>;
        if(AppData.authorize(20, this.state.group.id)) {
          permissionManager = (<div style={{ display: "flex", gap: 15, alignItems : "center" }}>
            <Button text="Manage User Permissions" icon="user" onClick={self.openPermissionManager}/>
            <Dialog 
              isOpen={self.state.showing_permission_manager}
              isCloseButtonShown={true}
              onClose={() => { self.setState({ showing_permission_manager: false }); }}
              title={"Group User Permissions Manager"}
              style={{ width: "75%" }}
            >
              <ManagerGroupPermissionsEditor group={this.state.group}/>
            </Dialog>
          </div>);
        } 

        let tagManager = <span></span>;
        if(AppData.authorize(20, this.state.group.id)) {
          tagManager = <Dialog
            isOpen={this.state.showing_tag_manager !== false}
            isCloseButtonShown={true}
            onClose={() => { self.setState({ showing_tag_manager: false }); }}
            title={`${this.state.showing_tag_manager?.name} - Tag Manager`}
            style={{ width: "50%" }}
          >
            <ServiceUserTagManager record={this.state.showing_tag_manager}/>
          </Dialog>
        }


      var group_title = this.state.group.name || "Unknown";
      return (
        <div>
          <div style={style.container}>
            <div style={{ width: "100%", padding: 5 }}>
              <Callout key={0} style={style.title}>
                <div style={{ display: "flex", gap: 15, alignItems : "center" }}><Icon icon="office" size={36} style={{ color: "#BE0000" }}/>{group_title}</div>
                <div style={{ display: "flex", gap: 15, alignItems : "center" }}>{permissionManager}</div>
              </Callout>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between"}}>
              <div key={1} style={style.service_container}>
                <div style={{ display: "flex", gap: 10, justifyContent: "center", borderBottom: "2px solid #BE0000" }}>
                  <Icon icon="lab-test" size={24} style={{ color: "#BE0000" }}/>
                  <H3> Available Services </H3>
                </div>
                {(hasScheduledService ? <><ServiceButton key={"gsv-button"} showInfo={false} showGroup={false} service={{ 
                  id: self.state.group.id,
                  group_id: self.state.group.id,
                  type: "group-scheduled",
                  name: "All Scheduled Resources",
                  location: "",
                }}/><div style={{ height: 2, width: "100%", backgroundColor: "#BE0000", marginBottom: 5 }}></div></> : "")}
                <ServicesTree tree={servicesTree} openTagManagerAction={self.openServiceTagManager}/>
                {(serviceCount == 0 ? <span key={0}> No services available at this time.</span> : "")}
              </div>
              <div key={2} style={style.news_container}>
                <div style={{ display: "flex", gap: 10, justifyContent: "center", borderBottom: "2px solid #BE0000" }}>
                  <Icon icon="feed" size={24} style={{ color: "#BE0000" }}/>
                  <H3> News </H3>
                </div>
                <ManageNewsItems groupId={this.state.group.id} show={isManager} onEndEdit={() => { GroupData.getGroupNews(self.state.group.id); }}/>
                {newsElements}
              </div>
            </div>
            {tagManager}
          </div>
        </div>

      );
  }
}

export default GroupPage;

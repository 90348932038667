
  'use strict';

  let { user_types } = require('./Data/Enum');

  var config = {};

  // Site Title Configuration
  config.site_title = "University of Utah Core Labs";

  // Site Nav Bar Configuration
  config.navbar = [
    { title : "Resources", path: "#/home", icon: "layout-auto", minAuth: user_types.PENDING },
    { title : "My Account", path: "#/my_account", icon: "people", minAuth: user_types.PENDING },
    { title : "My Orders/Invoices", path: "#/my_orders", icon: "dashboard", minAuth: user_types.PENDING },
    { title : "Docs", path: "/docs", target: "_blank", icon: "info-sign", minAuth: user_types.PENDING },
    { title : "Auditing", path: "#/audit", icon: "edit", minAuth: user_types.AUDITOR },
    { title : "Reports", path: "#/report", icon: "document", minAuth: user_types.AUDITOR },
    { title : "Invoice", path: "#/invoice", icon: "label", minAuth: user_types.AUDITOR },
    { title: "Message", path: "#/message", icon: "envelope", minAuth: user_types.MANAGER },
    { title: "Quotes", path: "#/quotes", icon: "projects", minAuth: user_types.MANAGER },
    { title : "Manage", path: "#/manage", icon: "build", minAuth: user_types.MANAGER },
    // { title: "Admin", path: "#/admin", icon: "database", minAuth: user_types.ADMINISTRATOR }
  ];

  config.manage = {};
  // Management Navbar Configuration
  config.manage.navbar = [
    { name : "Manage Approvals", icon: "tick", link : "#/manage/approval", minAuth : user_types.MANAGER },
    { name : "Manage Users", icon: "people", link : "#/manage/user", minAuth : user_types.ADMINISTRATOR },
    { name : "Manage Groups", icon: "projects", link : "#/manage/group", minAuth : user_types.MANAGER },
    { name : "Manage Accounts", icon: "label", link : "#/manage/account", minAuth : user_types.ADMINISTRATOR },
    { name : "Orders", icon: "dashboard", link : "#/manage/order", minAuth : user_types.ADMINISTRATOR }
  ];

  config.manage.forms = {};
  config.manage.forms.news = {
    title : "News",
    fields : [
      { key : "id", name : "ID", type : "number", enabled: false, options : [], required: true, defaultValue : -1 }
    ]
  };
  config.manage.forms.services = {
    title : "Service",
    fields : [
      { key : "id", name : "ID", type : "number", enabled: false, options : [], required: true, defaultValue : -1 }
    ]
  };
  config.manage.forms.groups = {
    title : "Group",
    fields : [
      { key : "id", name : "ID", type : "number", enabled: false, options : [], required: true, defaultValue : -1 }
    ]
  };
  config.manage.forms.users = {
    title : "User",
    fields : [
      { key : "id", name : "ID", type : "number", enabled: false, options : [], required: true, defaultValue : -1 }
    ]
  };
  config.manage.forms.accounts = {
    title : "Account",
    fields : [
      { key : "id", name : "ID", type : "number", enabled: false, options : [], required: true, defaultValue : -1 }
    ]
  };


  export default config;


'use strict';

import React from 'react';
import AppData from 'data-modules/AppData';
import UserData from 'data-modules/UserData';
import DataManager from "data-modules/DataManager";
import MetricsData from "data-modules/MetricsData";
let DM = new DataManager();
import { Spinner, Tag, Intent, FormGroup, InputGroup, Button, H2, Callout, ControlGroup, HTMLTable, Checkbox } from "@blueprintjs/core"
import { PasswordManager } from "generic-components/PasswordManager"
import MultiPhoneField from "generic-components/MultiPhoneField";
import UserPendingNotifications from "./pendingNotificationsList"
import EmailListField from "generic-components/EmailListField";
import moment from "moment"
import style from "./style"
import { ProfilePictureManager } from "../Generic/ProfilePictureManager";

// Shamelessly Lifted From https://www.emailregex.com
let emailValidator = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class MyAccountModule extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      user : null,
      accounts : [],
      isDirty : false
    };
    this.componentDidMount = this.componentDidMount.bind(this);
    this.componentWillUnmount = this.componentWillUnmount.bind(this);
    this.recordLoaded = this.recordLoaded.bind(this);
    this.accountsLoaded = this.accountsLoaded.bind(this);
    this.defaultAccountUpdated = this.defaultAccountUpdated.bind(this);
    this.updateField = this.updateField.bind(this);
    this.revertChanges = this.revertChanges.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
    this.recordUpdated = this.recordUpdated.bind(this);
    this.updateCancellationEmailSetting = this.updateCancellationEmailSetting.bind(this);
    this.updateEmailOptoutSetting = this.updateEmailOptoutSetting.bind(this);
  }
  componentDidMount() {
    DM.add(UserData.registerListener("RECORD_LOADED", this.recordLoaded), UserData);
    DM.add(UserData.registerListener("RECORD_UPDATED", this.recordUpdated), UserData);
    DM.add(UserData.registerListener("ACCOUNT_LIST_LOADED", this.accountsLoaded), UserData);
    DM.add(UserData.registerListener("DEFAULT_ACCOUNT_UPDATED", this.defaultAccountUpdated), UserData);
    UserData.getRecord(AppData.get("userID"));
    UserData.getActiveAccounts(AppData.get("userID"));
    MetricsData.recordClientMetric({
      metric: "component-mount",
      feature: "my-account-management",
      numerical_value: 1,
      precision: 0,
    })
  }
  componentWillUnmount() {
    DM.clear();
  }
  recordLoaded(user, action) {
    this.setState({'user' : user, isDirty: false });
  }
  accountsLoaded(accounts, action) {
    this.setState({'accounts' : accounts});
  }
  defaultAccountUpdated(new_account_id, action) {
    let user = this.state.user;
    user.default_account = new_account_id;
    this.setState({ 'user' : user });
  }
  updateField(key, event) {
    let user = this.state.user;
    user[key] = event.target.value;
    this.setState({
      user : user,
      isDirty : true
    });
  }
  revertChanges() {
    UserData.getRecord(AppData.get("userID"));
  }
  saveChanges() {
    UserData.updateRecord(this.state.user);
  }
  recordUpdated(user, action) {
    this.setState({
      user : user,
      isDirty : false
    });
  }

  shouldReceiveCancellationEmails(user) {
    return (`${user.tags}`).split(",").reduce((acc, tag) => {
      if(/^notification:service_cancel:.*/.test(tag)) { return true }
      return acc;
    }, false)
  }

  updateCancellationEmailSetting() {
    let user = this.state.user;
    let tags = (`${user.tags}`).split(",").map((tag) => { return tag;});
    if(this.shouldReceiveCancellationEmails(user)) {
      tags = tags.filter((tag) => { return (/^notification:service_cancel:.*/.test(tag) ? false : true) })
    } else {
      tags.push("notification:service_cancel:3M")
    }
    user.tags = tags.join(",");
    this.setState({user : user, isDirty: true });
  }

  shouldOptOutOfEmails(user) {
    return (`${user.tags}`).split(",").reduce((acc, tag) => {
      if(/opt_out_email_notifications/.test(tag)) { return true }
      return acc;
    }, false)
  }

  updateEmailOptoutSetting() {
    let user = this.state.user;
    let tags = (`${user.tags}`).split(",").map((tag) => { return tag;});
    if(this.shouldOptOutOfEmails(user)) {
      tags = tags.filter((tag) => { return (/opt_out_email_notifications/.test(tag) ? false : true) })
    } else {
      tags.push("opt_out_email_notifications")
    }
    console.log(tags)
    user.tags = tags.join(",");
    this.setState({user : user, isDirty: true });
  }



  render() {
    let self = this;

    if(self.state.user === null) { return <div> <Spinner/> </div> }

    let accountList = <tr><td colSpan="3"><div> You have no accounts. </div></td></tr>
    if(this.state.accounts.length > 0) {

      accountList = this.state.accounts.map(function (account, index) {
        let accountStatusTags = [];
        let allowDefault = true;
        if(moment(account.valid_to).isBefore(moment())) {
          accountStatusTags.push(<Tag key={"account_"+account.id+"_expired"} round={true} intent={Intent.DANGER}> Account Expired </Tag>)
          allowDefault = false;
        }
        if(account.status > 0) {
          accountStatusTags.push(<Tag key={"account_"+account.id+"_disabled"} round={true} intent={Intent.DANGER}> Account Disabled </Tag>)
          allowDefault = false;
        }

        if(self.state.user.default_account == account.id) {
          accountStatusTags.push(<Button key={"account_"+account.id+"_default"} small={true} intent={Intent.SUCCESS} style={{marginLeft : 5}} > Default Account </Button>)
        } else {
          if(allowDefault) {
            accountStatusTags.push(<Button key={"account_"+account.id+"_set_default"} onClick={() => {
              UserData.setDefaultAccount(self.state.user, account);
            }} small={true} style={{marginLeft : 5, cursor: 'default'}} > Make Default </Button>)
          }
        }
        return (<tr key={index}>
            <td><Tag> {moment(account.valid_to).format("YYYY-MM-DD")} </Tag></td>
            <td> {accountStatusTags} </td>
            <td> {account.title} </td>
          </tr>);
      });
    }


    let emailIsValid = `${this.state.user.email}`.split(",").reduce((acc, email) => {
      return (emailValidator.test(email) ? acc : false);
    }, true)
    let detailElement = <div style={{ display: "grid", gridTemplateColumns: "32% 32% 32%", gridColumnGap:"10px" }}>
      <FormGroup label="First Name">
        <InputGroup type={"text"} fill={true} placeholder={"First Name"} value={this.state.user.first_name} onChange={this.updateField.bind(null, "first_name")}/>
      </FormGroup>
      <FormGroup label="Middle Name (Optional)">
        <InputGroup type={"text"} fill={true} placeholder={"Middle Name (Optional)"} value={this.state.user.middle_name} onChange={this.updateField.bind(null, "middle_name")}/>
      </FormGroup>
      <FormGroup label="Last Name">
        <InputGroup type={"text"} fill={true} placeholder={"Last Name"} value={this.state.user.last_name} onChange={this.updateField.bind(null, "last_name")}/>
      </FormGroup>
      <FormGroup label="Email(s)" labelInfo={(emailIsValid ? "" : "(Invalid email in list)")} >
        <EmailListField  value={this.state.user.email} onChange={(newValue) => { this.updateField("email", { target: { value: newValue}}); }}/>
      </FormGroup>
      <Callout style={{ gridColumnEnd: "span 2" }}>
        <MultiPhoneField value={this.state.user.phone_number} onChange={this.updateField.bind(null, "phone_number")}/>
      </Callout>
      <FormGroup label="Opt Out of Email Notifications" inline={true}>
        <Checkbox checked={this.shouldOptOutOfEmails(self.state.user)} onChange={this.updateEmailOptoutSetting}/>
      </FormGroup>
    </div>;

    let controlElements = <div style={{ marginBottom : 10 }}>
      <Button intent={Intent.PRIMARY} disabled={true}> Save Changes </Button>
    </div>;
    if(this.state.isDirty && emailIsValid) {
      controlElements = <ControlGroup style={{ marginBottom : 10 }}>
        <Button intent={Intent.PRIMARY} onClick={this.saveChanges}> Save Changes </Button>
        <Button intent={Intent.WARNING} onClick={this.revertChanges}> Revert Changes </Button>
      </ControlGroup>
    }

    let passwordElement = <div> Password Management Disabled </div>;
    if(this.state.user !== null) { passwordElement = <PasswordManager record={this.state.user}/> }

    return (<div style={style.container}>
      <div style={{ display: "flex", flexDirection: "row", flexWrap:"wrap", justifyContent: "space-around"}}>
        <div style={{ flex: "auto", padding: 5 }}>
          <H2>My Details</H2>
          <Callout style={{ marginBottom : 10 }}>
            {detailElement}
            {controlElements}
          </Callout>
        </div>
        <div style={{ flex: "auto", padding: 5 }}>
          <H2>Profile Picture</H2>
          <Callout style={{ marginBottom : 10 }}>
            <ProfilePictureManager isEditable record={this.state.user}/>
          </Callout>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row", flexWrap:"wrap", justifyContent: "space-around"}}>
        <div style={{ flex: "auto", padding: 5 }}>
          <H2>Password Management</H2>
          <Callout style={{ marginBottom : 10 }}>
            {passwordElement}
          </Callout>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row", flexWrap:"wrap", justifyContent: "space-around"}}>
        <div style={{ flex: "auto", padding: 5 }}>
          <H2>Pending Notifications</H2>
          <Callout style={{ marginBottom : 10 }}>
            <UserPendingNotifications/>
          </Callout>
        </div>
        <div style={{ flex: "auto", padding: 5 }}>
          <H2> My Charge Accounts </H2>
          <Callout style={{ marginBottom : 10 }}>
            <HTMLTable striped={true} bordered={true} compact={true} style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th style={{ width: 150 }}> Expires </th>
                  <th style={{ width: 150 }}> Actions </th>
                  <th> Account Title </th>
                </tr>
              </thead>
              <tbody>
                {accountList}
              </tbody>
            </HTMLTable>
          </Callout>
        </div>
      </div>
    </div>);
  }
}

export default MyAccountModule;


'use strict';
import React from "react"
import SearchableList from "../SearchableList"
import { Button, ControlGroup, InputGroup, Intent, PopoverInteractionKind, Position, FormGroup, Popover, H5 } from "@blueprintjs/core"

class SearchPicker extends React.Component{
  constructor(props) {
    super(props)
    this.state = {
      isPicking : false,
      searchKey : "title",
      searchValue : "",
      selectedRecord : null,
      isLoadingList : false
    };

    this.componentDidMount = this.componentDidMount.bind(this)
    this.componentDidUpdate = this.componentDidUpdate.bind(this)
    this.componentWillUnmount = this.componentWillUnmount.bind(this)
    this.onRecordLoaded = this.onRecordLoaded.bind(this)
    this.onRecordLoadError = this.onRecordLoadError.bind(this)
    this.onListLoadError = this.onListLoadError.bind(this)
    this.beginSelect = this.beginSelect.bind(this)
    this.endSelect = this.endSelect.bind(this)
    this.onSearchValueChange = this.onSearchValueChange.bind(this)
    this.onSearchKeyChange = this.onSearchKeyChange.bind(this)
    this.onRowClick = this.onRowClick.bind(this)
  }
  componentDidMount () {
    let dataSource = this.props.dataSource;
    let responders = [];
    responders.push(dataSource.registerListener("RECORD_LOADED", this.onRecordLoaded));
    responders.push(dataSource.registerListener("RECORD_LOAD_ERROR", this.onRecordLoadError));
    responders.push(dataSource.registerListener("LIST_LOAD_ERROR", this.onListLoadError));
    this.setState({
      'responders' : responders,
      isLoadingList : false
    });
    if(this.props.value) { dataSource.getRecord(this.props.value); }
    
  }
  componentDidUpdate (prevProps) {
    let dataSource = this.props.dataSource;
    if(prevProps.value !== this.props.value) {
      dataSource.getRecord(this.props.value);
    }
  }
  componentWillUnmount () {
    let dataSource = this.props.dataSource;
    this.state.responders.forEach(function (responder, index, sourceArray) {
      dataSource.deregisterListener(responder);
    });
  }
  onRecordLoaded (payload, action) {
    this.setState({ selectedRecord : payload });
  }
  onRecordLoadError (payload, action) {
    this.setState({ selectedRecord : null });
  }
  onListLoadError (payload, action) {
    console.log(action, payload);
  }
  beginSelect () {
    this.setState({ isPicking : true });
  }
  endSelect () {
    this.setState({ isPicking : false });
  }
  onSearchValueChange (event) {
    let newValue = event.target.value;
    this.setState({searchValue : newValue});
  }
  onSearchKeyChange (newValue, event) {
    this.setState({searchKey : newValue, searchValue : ""});

  }
  onRowClick (event, rowId, record) {
    console.log(this.props.fieldKey,record[this.props.config.rowSelectKey]);
    this.props.onSelect(record[this.props.config.rowSelectKey], this.props.fieldKey);
    this.setState({ isPicking : false });
  }
  render () {
    let config = this.props.config;
    let title = config.title;
    let selectedRecord = this.state.selectedRecord;

    let searchPaneContent = <div style={{ backgroundColor: "white", margin: "2px solid gray", padding: 5 }}>
      <H5> Search </H5>
      <SearchableList searchConfig={config.searchConfig} rowTitle={config.listMapping.title} rowSubtitle={config.listMapping.subtitle} defaultPageSize={5} dataSource={this.props.dataSource} onRowClick={this.onRowClick}/>
      <Button intent={Intent.WARNING} onClick={this.endSelect}> Cancel Search </Button>
    </div>;

    let searchButton = <Popover content={searchPaneContent} isOpen={this.state.isPicking} interactionKind={PopoverInteractionKind.CLICK} position={Position.RIGHT}><Button onClick={this.beginSelect} icon={"search"} intent={Intent.PRIMARY}/></Popover>

    let element = <ControlGroup> <InputGroup value={"(Unknown/None)"} style={{ minWidth: 350, backgroundColor: "white", color: "black" }} disabled={true}/> {searchButton} </ControlGroup>;
    if(this.state.isLoadingList) { element = <ControlGroup> <InputGroup value={"(Loading Record)"} style={{ minWidth: 300, backgroundColor: "white", color: "black" }} disabled={true}/> <Button icon={"search"} intent={Intent.PRIMARY}/> </ControlGroup>; }
    if (typeof this.state.selectedRecord !== "undefined" && this.state.selectedRecord !== null) {
      let displayText = selectedRecord[this.props.config.rowDisplayKey];
      element = <ControlGroup> <InputGroup value={displayText} style={{ minWidth: 300, backgroundColor: "white", color: "black" }} disabled={true}/> {searchButton} </ControlGroup>;
    }

    return (<FormGroup label={title} style={{ margin: 5 }}>
      {element}
    </FormGroup>);
  }
}

export default SearchPicker;


'use strict';

import React from "react"
import { Button, Intent, HTMLTable, ControlGroup, Label, Classes } from "@blueprintjs/core"

class PagedListSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            pageSize: props.pageSize || 10
        }
        
        this.pageBack = this.pageBack.bind(this);
        this.pageForward = this.pageForward.bind(this);
    }

    pageBack() {
        let newPage = this.state.page - 1;
        if(this.state.page == 0 ) { newPage = 0; }
        this.setState({ page: newPage });
    }

    pageForward() { 
        let newPage = this.state.page + 1;
        if(newPage * this.state.pageSize  > this.props.records.length ) { newPage = this.state.page; }
        this.setState({ page: newPage });
    }

    render() {

        let self = this;
        let page = self.state.page;
        let pageSize = self.state.pageSize;
        let allowSelect = () => { return true; };
        if(typeof self.props.allowSelect === "function") { allowSelect = self.props.allowSelect; }
        

        let dataHeaders = self.props.columns.map((columnConfig, index) => {
            return (<th key={`pls_header_${index}`}>{columnConfig.label}</th>);
        })

        dataHeaders.unshift(<th key={`pls_header_select`}>Select</th>)

        let dataRows = dataRows = self.props.records.slice(page * pageSize, (page + 1) * pageSize).map((record, index) => {
            return (<tr key={"pls_"+index}>
                <td>
                    <Button intent={Intent.PRIMARY} className={Classes.POPOVER_DISMISS} disabled={!allowSelect(record)} onClick={self.props.onSelect.bind(null, record)}> Select </Button>
                </td>
                {self.props.columns.map((columnConfig, index) => {
                    if(columnConfig.display_extract) {
                        return (<td key={"pls_col_"+index}>{columnConfig.display_extract(record[columnConfig.data_key])}</td>);
                    } else {
                        return (<td key={"pls_col_"+index}>{(record[columnConfig.data_key] || "")}</td>);    
                    }
                })}
            </tr>)
        });

        if(self.props.activeRecord) {
            dataRows.unshift(<tr key={"pls_current"}>
                <td>
                    <Button style={{ float: 'right' }} intent={Intent.SUCCESS} className={Classes.POPOVER_DISMISS} onClick={self.props.onSelect.bind(null, self.props.activeRecord)}> Use Current </Button>
                </td>
                {self.props.columns.map((columnConfig, index) => {
                    if(columnConfig.display_extract) {
                        return (<td key={"pls_col_"+index}>{columnConfig.display_extract(self.props.activeRecord[columnConfig.data_key])}</td>);
                    } else {
                        return (<td key={"pls_col_"+index}>{(self.props.activeRecord[columnConfig.data_key] || "")}</td>);    
                    }
                })}
            </tr>)
        }

        return (<HTMLTable striped={true} compact={true} interactive={true}>
            <thead>
                <tr>
                    {dataHeaders}
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {dataRows}
            </tbody>
            <tfoot>
                <tr>
                    <td colSpan={self.props.columns.length + 1}>
                        <ControlGroup fill={true}>
                            <Button minimal={true} icon="caret-left" disabled={false} onClick={self.pageBack}></Button>
                            <Label style={{ textAlign: "center" }}> Page {self.state.page + 1} of {Math.floor(self.props.records.length / pageSize) + 1}</Label>
                            <Button minimal={true} icon="caret-right" disabled={false} onClick={self.pageForward}></Button>
                        </ControlGroup>
                    </td>
                </tr>
            </tfoot>
        </HTMLTable>)
    }
}

export { PagedListSelect };
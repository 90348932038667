
import Eventer from "../Eventer";
import { get, post, put, patch, del, getErrorPayload  } from "data-modules/SystemREST";

var MetricsData = Eventer.createBus("METRIC");

MetricsData.recordClientMetric = function (data) {
    return post("/metrics/client").send(data).then(function(res) {
            var payload = res.body;
            MetricsData.notifyListeners("METRIC_CREATED", payload);
        }, function (err) {
            MetricsData.notifyListeners("METRIC_CREATION_ERROR", getErrorPayload(err));
            return Promise.reject(getErrorPayload(err));
        });
}

export default MetricsData;
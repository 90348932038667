import { Button, Callout } from "@blueprintjs/core";
export default ({ records, onPageAdvance, onPageRetreat, currentPage, pageSize, totalRecordCount, actions, labelExtractor }) => {
    let recordElements = records.map((record, index) => {
        let recordLabel = "Record Here";
        if(labelExtractor !== undefined) { recordLabel = labelExtractor(record); }
        let actionButtons = actions.map((action, index) => { return <Button onClick={action.onClick.bind(null, record)} icon={action.icon} intent={action.intent || "none"} text={action.label || ""}/>})
        return <Callout key={`pal_${index}`} style={{ display: "flex", justifyContent: "space-between" }}>{recordLabel} {actionButtons}</Callout>;
    })

    // Note: Pagination Uses 1 based indexing, not 0.
    let maxPage = Math.floor(totalRecordCount/pageSize) + 1;

    let retreatPage = () => {
        let newPageNumber = currentPage;
        if(currentPage > 1) { newPageNumber--; }
        if(typeof onPageRetreat == "function") { onPageRetreat(newPageNumber) }
    }
    
    let advancePage = () => {
        let newPageNumber = currentPage;
        if(currentPage < maxPage) { newPageNumber++; }
        if(typeof onPageAdvance == "function") { onPageAdvance(newPageNumber) }
    }

    return (<div>
        <div style={{ display: 'flex', justifyContent: 'space-between'}}>
            <Button onClick={retreatPage} disabled={currentPage <= 1} icon="less-than"/><span>Page {currentPage} of {maxPage}</span><Button disabled={currentPage >= maxPage} icon="more-than" onClick={advancePage} icon="greater-than"/>
        </div>
        <div style={{ display: 'flex', flexDirection: "column", gap: "5px 0px", margin: 5 }}>
            {recordElements}
        </div>
    </div>)
}

	'use strict';

	/*

		Service Module Event Bus.

	*/

	import Eventer from "../Eventer";
	import { get, post, del, put, patch, getErrorPayload } from "./SystemREST";
	import URI from "urijs";
	import queryString from 'query-string';
	var ServiceData = Eventer.createBus("SERV");
	// AppData.injectJWTErrorListener(ServiceData)

	var _dataCache = {};

	// Uses Bit Masking to extract data enable settings
	ServiceData.dataIsActive = function (service, slot) {
		if(typeof service == "undefined") { return false; }
		var data_enable = service.data_enable;
		if (typeof data_enable == "undefined") { return false; }
		switch(slot) {
			case 1 : { return (parseInt(data_enable,10) & parseInt('00001', 2)) > 0;} break;
			case 2 : { return (parseInt(data_enable,10) & parseInt('00010', 2)) > 0;} break;
			case 3 : { return (parseInt(data_enable,10) & parseInt('00100', 2)) > 0;} break;
			case 4 : { return (parseInt(data_enable,10) & parseInt('01000', 2)) > 0;} break;
			case 5 : { return (parseInt(data_enable,10) & parseInt('10000', 2)) > 0;} break;
			default : { return false; }
		}
	}

	ServiceData.dayIsAllowed = function (service, day) {
		// Map Allowed Days via bit masking
		var allowed_days = service.allowed_days
		switch (day) {
			case "monday" : { return parseInt(allowed_days, 2) & parseInt('1000000', 2); } break;
			case "tuesday" : { return parseInt(allowed_days, 2) & parseInt('0100000', 2); } break;
			case "wednesday" : { return parseInt(allowed_days, 2) & parseInt('0010000', 2); } break;
			case "thursday" : { return parseInt(allowed_days, 2) & parseInt('0001000', 2); } break;
			case "friday" : { return parseInt(allowed_days, 2) & parseInt('0000100', 2); } break;
			case "saturday" : { return parseInt(allowed_days, 2) & parseInt('0000010', 2); } break;
			case "sunday" : { return parseInt(allowed_days, 2) & parseInt('0000001', 2); } break;
			default : { return false; } break;
		}
	}

	ServiceData.listMapping = {
		title : function (service) { return service.name; },
		subtitle : function (service) { 
			if (service.enabled == -1) { return "Archived"; }
			if (service.enabled == 0) { return "Disabled"; }
			if (service.enabled == 1) { return "Enabled"; }
			return "Unknown"
		},
		rowStyle : function (service) {
			if(service.enabled == -1) { return { backgroundColor: "rgba(0,0,255,0.25)" }; }
			if(service.enabled == 0) { return { backgroundColor: "rgba(255,0,0,0.25)" }; }
			return { backgroundColor: "rgba(0,255,0,0.25)" };
		}
	};

	ServiceData.searchConfig = { options: [
		{value: "name", title: "Name", type: "text"},
		{value: "group_id", title: "Group", type: "text"},
		{value: "id", title: "Service ID", type: "text"},
		{value: "enabled", title: "Enabled", type: "text"}
	]};

	ServiceData.getRecordList = function (opts) {


		let options = opts || {};
		let queryOptions = {};

		// Apply pagination if exists
		if(options._page && options._pageSize) {
			queryOptions._pageSize = options._pageSize;
			queryOptions._page = options._page;
		}
		
		// Apply query options if it exists
		if(options.where) { queryOptions = Object.assign(queryOptions, options.where) }

		// Apply Group Limiter
		if(options.group_id) { queryOptions.group_id = options.group_id; }

		let requestURL = `/services`;
		if(queryString.stringify(queryOptions).length > 0) { requestURL = `${requestURL}?${queryString.stringify(queryOptions)}`}

		return get(requestURL).send().then(function(res) {
			var payload = res.body;
			ServiceData.notifyListeners("LIST_LOADED", { records: payload.services, count: payload.count });
			return Promise.resolve({ records: payload.services, count: payload.count });
		}, function (err) {
			ServiceData.notifyListeners("LIST_LOAD_ERROR", getErrorPayload(err));
			return Promise.reject(getErrorPayload(err));
		});
	};

	ServiceData.updateRecord = function (recordData) {
		post("/service/update").send({service : recordData}).then(function(res) {
			var payload = res.body;
			delete _dataCache.services; // Clear Data Cache
			ServiceData.notifyListeners("RECORD_UPDATED", payload.service);
		}, function (err) {
			ServiceData.notifyListeners("RECORD_UPDATE_ERROR", getErrorPayload(err));
		});
	};

	ServiceData.createRecord = function (recordData) {
		post("/service/create").send({service : recordData}).then(function(res) {
			var payload = res.body;
			delete _dataCache.services; // Clear Data Cache
			ServiceData.notifyListeners("RECORD_CREATED", payload.service);
		}, function (err) {
			ServiceData.notifyListeners("RECORD_CREATE_ERROR", getErrorPayload(err));
		});
	};

	ServiceData.getRecord = function (record_id) {
		if(`${record_id}`.length < 1) { return; }
		return get("/service/"+record_id).send().then(function(res) {
			var payload = res.body;
			ServiceData.notifyListeners("RECORD_LOADED", payload.service);
			return payload.service;
		}, function (err) {
			ServiceData.notifyListeners("RECORD_LOAD_ERROR", getErrorPayload(err));
			throw err
		});
	};

	// Aliases / Specific Methods

	ServiceData.getServices = function (opts) {
		ServiceData.getRecordList(opts);
	};

	ServiceData.searchAvailable = function (filter) {
		let url = URI("/services/available");
		if (filter) { url.addSearch("filter", `${filter}`) }
		get(url.toString()).send().then(function (res) {
			var payload = res.body;
			ServiceData.notifyListeners("LIST_LOADED", payload.services);
		}, function (err) {
			ServiceData.notifyListeners("LIST_LOAD_ERROR", getErrorPayload(err));
		});
	}

	ServiceData.getMyServices = function () {
		get("/services/mine").send().then(function (res) {
			var payload = res.body;
			ServiceData.notifyListeners("LIST_LOADED", payload.services);
		}, function (err) {
			ServiceData.notifyListeners("LIST_LOAD_ERROR", getErrorPayload(err));
		});
	}

	ServiceData.getService = function (service_id) {
		ServiceData.getRecord(service_id);
	};

	ServiceData.getServiceTags = function (service_id, page, pageSize, searchKey, searchValue) {
		let queryConfig = { service_id: service_id, _page: page || 1, _pageSize: pageSize || 10 }
		if(searchKey && `${searchValue}`.length > 0) { queryConfig[searchKey] = searchValue; }
		return get(`/user_service_tags`).query(queryConfig).then((res) => {
		  ServiceData.notifyListeners("SERVICE_USER_TAG_LIST_LOADED", { records: res.body?.tags || [], count: res.body.count })
		  return { records: res.body?.tags || [], count: res.body.count };
		})
	  }
	
	ServiceData.upsertServiceTag = function (data) {
		return post('/service_user_tag').send(data).then((res) => {
			let payload = res.body;
			if(payload.mode == "create") { 
				ServiceData.notifyListeners("SERVICE_USER_TAG_CREATED", payload.tag); 
			} else {
				ServiceData.notifyListeners("SERVICE_USER_TAG_UPDATED", payload.tag);
			}
			return payload;
		})
	}

	ServiceData.deleteServiceTag = function (data) {
		return del('/service_user_tag').query({ service_id: data.service_id, user_id: data.user_id, tag_name: data.tag_name }).then((res) => {
			let payload = res.body;
			ServiceData.notifyListeners("SERVICE_USER_TAG_DELETED", payload.tag);
			return payload;
		})
	}

  export default ServiceData;

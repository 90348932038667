
	'use strict';

	/*

		Order Module Event Bus

	*/

  import AppData from "data-modules/AppData";
	import moment from "moment";
	import Eventer from "../Eventer";
  import { get, post, del, put, patch, getErrorPayload } from "./SystemREST";
  var OrderData = Eventer.createBus("ORD");
  // AppData.injectJWTErrorListener(OrderData);

  /*
    Service Types
    0 - Scheduled
    1 - Linked
    2 - Custom
    3 - Line Item
    4 - Form Template
  */

	var _dataCache = {};

	OrderData.listMapping = {
		title : function (order) { return order.full_name; },
		subtitle : function (order) { return order.ordername; }
	};

	OrderData.searchConfig = { options: [
		{value:"id", title : "ID" },
		{value:"full_name", title : "Name" },
		{value:"email", title : "Email" },
		{value:"ordername", title : "Username" }
	]};

	OrderData.isAccountValidSync = function (order) {
		var isValid = true;
		if(order.account.status > 0) { isValid = false; }
		if(moment(order.account.valid_to).isBefore(moment())) { isValid = false; }
		return isValid;
	}

	OrderData.getPendingOrders = function (group_id) {
		get("/orders/pending/"+group_id).send().then(function (res) {
      var payload = res.body;
      OrderData.notifyListeners("LIST_LOADED", payload.orders);
 		}, function (err) {
      OrderData.notifyListeners("LIST_LOAD_ERROR", getErrorPayload(err));
    })
	};

	OrderData.getApprovedOrders = function (group_id) {
		get("/orders/approved/"+group_id).send().then(function (res) {
      var payload = res.body;
      OrderData.notifyListeners("LIST_LOADED", payload.orders);
 		}, function (err) {
      OrderData.notifyListeners("LIST_LOAD_ERROR", getErrorPayload(err));
    })
	};
  
  OrderData.getWaitingOrders = function (group_id) {
		get("/orders/waiting/"+group_id).send().then(function (res) {
      var payload = res.body;
      OrderData.notifyListeners("LIST_LOADED", payload.orders);
 		}, function (err) {
      OrderData.notifyListeners("LIST_LOAD_ERROR", getErrorPayload(err));
    })
	};

	OrderData.getExcludedOrders = function (group_id, startDate, endDate) {
		get("/orders/excluded/"+group_id+"/"+startDate.format("YYYY-MM-DD")+"/"+endDate.format("YYYY-MM-DD")).send().then(function (res) {
        var payload = res.body;
        OrderData.notifyListeners("LIST_LOADED", payload.orders);
 		}, function (err) {
      OrderData.notifyListeners("LIST_LOAD_ERROR", getErrorPayload(err));
    })
	};

	OrderData.getBilledOrders = function (group_id, startDate, endDate) {
		get("/orders/billed/"+group_id+"/"+startDate.format("YYYY-MM-DD")+"/"+endDate.format("YYYY-MM-DD")).send().then(function (res) {
      var payload = res.body;
      OrderData.notifyListeners("LIST_LOADED", payload.orders);
 		}, function (err) {
      OrderData.notifyListeners("LIST_LOAD_ERROR", getErrorPayload(err));
    })
	};

	OrderData.getRecordList = function () {
		if(typeof _dataCache.orders !== "undefined") {
			OrderData.notifyListeners("LIST_LOADED", _dataCache.orders);
			return;
		}
    get("/orders").send().then(function(res) {
      var payload = res.body;
      _dataCache.orders = payload.orders;
      OrderData.notifyListeners("LIST_LOADED", payload.orders);
    }, function (err) {
      OrderData.notifyListeners("LIST_LOAD_ERROR", getErrorPayload(err));
    });
  };

	OrderData.getRecord = function (record_id) {
    get("/order/"+record_id).send().then(function(res) {
      var payload = res.body;
      OrderData.notifyListeners("RECORD_LOADED", payload.order);
    }, function (err) {
      OrderData.notifyListeners("RECORD_LOAD_ERROR", getErrorPayload(err));
    });

  };

	OrderData.updateRecord = function (recordData) {
    return post("/order/update").send({order : recordData}).then(function(res) {
      var payload = res.body;
      delete _dataCache.orders; // Clear Data Cache
      OrderData.notifyListeners("RECORD_UPDATED", payload.order);
      return payload.order;
    }, function (err) {
      OrderData.notifyListeners("RECORD_UPDATE_ERROR", getErrorPayload(err));
      return err;
    });

  };

  OrderData.setTax = function (order_id, sales_tax) {
    return post(`/order/set_tax/${order_id}`).send({ sales_tax_total: sales_tax }).then(function(res) {
      var payload = res.body;
      return payload.order;
    }, function (err) {
      OrderData.notifyListeners("RECORD_UPDATE_ERROR", getErrorPayload(err));
      return err;
    });

  };

	OrderData.preprocessBatch = function (orders) {
    post("/order/batch/preprocess").send({orders : orders}).then(function(res) {
      var payload = res.body;
      OrderData.notifyListeners("BATCH_PREPROCESSED", payload.orders);
    }, function (err) {
      OrderData.notifyListeners("BATCH_PREPROCESSING_ERROR", getErrorPayload(err));
    });

  };

	OrderData.createBatch = function (orders) {
    post("/order/batch").send({orders : orders}).then(function(res) {
      var payload = res.body;
      OrderData.notifyListeners("BATCH_CREATED", payload.orders);
    }, function (err) {
      OrderData.notifyListeners("BATCH_CREATING_ERROR", getErrorPayload(err));
    });

  };

	OrderData.createRecord = function (recordData) {
    post("/order/create").send({order : recordData}).then(function(res) {
      var payload = res.body;
      delete _dataCache.orders; // Clear Data Cache
      OrderData.notifyListeners("RECORD_CREATED", payload.order);
    }, function (err) {
      OrderData.notifyListeners("RECORD_CREATE_ERROR", getErrorPayload(err));
    });

  };

	// ---- Due to issues with doing approvals over WebSockets we use sequential recursion to process batch changes to orders ----
	// Actions: Refactor to do single endpoint call and apply returned batch changes to orders.
	OrderData.approveBatch = function (orders) {

		let order_ids = orders.map((o) => {
			return o.id;
		})
		post("/order/batch/approve").send(order_ids).then((res) => {
			var payload = res.body;
      OrderData.notifyListeners("BATCH_UPDATED", { mode: "approved", results: payload });
		}, function (err) {
      OrderData.notifyListeners("BATCH_UPDATE_ERROR", getErrorPayload(err));
    });
	}

	OrderData.unapproveBatch = function (orders) {

		let order_ids = orders.map((o) => {
			return o.id;
		})
		post("/order/batch/unapprove").send(order_ids).then((res) => {
			var payload = res.body;
      OrderData.notifyListeners("BATCH_UPDATED", { mode: "unapprove", results: payload });
		}, function (err) {
      OrderData.notifyListeners("BATCH_UPDATE_ERROR", getErrorPayload(err));
    });
	}

	OrderData.billBatch = function (orders) {


		let order_ids = orders.map((o) => {
			return o.id;
		})
		post("/order/batch/bill").send(order_ids).then((res) => {
			var payload = res.body;
      OrderData.notifyListeners("BATCH_UPDATED", { mode: "billed", results: payload });
		}, function (err) {
      OrderData.notifyListeners("BATCH_UPDATE_ERROR", getErrorPayload(err));
    });
	}

  OrderData.calculateBaseCost = function (order) {

    if(!order.events) { order.events = []; }
    if(!order.lines) { // TODO: In some places across both the client and server there is a switch between lines and line_items in the code that will need to be unified.
      if(typeof order.line_items !== "undefined") {
        order.lines = order.line_items;
      } else {
        order.lines = [];
      }
    }

    // Sum Events
    let eventTotal = order.events.reduce(function (prevTotal, event, index, sourceArray) {
      if(parseInt(order.account.type, 10) == 2 ) { // Commercial Account
        // Per Hour Event Type
        let onCost = parseFloat(event.on_hours_duration) * parseFloat(event.commercial_rate);
        let offCost = parseFloat(event.off_hours_duration) * parseFloat(event.commercial_off_hours_rate);
        let eventSubTotal = onCost + offCost + parseFloat(event.cost_modifier);

        if(event.event_rate !== null && event.event_rate.type == 1) { // Per Run Rate Type
          eventSubTotal = parseFloat(event.commercial_rate) + parseFloat(event.cost_modifier);
        }
        return prevTotal + eventSubTotal;

      } else { // Internal / OCE Accounts
        let onCost = parseFloat(event.on_hours_duration) * parseFloat(event.on_hours_rate);
        let offCost = parseFloat(event.off_hours_duration) * parseFloat(event.off_hours_rate);
        let eventSubTotal = onCost + offCost + parseFloat(event.cost_modifier);
        if(event.event_rate !== null && event.event_rate.type == 1) {
          eventSubTotal = parseFloat(event.on_hours_rate) + parseFloat(event.cost_modifier);
        }
        return prevTotal + eventSubTotal;
      }
    }, 0);

    // Sum Lines
    let lineTotal = order.lines.reduce(function (prevTotal, line, index, sourceArray) {
      let totalCost = 0;
      if(parseInt(order.account.type, 10) == 2 && line.commercial_rate !== null) {
        totalCost = parseFloat(line.quantity) * parseFloat(line.commercial_rate);
      } else {
        totalCost = parseFloat(line.quantity) * parseFloat(line.rate);
      }

      return prevTotal + (totalCost + parseFloat(line.cost_modifier));
    }, 0);

    return lineTotal + eventTotal;

  }

  OrderData.listTaxableForGroup = function (group_id) {
		return get(`/orders/taxable/${group_id}`).then((res) => {
			var payload = res.body;
      OrderData.notifyListeners("TAXABLE_LIST_LOADED", payload.orders);
      return payload.orders
		}, function (err) {
      OrderData.notifyListeners("TAXABLE_LIST_LOAD_ERROR", getErrorPayload(err));
    });
	}


  export default OrderData;

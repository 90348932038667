import { useState, useReducer, useEffect } from "react"
import { HTMLTable, HTMLSelect, ProgressBar, Spinner, Icon, Collapse, Button, InputGroup, ControlGroup, Menu, Checkbox, MenuItem, Tag } from "@blueprintjs/core"
import LineItemData from "data-modules/LineItemData"
import ServicePresetData from "data-modules/ServicePresetData"
import EditableOrderLine from "./EditableOrderLine"

export default function OrderLineItemManager({ order_id, service_id, event_duration }) {

    let [orderLines, setOrderLines] = useState([])
    let [servicePresets, setServicePresets] = useState([])
    let [isLoading, setIsLoading] = useState(true)
    let [isLoadingPresets, setIsLoadingPresets] = useState(true)
    let [showVoidedItems, setShowVoidedItems] = useState(false)
    let [expanded, setExpanded] = useState(false)
    let [presetMenuExpanded, setPresetMenuExpanded] = useState(false)
    let [pendingDescription, setPendingDescription] = useState("")
    let [pendingQuantity, setPendingQuantity] = useState("")
    let [pendingRate, setPendingRate] = useState("")
    let [pendingCommercialRate, setPendingCommercialRate] = useState("")
    let [isEditingDescription, setIsEditingDescription] = useState(false)
    let [isAddingLine, setIsAddingLine] = useState(false)

    useEffect(() => {
        loadOrderLines()
    }, [order_id])

    useEffect(() => {
        loadServicePresets()
    }, [service_id])

    let loadOrderLines = async () => {
        setIsLoading(true)
        let lines = await LineItemData.loadOrderLines(order_id)
        setOrderLines(lines)
        setIsLoading(false)
    }

    let loadServicePresets = async () => {
        setIsLoadingPresets(true)
        let presets = await ServicePresetData.getRecordList(service_id)
        setServicePresets(presets)
        setIsLoadingPresets(false)
    }

    let appendLineItem = async () => {
        setIsAddingLine(true)
        let result = await LineItemData.addLine(order_id, {
            description: pendingDescription,
            quantity: pendingQuantity,
            rate: pendingRate,
            commercial_rate: pendingCommercialRate
        })
        setIsAddingLine(false)
        setPendingDescription("")
        setPendingCommercialRate(0)
        setPendingQuantity(0)
        setPendingRate(0)
        loadOrderLines()
    }

    if(isLoading) { return <div style={{ padding: 5 }}><ProgressBar/></div> }

    let pendingIsValid = true;
    if(`${pendingDescription}`.length < 1) { pendingIsValid = false; }
    if(isNaN(parseFloat(pendingQuantity, 10)) || parseFloat(pendingQuantity, 10) === 0) { pendingIsValid = false; }
    if(isNaN(parseFloat(pendingRate, 10)) || parseFloat(pendingRate, 10) === 0) { pendingIsValid = false; }
    if(isNaN(parseFloat(pendingCommercialRate, 10)) || parseFloat(pendingCommercialRate, 10) === 0) { pendingIsValid = false; }

    return <div> 
        <div style={{ display: "flex", justifyContent: "space-between"}}>
            <span> Attached Line Items </span>
            <Button minimal={true} small={true} icon={(expanded)? "caret-up" : "caret-down"} onClick={() => setExpanded(!expanded)}/>
        </div>
        
        <Collapse isOpen={expanded} onToggle={() => setExpanded(!expanded)}>
            <div style={{ backgroundColor: "rgba(0,0,0,0.1)", padding: 5 }}>
                <div>
                    <Checkbox label="Show Voided Items" checked={showVoidedItems} onChange={() => setShowVoidedItems(!showVoidedItems)}/>
                </div>
                <h3> Attached Lines </h3>
                <div style={{ display: "flex", flexDirection: "column", gap: 5  }}>
                    {orderLines.filter((l) => {
                        if(showVoidedItems) { return true; }
                        return !(`${l.description}`.includes("[Voided"))
                    }).map(line => (<div key={line.id}>
                            <EditableOrderLine 
                                id={line.id} 
                                description={line.description} 
                                quantity={line.quantity} 
                                rate={line.rate} 
                                commercial_rate={line.commercial_rate}
                                onSaved={loadOrderLines}
                            />
                    </div>))}
                    <h3> Attach New Line </h3>
                    <div style={{ display: "flex", flexDirection: "row", gap: 10, borderTop: "1px solid gray", borderBottom: "1px solid gray", padding: "10px 0px" }}>
                            <div style={{ display: "flex", flexDirection: "column", gap: 10, width: "50%" }}>
                                <div style={{ display: "flex", flexDirection: "row", gap: 10 }} >
                                    <Button intent="primary" style={{ width: "15%" }} icon="caret-right" onClick={() => setPresetMenuExpanded(!presetMenuExpanded)}/>
                                        {( presetMenuExpanded ? <HTMLSelect value="-- Select a Preset --" onChange={(e) => {
                                            let p = servicePresets.find(p => p.id === parseInt(e.target.value, 10));
                                            if(p == undefined) { return; }
                                            setPendingDescription(p.label)
                                            setPendingQuantity(event_duration || p.min_quantity);
                                            setPendingRate(p.per_item_rate);
                                            setPendingCommercialRate(p.commercial_per_item_rate);
                                            setPresetMenuExpanded(false);
                                        }}>
                                            <option value={"-- Select a Preset --"} disabled> -- Select a Preset -- </option>
                                            {servicePresets.map((p) => (<option key={p.id} value={p.id}>{p.label}</option>))}
                                        </HTMLSelect> : <InputGroup 
                                        leftIcon="document" 
                                        placeholder="Description" 
                                        onFocus={() => setIsEditingDescription(true)} 
                                        onBlur={() => setIsEditingDescription(false)}    
                                        type="text" 
                                        value={pendingDescription} onChange={(e) => setPendingDescription(e.target.value)}
                                    /> )}
                                </div>
                                {( isEditingDescription ? "" : <InputGroup leftIcon={"numerical"} rightElement={<Button small={true} minimal={true} icon="time" intent="primary" onClick={() => {
                                    setPendingQuantity(event_duration);
                                }}/>} placeholder="Quantity" size={5} type="text" value={pendingQuantity} onChange={(e) => setPendingQuantity(e.target.value)}/> )}
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", gap: 10, width: "40%" }}>
                                {( isEditingDescription ? "" : <InputGroup leftIcon="dollar" intent={"primary"} size={4} rightElement={<Tag intent={"primary"}>Internal</Tag>} type="text" value={pendingRate} onChange={(e) => setPendingRate(e.target.value)}/> )}
                                {( isEditingDescription ? "" : <InputGroup leftIcon="dollar" intent={"success"} size={4} rightElement={<Tag intent={"success"}>Commercial</Tag>} type="text" value={pendingCommercialRate} onChange={(e) => setPendingCommercialRate(e.target.value)}/> )}    
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", gap: 10, width: "10%" }}>
                                <Button icon={(isAddingLine ? <Spinner size={16}/> : "add")} intent={"success"} onClick={appendLineItem} disabled={(!pendingIsValid || isAddingLine)}/>
                            </div>
                    </div>
                </div>
            </div>
        </Collapse>
    </div>
}
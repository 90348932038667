import React, { useState } from "react"
import { Classes, Icon } from "@blueprintjs/core";

const GroupButton = ({group, color, icon = "lab-test"}) => {

  const [isHovered, setIsHovered] = useState(false);

  const buttonStyle = { 
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "#BE0000",
    backgroundColor: "white",
    textAlign: "center",
    fontSize: "1rem",
    fontFamily: "Montserrat, sans-serif",
    cursor : "default", 
    width: "250px", 
    borderRadius: "3px", 
    border: `2px solid #BE0000`,
    gap: 10,
    fontWeight: 500,
    textTransform: "uppercase",
  }

  const hoveredStyle = {
    ... buttonStyle,
    color: "white",
    backgroundColor: color,
    textDecoration: "none",
    border: `2px solid ${color}`,
  }
  return (<a className={Classes.CALLOUT} style={(isHovered ? hoveredStyle : buttonStyle)} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} href={"#/group/"+ group.id}>
    <Icon icon={icon} color={(isHovered ? "white" : color)} size={32}/>
    {group.name}
  </a> );
}

export default GroupButton;

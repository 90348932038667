-
'use strict';

/*

    Webhook Module Event Bus

*/

import Eventer from "../Eventer";
import queryString from 'query-string';
import { get, post, del, put, patch, getErrorPayload } from "./SystemREST";
var WebhookData = Eventer.createBus("WHK");

WebhookData.getRecordList = function (opts) {

    let options = opts || {};
    let queryOptions = {};

    // Apply pagination if exists
    if(options._page && options._pageSize) {
        queryOptions._pageSize = options._pageSize;
        queryOptions._page = options._page;
    }

    // Apply query options if it exists
    if(options.where) { queryOptions = Object.assign(queryOptions, options.where) }

    let requestURL = `/webhooks`;
    if(queryString.stringify(queryOptions).length > 0) { requestURL = `${requestURL}?${queryString.stringify(queryOptions)}`}

    return get(requestURL).send().then(function(res) {
        var payload = res.body;
        WebhookData.notifyListeners("LIST_LOADED", { records: payload.records, count: payload.count });
        return { records: payload.records, count: payload.count }
    }, function (err) {
        WebhookData.notifyListeners("LIST_LOAD_ERROR", getErrorPayload(err));
        throw err;
    });

};

WebhookData.getRecord = function (id) {
    let requestURL = `/webhook/${id}`;
    return get(requestURL).send().then(function(res) {
        var payload = res.body;
        WebhookData.notifyListeners("RECORD_LOADED", payload.record);
        return payload.record;
    }, function (err) {
        WebhookData.notifyListeners("RECORD_LOAD_ERROR", getErrorPayload(err));
        throw err;
    });
};

WebhookData.createRecord = function (record) {
    let requestURL = `/webhook`;
    return post(requestURL).send(record).then(function(res) {
        var payload = res.body;
        WebhookData.notifyListeners("RECORD_CREATED", payload.record);
        return payload.record;
    }, function (err) {
        WebhookData.notifyListeners("RECORD_CREATE_ERROR", getErrorPayload(err));
        throw err;
    });
};

WebhookData.updateRecord = function (record) {
    let requestURL = `/webhook/${record.id}`;
    return put(requestURL).send({ record: record }).then(function(res) {
        var payload = res.body;
        WebhookData.notifyListeners("RECORD_UPDATED", payload.record);
        return payload.record;
    }, function (err) {
        WebhookData.notifyListeners("RECORD_UPDATE_ERROR", getErrorPayload(err));
        throw err;
    });
};

WebhookData.deleteRecord = function (record) {
    let requestURL = `/webhook/${record.id}`;
    return del(requestURL).send().then(function(res) {
        var payload = res.body;
        WebhookData.notifyListeners("RECORD_DELETED", payload.record);
        return payload.record;
    }, function (err) {
        WebhookData.notifyListeners("RECORD_DELETE_ERROR", getErrorPayload(err));
        throw err;
    });
};

WebhookData.triggerHook = function(trigger, data) {
    let requestUrl = `/webhooks/trigger/${trigger}`;
    return post(requestUrl).send(data).then((response) => {
        WebhookData.notifyListeners("WEBHOOK_TRIGGERED", { trigger: trigger, results: response?.body?.results })
        return { trigger: trigger, results: response?.body?.results };
    }, function (err) {
        WebhookData.notifyListeners("WEBHOOK_TRIGGER_ERROR", err);
        throw err;
    });
}

export default WebhookData;
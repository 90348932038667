
import React from 'react'
import AppData from 'data-modules/AppData'
import SiteDialogData from "data-modules/SiteDialogData"
import { Dialog, AnchorButton, Intent, InputGroup, ControlGroup, Button, H5, Callout, Classes, FormGroup } from "@blueprintjs/core"
import QueryString from "query-string"
import LoginStyle from "./LoginStyle"

class LoginPage extends React.Component {
  constructor () {
    super()

    this.state = {
      responders: [],
    }

    this.componentDidMount = this.componentDidMount.bind(this)
    this.componentWillUnmount = this.componentWillUnmount.bind(this)
    this.userDidLogin = this.userDidLogin.bind(this)
    this.loginErrorHandler = this.loginErrorHandler.bind(this)
    this.ajaxErrorHandler = this.ajaxErrorHandler.bind(this)
  }

  componentDidMount () {
    var responders = [];
    responders.push(AppData.registerListener("USER_LOGGED_IN", this.userDidLogin));
    responders.push(AppData.registerListener("LOGIN_ERROR", this.loginErrorHandler));
    this.setState({
      'responders' : responders
    }, () => {
      let urlQuery = QueryString.parse(this.props.location.search); 
      if(urlQuery.ticket && urlQuery.provider) {
        AppData.ticketLogin(urlQuery).then(() => { this.props.navigate("/"); })
      } else {
        window.location = "/auth/login";
      }
    });

  }
  componentWillUnmount () {
    this.state.responders.forEach(function (responder, index, sourceArray) {
      AppData.deregisterListener(responder);
    });
  }
  userDidLogin (action, authToken) {
    window.location.hash = "#/";
  }
  loginErrorHandler (err) {
    let reason = err?.debug || "Unknown";
    SiteDialogData.open("Login Error", reason);
    window.location = "/auth/login";
  }
  ajaxErrorHandler (err) {
    let reason = err?.debug || "Unknown";
    SiteDialogData.open("Login Error", reason);
    window.location = "/auth/login";
  }

  render () {
    var style = LoginStyle;
    return (
      <div>
        <div style={style.container}>
            <div style={style.login_box}>
              <AnchorButton href="/auth/login"> Login Page </AnchorButton>
            </div>
        </div>
      </div>
    );
  }
}

import withRouter from "lib/withRouter";
let Page = withRouter(LoginPage);
export default Page;

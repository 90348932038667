
'use strict';

import React, { useState, useEffect } from "react";
import DataManager from "data-modules/DataManager"
let DM = new DataManager();
import AppData from "data-modules/AppData";
import ServiceData from "data-modules/ServiceData";
import SiteDialogData from "data-modules/SiteDialogData";
import moment from "moment";
import MarkdownIt from "markdown-it";
let markdown = new MarkdownIt({
    html: true
});
import { AnchorButton, ButtonGroup, Icon, Button } from "@blueprintjs/core"

export default function ServiceButton({
  service,
  showInfo = true,
  showGroup = false,
  showTagManager = false,
  onOpenTagManager = () => {},
}) {

  let [isWritingMessage, setIsWritingMessage] = useState(false)
  let [isSending, setIsSending] = useState(false)
  let [isInfoDialogOpen, setIsInfoDialogOpen] = useState(false)
  let [startDate, setStartDate] = useState(moment().add(-1, "months"))
  let [endDate, setEndDate] = useState(moment())
  let [subject, setSubject] = useState("")
  let [message, setMessage] = useState("")
  let [isHovered, setIsHovered] = useState(false)

  const clickHandler = function () {
    var id = service.id;
    switch(service.type) {
      case 0: {
        window.location.hash = "#/service-calendar/" + id;
      } break;
      case "group-scheduled": {
        window.location.hash = "#/group-calendar/" + id;
      } break;
      case 1 : {
        window.location = service.link;
      } break;
      case 2: {
        window.location = service.link;
      } break;
      case 5 : {
        window.location = "#/GSIPT/"+service.group_id+"/wizard/"+id;
      } break;
      case 6 : {
        window.location = "#/BBSP/"+service.group_id+"/wizard/"+id;
      } break;
      case 7: {
        window.location = "#/MSSP/" + service.group_id + "/wizard/" + id;
      } break;
      case 9: {
        window.location = `#/FASTA/${service.group_id}/wizard/${id}`;
      } break;
      case 10: {
        window.location = `#/walkup/${service.id}/wizard`;
      } break;
      default : {
        window.location.hash = "#/service/"+id;
      } break;
    }
  }
  
  let self = this;
  let name = service.name;
  let location = service.location || "";
  let color = service.color || "#999999"
  let id = service.id;
  let group_id = service.group_id;
  var serviceNotes = markdown.render(service.service_notes || "No Information");

  let isManager = (AppData.authorize(20, group_id) && service.type !== "group-scheduled");
  let buttonStyle = { 
    backgroundColor: (isHovered ? color : "white"), 
    border: `3px solid ${(isHovered ? color : "#BE0000")}`,
    display: "flex",  
    justifyContent : "space-between", 
    padding: "5px 10px", 
    width: "100%",
    alignItems: "center",
    margin: "5px 0",
    borderRadius: "5px",
    gap: 20
  };
  
  return ( <div style={buttonStyle} className={"cores-service-manage-button"} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} onClick={clickHandler}>
    <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
      <Icon icon={([0, "group-scheduled"].includes(service.type)? "timeline-events" : "dollar")} size={24} style={{ color: (isHovered ? "white" : color) }}/>
      <span style={{ fontWeight: "bold", cursor: "default", color: (isHovered ? "black" : "#333333") }}> {(showGroup === true ? `${service.group.name} - ` : "")} {name} {location}</span>
    </div>
    <ButtonGroup>
      {( showInfo && <Button minimal={true} icon="info-sign" intent="primary" onClick={(e) => { SiteDialogData.open(`${name} Information`, <div dangerouslySetInnerHTML={{ __html: serviceNotes }}/>); e.stopPropagation(); }}/> )}
      {( isManager && <AnchorButton minimal={true} intent="success" href={"#/manage/group/"+group_id+"/service/"+id} icon="cog" onClick={(e) => { e.stopPropagation(); }}></AnchorButton>)}
      {( ((showTagManager || true) && isManager) == true ? <Button icon="user" minimal={true} intent="warning" onClick={(e) => { onOpenTagManager(service); e.stopPropagation(); }}/> : <span/> )}
    </ButtonGroup>
  </div> );


}

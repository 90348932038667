
'use strict';

import React from "react";
import CustomFormData from "data-modules/CustomFormData"
import moment from "moment";
import {
    Classes,
    Button,
    InputGroup,
    RadioGroup,
    Radio,
    HTMLTable,
    Intent,
    FormGroup
} from "@blueprintjs/core";
import { DateRangeInput } from "@blueprintjs/datetime"

class ServiceEntryList extends React.Component {
    constructor(props) {
        super(props);
        let now = moment();
        this.state = {
            forms: [],
            status_filter: "received",
            search_value: "",
            search_range: [moment(now).subtract(1, "month"), now]
        };

        this.componentDidMount = this.componentDidMount.bind(this)
        this.updateStatusFilter = this.updateStatusFilter.bind(this)
        this.updateSearchValue = this.updateSearchValue.bind(this)
        this.updateSearchRange = this.updateSearchRange.bind(this)
    }

    componentDidMount() {
        let self = this;
        let params = this.props.params;
        let now = moment();
        let range = [moment(now).subtract(1, "month"), now];
        CustomFormData.getAvailableForms(params.service_id, "received").then((forms) => {
            self.setState({ forms: forms, search_range: range })
        });
    }

    updateStatusFilter(event) {
        let self = this;
        let params = this.props.params;
        let new_value = event.target.value;
        let action = ((nv) => {
            switch(nv) {
                case "active" :
                case "completed" :
                case "archived": { return CustomFormData.getAvailableForms(params.service_id, new_value, self.state.search_range); } break;
                default: { return CustomFormData.getAvailableForms(params.service_id, new_value); } break;
            }
        })(new_value)
        action.then((forms) => {
            self.setState({ forms: forms, status_filter: new_value, search_range: self.state.search_range })
        });
    }

    updateSearchValue(event) {
        let self = this;
        self.setState({ search_value: event.target.value });
    }

    updateSearchRange(new_range) {
        let self = this;
        let range = ((nr) => {
            let range_start = self.state.search_range[0];
            let range_end = self.state.search_range[1];
            if(nr[0] !== null) { range_start = nr[0] }
            if (nr[1] !== null) { range_end = nr[1] }
            if(moment(range_start).isAfter(moment(range_end))) {
                return [moment(range_end), moment(range_start)];
            } else {
                return [moment(range_start), moment(range_end)];
            }

        })(new_range);
        self.setState({ search_range: range }, () => {
            self.updateStatusFilter({ target: { value: self.state.status_filter }});
        });
    }

    openForm(form) {
        window.open(`#/form/detail/${form.id}`, "_blank");
    }


    exportFormsToCSV() {
        let self = this;
        let params = this.props.params;
        let searchOption = {
            service_id: params.service_id,
            status: self.state.status_filter,
            search_value: self.state.search_value
        }
        if ( self.state.status_filter && ["active", "completed", "archived"].includes(self.state.status_filter) ) {
            searchOption.range = self.state.search_range;
        }
        CustomFormData.exportForms(searchOption);
    }

    render() {
        let self = this;
        let rows = self.state.forms.filter((form) => {
            if (self.state.search_value.length < 1) { return true; }
            let lowerSearch = self.state.search_value.toLowerCase();
            if (form.service.name.toLowerCase().includes(lowerSearch)) { return true; }
            if (form.user.full_name.toLowerCase().includes(lowerSearch)) { return true; }
            if (form.custom_form_config.config_data.title.toLowerCase().includes(lowerSearch)) { return true; }
            if (form.processing_status.toLowerCase().includes(lowerSearch)) { return true; }
            if (moment(form.createdAt).format("YYYY-MM-DD HH:mm").toLowerCase().includes(lowerSearch)) {return true; }
            if (JSON.stringify(form.form_data).toLowerCase().includes(lowerSearch)) { return true; }
            return false;
        }).map((form, index) => {
            return (<tr key={`row_${index}`} onClick={self.openForm.bind(null, form)}>
                <td> {form.id} </td>
                <td> {form.service.name} </td>
                <td> {form.user.full_name} </td>
                <td> {form.custom_form_config.config_data.title} </td>
                <td> {form.processing_status} </td>
                <td> {moment(form.createdAt).format("YYYY-MM-DD HH:mm")} </td>
            </tr>);
        })

        let rangeSelectElement = ((sf) => {
            switch (sf) {
                case "active":
                case "archived" :
                case "completed" : {
                    let range = [self.state.search_range[0].toDate(), self.state.search_range[1].toDate()]
                    return <div>
                        <FormGroup label="Search Range">
                            <DateRangeInput 
                                minDate={moment().add(-5, "years").toDate()} 
                                maxDate={moment().add(1, "years").toDate()} 
                                formatDate={(date) => moment(date).format("YYYY-MM-DD")}
                                parseDate={(date) => moment(date, "YYYY-MM-DD").toDate()} 
                                value={range} 
                                onChange={self.updateSearchRange}/>
                        </FormGroup>
                    </div>
                    
                 }
                default: { return <div></div>}
            }
        })(self.state.status_filter)

        let exportButtonElement = rows.length ? (
            <Button disabled={!rows.length}
                    type="submit" small={true}
                    intent={Intent.PRIMARY}
                    style={{ margin: "15px 30px" }}
                    onClick={this.exportFormsToCSV.bind(this)}>
                Export to CSV
            </Button>
        ) : <div></div>;

        return (<div style={{ width: "100%", paddingLeft: "10%", paddingRight: "10%", paddingTop: 20 }}>
            <h2 className={Classes.HEADING}> Service Submitted Custom Forms </h2>
            <div style={{ display: "grid", gridTemplateColumns: "80% 20%"}}>
                <RadioGroup inline={true} label="Filter by Processing Status" selectedValue={self.state.status_filter} onChange={self.updateStatusFilter}>
                    <Radio label="All Active" value="active" />
                    <Radio label="Received" value="received" />
                    <Radio label="In Process" value="in_process" />
                    <Radio label="Completed" value="completed" />
                    <Radio label="Archived" value="archived" />
                </RadioGroup>
                {exportButtonElement}
            </div>
            {rangeSelectElement}
            <InputGroup style={{ width: "100%" }} placeholder="Filter for value..." type="text" onChange={self.updateSearchValue} value={self.state.search_value} leftIcon="filter" />
            <HTMLTable striped={true} interactive={true} style={{width: "100%"}}>
                <thead>
                    <tr>
                        <th> ID </th>
                        <th> Service </th>
                        <th> Submitter </th>
                        <th> Form </th>
                        <th> Status </th>
                        <th> Received </th>
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </HTMLTable>
        </div>)
    }
}

import withRouter from "lib/withRouter";
let Page = withRouter(ServiceEntryList)
export default Page;
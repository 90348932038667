
'use strict';

import React from 'react';
import RecordRow from './RecordRow';
import { HTMLTable } from "@blueprintjs/core";

class RecordList extends React.Component {
  constructor(props) {
    super(props);

    this.onRowClick = this.onRowClick.bind(this);

  }
  onRowClick(event, rowId, record) {
    if(typeof this.props.onRowClick != "undefined") {
      this.props.onRowClick(event, rowId, record);
    }
  }
  render() {
    let style = {
      container : {
        width: "100%"
      }
    };

    let listData = [];
    if(this.props.dataSource !== "undefined") {
      listData = this.props.dataSource;
    }
    
    let self = this;
    let recordRows = listData.map(function (rowData, index) {
      if(typeof self.props.rowSubtitle == "undefined" || self.props.rowSubtitle == "") {
          return <RecordRow key={index} record={rowData} rowId={index} title={self.props.rowTitle} rowStyle={self.props.rowStyle} clickHandler={self.onRowClick}/>
      } else {
        return <RecordRow key={index} record={rowData} rowId={index} title={self.props.rowTitle} subtitle={self.props.rowSubtitle} rowStyle={self.props.rowStyle} clickHandler={self.onRowClick}/>
      }

    });

    return (
      <div style={style.container}>
        <HTMLTable striped={true} interactive={true} style={{ width : "100%" }}>
          <tbody>
            {recordRows}
          </tbody>
        </HTMLTable>
      </div>
    );
  }
}

export default RecordList;

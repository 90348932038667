
	'use strict';

	/*

		Master Application Data Store. Controls Authentication and Auth Storage Across the Application
		
	*/

	var Superagent = require('superagent');
	import Eventer from "../Eventer";
	var AppData = Eventer.createBus("APP");
	import { permission_types } from "./Enum"

	var _systemData = {
		authToken : "",
		username : "",
		full_name : null,
		userType : 0,
		userID : null,
		groupPermissions : [],
		manageMode : false, 
		systemTimezone: "UTC",
		defaultHolidayRegion: "US:UT",
		site_title: "Utah Cores Management System",
		ldap_auth_regex: "^u[0-9]{7}",
		featureConfig: {}
	};

	/*

		Semi-generic setter/getter functions appended to Eventer Data Store. Value Type Agnostic. Will set previousely undefined keys
		on the object.

	*/

	AppData.set = function (key, value) {
		_systemData[key] = value;
		this.notifyListeners("VALUE_SET", _systemData);
	}

	/*

		Getter will return false if the value at that key does not exist. (No Object Dumping)

	*/

	AppData.get = function (key) {
		if(typeof key == 'undefined') {
			return false;
		} else {
			return _systemData[key];
		}
	}

	AppData.getFeatureConfig = function (feature_key) {
		let featureConfig = _systemData.featureConfig[feature_key];
		if(featureConfig === undefined) { return Promise.resolve({ enabled: false, config: null }) }
		return Promise.resolve({ enabled: featureConfig['enabled'], config: featureConfig });
	}

	/*

		Uses superagent.js to login and retrieve a valid auth token from the server.

	*/

	AppData.loginHandler = function(res) {
		switch(res.statusCode) {
			case 200: {
				var payload = res?.body || {};
				if(payload?.status === true) {
					_systemData.authToken = payload.token;
					_systemData.userType = payload.type;
					_systemData.userID = payload.id;
					_systemData.full_name = payload.full_name;
					_systemData.groupPermissions = payload.group_permissions;
					_systemData.timezone = payload.timezone;
					_systemData.defaultHolidayRegion = payload.default_holiday_region;
					localStorage.setItem("resource_authToken", payload.token);
					localStorage.setItem("resource_authType", payload.type);
					localStorage.setItem("resource_userID", payload.id);
					localStorage.setItem("resource_groupPermissions", JSON.stringify(payload.group_permissions));
					AppData.notifyListeners("USER_LOGGED_IN", _systemData.authToken);
					return Promise.resolve();
				} else {
					let payload = res.body;
					AppData.notifyListeners("LOGIN_ERROR", payload);
					return Promise.reject(payload);
				}
			}
			case 401: {
				let payload = res.body;
				AppData.notifyListeners("LOGIN_ERROR", payload);
				return Promise.reject(payload);
			}
			default: {
				AppData.notifyListeners("REQUEST_SERVER_ERROR", res?.body);
				return Promise.reject(res?.body);
			}
		}
	}

	AppData.ticketLogin = function (parameters) {
		return Superagent.get(`/auth/jwt?ticket=${parameters.ticket}`).ok(res => res.status < 500).then(AppData.loginHandler);		
	};

	AppData.authorize = function (level, group_id) {
		if(!AppData.isLoggedIn()) { return false; }
		if(group_id) { // Checking Specific Group
			if(_systemData.userType >= permission_types.ADMINISTRATOR )  { return true; } // Admin case
			return _systemData.groupPermissions.reduce(function (prevValue, permission) { // Manager Case
				if ( permission.type >= level && permission.group_id == group_id ) { return true; }
				return prevValue;
			}, false);
		} else { // General Access case
			switch(level) {
				case permission_types.MANAGER : {
					if(_systemData.userType >= permission_types.ADMINISTRATOR )  { return true; } // Admin case
					return _systemData.groupPermissions.reduce(function (prevValue, permission) {
						if ( permission.type >= permission_types.MANAGER ) { return true; }
						return prevValue;
					}, false);
				} break;
				case permission_types.AUDITOR : {
					if(_systemData.userType >= permission_types.ADMINISTRATOR )  { return true; } // Admin case
					if(_systemData.userType >= permission_types.AUDITOR ) { return true; } // Admin case
					return _systemData.groupPermissions.reduce(function (prevValue, permission) {
						if ( permission.type >= permission_types.AUDITOR ) { return true; }
						return prevValue;
					}, false);
				} break;
				default : {
					if(_systemData.userType < level) { return false; }
					return true;
				} break;
			}
		}
	}

	AppData.requestReset = function (username) {
		return new Promise((resolve, reject) => {
			var url = "/auth/reset/send";
			Superagent.post(url).send({ username: username }).end(function (err, res) {
				if (err) { reject(err); console.log(err, res); return; }
				if (res.ok) {
					var payload = res.body;
					if (payload.status) {
						resolve(true);
					} else {
						reject(payload.message);
					}
				} else {
					AppData.notifyListeners("REQUEST_SERVER_ERROR", res?.body);
					reject(res?.body)
				}
			});
		})
	}

	AppData.requestResetByID = function (id) {
		return new Promise((resolve, reject) => {
			var url = "/auth/reset/send";
			Superagent.post(url).send({ user_id: id }).end(function (err, res) {
				if (err) { reject(err); console.log(err, res); return; }
				if (res.ok) {
					var payload = res.body;
					if (payload.status) {
						resolve(true);
					} else {
						reject(payload.message);
					}
				} else {
					AppData.notifyListeners("REQUEST_SERVER_ERROR", res?.body);
					reject(res?.body)
				}
			});
		})
	}

	AppData.isLoggedIn = function () {
		if(localStorage.getItem("resource_authToken")) {
			AppData.set("authToken", localStorage.getItem("resource_authToken"));
			AppData.set("userType", localStorage.getItem("resource_authType"));
			AppData.set("userID", localStorage.getItem("resource_userID"));
			AppData.set("groupPermissions", JSON.parse(localStorage.getItem("resource_groupPermissions")));
			return localStorage.getItem("resource_authToken").length > 0;
		} 
		return false;
	}

	AppData.fetchAppConfig = function () {
		return Superagent.get("/config/timezone,default_holiday_region,site_title,ldap_auth_regex,fullcalendar_key,custom_holiday_list").then((res) => {
			let payload = res.body;
			if(typeof payload.timezone === "string") { _systemData.systemTimezone = payload.timezone; }
			if(typeof payload.default_holiday_region === "string") { _systemData.defaultHolidayRegion = payload.default_holiday_region; }
			if(typeof payload.site_title === "string") { _systemData.site_title = payload.site_title; }
			if(typeof payload.ldap_auth_regex === "string") { _systemData.ldap_auth_regex = payload.ldap_auth_regex; }
			if(typeof payload.fullcalendar_key === "string") { _systemData.fullcalendar_key = payload.fullcalendar_key; }
			if(typeof payload.custom_holiday_list === "string") { _systemData.custom_holiday_list = payload.custom_holiday_list; }
			return;
		}).catch((err) => {
			console.log(err); 
			throw err;
		})
	}
	AppData.fetchFeaturesConfig = function () {
		return Superagent.get("/system/features").then(res => {
			let payload = res.body;
			_systemData.featureConfig = payload;
		}).catch((err) => {
			console.log(err); 
		})
	}

	AppData.getUserConfig = function () {
		return Superagent.get("/auth/me").then(res => {
			let payload = res.body;
			_systemData.authToken = payload.token;
			_systemData.userType = payload.type;
			_systemData.userID = payload.id;
			_systemData.full_name = payload.full_name;
			_systemData.groupPermissions = payload.group_permissions;
			_systemData.timezone = payload.timezone;
			_systemData.defaultHolidayRegion = payload.default_holiday_region;
			localStorage.setItem("resource_authToken", payload.token);
			localStorage.setItem("resource_authType", payload.type);
			localStorage.setItem("resource_userID", payload.id);
			localStorage.setItem("resource_groupPermissions", JSON.stringify(payload.group_permissions));
			return true;
		}).catch((err) => {
			console.log(err); 
			return false;
		})
	}

	AppData.timezone = function () { return _systemData.systemTimezone || "UTC"; }
	AppData.defaultHolidayRegion = function () { return _systemData.defaultHolidayRegion || "US:UT"; }
	AppData.site_title = function () { return _systemData.site_title || "Utah Cores Management System"; }

	AppData.logout = function () {
		_systemData.authToken = "";
		_systemData.userType = -1;
		_systemData.userID = null;
		_systemData.full_name = null;
		_systemData.groupPermissions = [];
		localStorage.removeItem("resource_authToken");
		localStorage.removeItem("resource_authType");
		localStorage.removeItem("resource_userID");
		localStorage.removeItem("resource_groupPermissions");
		AppData.notifyListeners("USER_LOGGED_OUT", true);
		fetch("/auth/logout")
	}

	export default AppData;

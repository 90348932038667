import WebhookData from "data/WebhookData"
import { FormGroup, ControlGroup, Button } from "@blueprintjs/core";
export default ({ service, postLock, postUnlock, postQuery }) => {

    let service_prefix = `service:${service.id}`
    let triggerLock = () => { WebhookData.triggerHook(`${service_prefix}:interlock:lock`, {}).then(() => {
        if(typeof postLock == "function") { postLock(); }
    }).catch((err) => { console.log(err); }); }
    let triggerUnlock = () => { WebhookData.triggerHook(`${service_prefix}:interlock:unlock`, {}).then(() => {
        if(typeof postUnlock == "function") { postUnlock(); }
    }).catch((err) => { }); }
    let queryStatus = () => { WebhookData.triggerHook(`${service_prefix}:interlock:status`, {}).then((result) => { 
        if(result.results.length == 0) { alert("No status query configured"); return; }
        if(result.results.length !== 1) { alert("Err - Multiple Responses Recieved"); return; }
        let status = "[Unknown - Improperly formatted response.]";
        if(typeof result.results[0]['data'] == "object") { 
            if(result.results[0]['data']['status'] == "unlocked") { status = "Unlocked" }
            if(result.results[0]['data']['status'] == "locked") { status = "Locked" }
         } else {
            if(result.results[0]['data'].includes("locked")) { status = "Locked"}
            if(result.results[0]['data'].includes("unlocked")) { status = "Unlocked"}
         }
        
        alert(`${service.service.name} is ${status}`);
        if(typeof postQuery == "function") { postQuery(); }
    }); }

    return <FormGroup label="Interlock Controls">
        <ControlGroup>
            <Button icon="unlock" intent="success" onClick={triggerUnlock}>Unlock</Button>
            <Button icon="help" intent="primary" onClick={queryStatus}>Query Status</Button>
            <Button icon="lock" intent="danger" onClick={triggerLock}>Lock</Button>
        </ControlGroup>
    </FormGroup>;
}
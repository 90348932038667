/*

    Custom Form Data Event Bus and Data Interface

*/

let moment = require('moment');
import URI from "urijs";
import AppData from "data-modules/AppData";
import Eventer from "../Eventer";
import { get, post, put, patch, del, getErrorPayload  } from "data-modules/SystemREST";
let CustomFormData = Eventer.createBus("CFD");

let responseHandler = (bus, res, actions) => {
    if (res.ok) {
        let payload = res.body;
        if (payload.status) {
            bus.notifyListeners(actions.success.key, payload.forms);
            actions.success.action(payload[actions.success.payload_key])
        } else {
            bus.notifyListeners(actions.error.key, payload.debug);
            actions.error.action(payload.debug)
        }
    } else {
        bus.notifyListeners(action.error.key, payload.debug);
        actions.error.action(payload.debug)
    }
}

let errorHandler = (bus, err, action) => {
    bus.notifyListeners("AJAX_ERROR", err)
    action(err)
}

CustomFormData.getAvailableForms = function (service_id, status, range) {
    let requestURL = URI(`/forms/${service_id}`);
    if (status) { requestURL.addSearch("status", status)}
    if (range) {
        let start = moment(range[0]).format("YYYY-MM-DD")
        let end = moment(range[1]).format("YYYY-MM-DD")
        requestURL.addSearch("range", `${start}:${end}`)
    }
    return new Promise((resolve, reject) => {
        get(requestURL.toString()).send().then(function (res) {
            responseHandler(CustomFormData, res, {
                "success": { key: "FORM_LIST_LOADED", action: resolve, payload_key: "forms" },
                "error": { key: "FORM_LIST_LOAD_ERROR", action: reject}
            })
        }, function (err) {
            errorHandler(CustomFormData, getErrorPayload(err), reject)
        });
    })
};

CustomFormData.getMyForms = function (status, range) {
    let requestURL = URI(`/forms/mine`);
    if (status) { requestURL.addSearch("status", status) }
    if (range) {
        let start = moment(range[0]).format("YYYY-MM-DD")
        let end = moment(range[1]).format("YYYY-MM-DD")
        requestURL.addSearch("range", `${start}:${end}`)
    }
    return new Promise((resolve, reject) => {
        get(requestURL.toString()).send().then(function (res) {
            responseHandler(CustomFormData, res, {
                "success": { key: "FORM_LIST_LOADED", action: resolve, payload_key: "forms" },
                "error": { key: "FORM_LIST_LOAD_ERROR", action: reject }
            })
        }, function (err) {
            errorHandler(CustomFormData, getErrorPayload(err), reject)
        });
    })
};


CustomFormData.exportForms = function ({service_id, status, range, search_value}) {
    let requestURL = URI('/forms/export');
    if (service_id) {requestURL.addSearch("service_id", service_id)}
    if (status) { requestURL.addSearch("status", status)}
    if (range) {
        let start = moment(range[0]).format("YYYY-MM-DD")
        let end = moment(range[1]).format("YYYY-MM-DD")
        requestURL.addSearch("range", `${start}:${end}`)
    }
    if (search_value) { requestURL.addSearch("search_value", search_value)}

    return new Promise((resolve, reject) => {
        post(requestURL.toString())
            .send().then(function (res) {
                if (res.statusCode == 200) {
                    downloadBlob(res, 'custom_forms.csv')
                } else if (res.statusCode == 204) {
                    console.log('No Data to Download')
                }
            }, function (err) {
                errorHandler(CustomFormData, getErrorPayload(err), reject)
            });
    })
};

let downloadBlob = (res, filename) => {
    let blob = new Blob([res.text], {type: "text/csv"});
    if (window.navigator?.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
        let link = document.createElement('a');
        let url = window.URL.createObjectURL(blob);
        link.href = url;
        link.download = filename;
        link.click();
    }
}

CustomFormData.getServiceFormConfig = function (service_id) {
    let requestUrl = `/form/config/${parseInt(service_id)}`;
    return new Promise((resolve, reject) => {
        get(requestUrl).send().then(function (res) {
            responseHandler(CustomFormData, res, {
                "success": { key: "CONFIG_LOADED", action: resolve, payload_key: "config" },
                "error": { key: "CONFIG_LOAD_ERROR", action: reject }
            })
        }, function (err) {
            errorHandler(CustomFormData, getErrorPayload(err), reject)
        });
    })
}

CustomFormData.saveForm = function (config) {
    switch (config.id) {
        case "new" : {
            return new Promise((resolve, reject) => {
                let requestURL = `/form/config`
                post(requestURL).send(config).then(function (res) {
                    responseHandler(CustomFormData, res, {
                        "success": { key: "CONFIG_CREATED", action: resolve, payload_key: "config" },
                        "error": { key: "CONFIG_CREATE_ERROR", action: reject }
                    })
                })
            })
        } break;
        default: {
            return new Promise((resolve, reject) => {
                let requestURL = `/form/config/${config.id}`
                put(requestURL).send(config).then(function (res) {
                    responseHandler(CustomFormData, res, {
                        "success": { key: "CONFIG_UPDATED", action: resolve, payload_key: "config" },
                        "error": { key: "CONFIG_UPDATE_ERROR", action: reject }
                    })
                })
            })
        } break;
    }
}

CustomFormData.submitData = function (config, data) {
    return new Promise((resolve, reject) => {
        let requestURL = `/form/${config.id}`
        post(requestURL).send(data).then(function (res) {
            responseHandler(CustomFormData, res, {
                "success": { key: "FORM_CREATED", action: resolve, payload_key: "form" },
                "error": { key: "FORM_CREATE_ERROR", action: reject }
            })
        })
    })
}

CustomFormData.saveData = function (data) {
    return new Promise((resolve, reject) => {
        let requestURL = `/form/${data.id}`
        put(requestURL).send(data).then(function (res) {
            responseHandler(CustomFormData, res, {
                "success": { key: "FORM_UPDATED", action: resolve, payload_key: "form" },
                "error": { key: "FORM_UPDATE_ERROR", action: reject }
            })
        })
    })
}

CustomFormData.getForm = function (form_id) {
    return new Promise((resolve, reject) => {
        let requestURL = `/form/${form_id}`
        get(requestURL).then(function (res) {
            responseHandler(CustomFormData, res, {
                "success": { key: "FORM_LOADED", action: resolve, payload_key: "form" },
                "error": { key: "FORM_LOAD_ERROR", action: reject }
            })
        })
    })
}

CustomFormData.setProcessingStatus = function (form_id, status) {
    return new Promise((resolve, reject) => {
        let requestURL = `/form/${form_id}/status`
        put(requestURL).send({ processing_status: status }).then(function (res) {
            responseHandler(CustomFormData, res, {
                "success": { key: "FORM_UPDATED", action: resolve, payload_key: "processing_status" },
                "error": { key: "FORM_UPDATE_ERROR", action: reject }
            })
        })
    })
}

CustomFormData.resendSignatureRequest = function (form, field_key) {
    return new Promise((resolve, reject) => {
        let requestURL = `/form/signature_resend`
        post(requestURL).send({
            field_key: field_key,
            form_id: form.id
        }).then((res) => {
            responseHandler(CustomFormData, res, {
                "success": { key: "FORM_SIGNATURE_REQUEST_SENT", action: resolve, payload_key: "message" },
                "error": { key: "FORM_SIGNATURE_REQUEST_ERROR", action: reject }
            })
        })
    })
}


export default CustomFormData;
export default {
  container : {
    width: "100%"
  },
  login_box : {
    width: 250,
    marginTop: 50,
    marginLeft : "auto",
    marginRight : "auto"
  }
};

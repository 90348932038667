import React from 'react';
import { Button, HTMLSelect, ControlGroup, Tag } from "@blueprintjs/core";
import ScheduledMessageData from "data-modules/ScheduledMessageData"

export default class RequestedEventUserNotifications extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            offsetDuration: 15
        }

        this.createNotification = this.createNotification.bind(this);    
        this.deleteNotification = this.deleteNotification.bind(this);
        this.setOffsetDuration = this.setOffsetDuration.bind(this)

    }

    setOffsetDuration(evt) {
        this.setState({ offsetDuration: evt.target.value });
    }

    createNotification() {
        this.props.onCreate(this.state.offsetDuration);
    }

    deleteNotification(index) {
        this.props.onRemove(index);
    }

    render() {
        let pendingNotificationElements = this.props.requestedNotifications.map((row, index) => {
            let offset = row.split("::")[1]; 
            let offsetString = `${offset/1440}d before`;
            if(offset < 1440) { offsetString = `${offset/60}h before`; }
            if(offset < 60) { offsetString = `${offset}m before`; }
            return <Tag key={`notification_${index}`} round={true} style={{ margin: 4 }} rightIcon="delete" onClick={this.deleteNotification.bind(null, index)}>{offsetString}</Tag>
         });
            
        return <div> 
            <div> Scheduled Notifications </div>
            <div style={{ marginTop: 5 }}>{pendingNotificationElements}</div>
            <div style={{ marginTop: 5, marginBottom: 5 }}>
                <ControlGroup>
                    <HTMLSelect value={this.state.offsetDuration} onChange={this.setOffsetDuration}>
                        <option value="15"> 15m </option>
                        <option value="30"> 30m </option>
                        <option value="60"> 1h </option>
                        <option value="120"> 2h </option>
                        <option value="1440"> 1d </option>
                    </HTMLSelect>
                    <Button onClick={this.createNotification} icon="add" text="Add Notification"/>
                </ControlGroup>
            </div>
        </div>
    }
}
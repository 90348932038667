import { useState, useReducer, useEffect, useRef } from "react"
import { HTMLTable, ProgressBar, Spinner, Icon, Collapse, Button, InputGroup, ControlGroup, Tag } from "@blueprintjs/core"
import LineItemData from "data-modules/LineItemData"

export default function EditableOrderLine({ id, description, quantity, rate, commercial_rate, onSaved = () => {}, onDeleted = () => {} }) {

    let [pendingDescription, setPendingDescription] = useState(description)
    let [pendingQuantity, setPendingQuantity] = useState(quantity)
    let [pendingRate, setPendingRate] = useState(rate)
    let [pendingCommercialRate, setPendingCommercialRate] = useState(commercial_rate)
    let [isDirty, setIsDirty] = useState(false)
    let [isUpdating, setIsUpdating] = useState(false)
    let [isEditingDescription, setIsEditingDescription] = useState(false)
    
    let descriptionFieldRef = useRef(null)

    useEffect(() => { setPendingDescription(description)}, [description])
    useEffect(() => { setPendingQuantity(quantity)}, [quantity])
    useEffect(() => { setPendingRate(rate)}, [rate])
    useEffect(() => { setPendingCommercialRate(commercial_rate)}, [commercial_rate])

    let updateLine = async () => {
        setIsUpdating(true)
        let result = await LineItemData.updateRecord({ 
            id: id,
            description: pendingDescription,
            quantity: pendingQuantity,
            rate: pendingRate,
            commercial_rate: pendingCommercialRate
        })
        setIsUpdating(false)
        onSaved()
    }

    let voidLine = async () => {
        if(confirm("Are you sure you want to void this line?")) {
            setIsUpdating(true)
            let result = await LineItemData.voidLine(id)
            setIsUpdating(false)
            onSaved()
        }
    }

    let isValid = true;

    return <>
        <div style={{ display: "flex", flexDirection: "row", gap: 10, borderTop: "1px solid gray", borderBottom: "1px solid gray", padding: "10px 0px" }}>
            <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                <InputGroup 
                    leftIcon="document" 
                    ref={descriptionFieldRef} 
                    size={(isEditingDescription ? 43 : 16)} 
                    onFocus={() => setIsEditingDescription(true)} 
                    onBlur={() => setIsEditingDescription(false)}
                    type="text" value={pendingDescription} 
                    onChange={(e) => { setPendingDescription(e.target.value); setIsDirty(true); }}
                />
                {(isEditingDescription ? "" : <InputGroup leftIcon="numerical" size={4} type="text" value={pendingQuantity} onChange={(e) => { setPendingQuantity(e.target.value); setIsDirty(true); }}/> )}
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                {(isEditingDescription ? "" : <InputGroup leftIcon="dollar" intent={"primary"} size={4} rightElement={<Tag intent={"primary"}>Internal</Tag>} type="text" value={pendingRate} onChange={(e) => { setPendingRate(e.target.value); setIsDirty(true); }}/> )}
                {(isEditingDescription ? "" : <InputGroup leftIcon="dollar" intent={"success"} size={4} rightElement={<Tag intent={"success"}>Commercial</Tag>} type="text" value={pendingCommercialRate} onChange={(e) => { setPendingCommercialRate(e.target.value); setIsDirty(true); }}/> )}
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                <Button icon={(isUpdating ? <Spinner size={16}/> : "floppy-disk")} intent={"success"} disabled={!(isDirty && isValid && !isUpdating)} onClick={updateLine}/>
                <Button icon="delete" intent={"danger"} onClick={voidLine} disabled={isUpdating}/>
            </div>
        </div>
    </>
}
import React from 'react';
import { Button, HTMLSelect, ControlGroup, Tag } from "@blueprintjs/core";
import ScheduledMessageData from "data-modules/ScheduledMessageData"

export default class EventUserNotifications extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pendingNotifications: [],
            offsetDuration: 15
        }

        this.componentDidMount = this.componentDidMount.bind(this);
        this.getPendingEventNotifications = this.getPendingEventNotifications.bind(this);
        this.createNotification = this.createNotification.bind(this);    
        this.deleteNotification = this.deleteNotification.bind(this);
        this.setOffsetDuration = this.setOffsetDuration.bind(this)

        this.getPendingEventNotifications(props.event);
    }

    componentDidMount() {
        this.getPendingEventNotifications(this.props.event);
    }
    
    setOffsetDuration(evt) {
        this.setState({ offsetDuration: evt.target.value });
    }

    getPendingEventNotifications() {
        ScheduledMessageData.getPendingEventNotifications(this.props.event).then(({ rows, count }) => {
            this.setState({ pendingNotifications: rows });
        }).catch((err) => { console.log(err) });
    }

    createNotification() {
        ScheduledMessageData.createEventNotification(this.props.event, this.state.offsetDuration).then(() => {  
            this.getPendingEventNotifications(); 
        }).catch((err) => { console.log(err) });
    }

    deleteNotification(id) { 
        ScheduledMessageData.deleteNotification(id).then(() => {  
            this.getPendingEventNotifications(); 
        }).catch((err) => { console.log(err) });
    }

    render() {
        let pendingNotificationElements = this.state.pendingNotifications.map((row, index) => {
            let offset = row.tags.split(",").reduce((result, tag) => { 
                if(tag.includes("offset::")) {
                    const offset = parseInt(tag.split("::")[1], 10);
                    if(offset < 60) { return `${offset}m before`; }
                    if(offset < 1440) { return `${offset/60}h before`; }
                    return `${offset/1440}d before`;
                }
                return result;  
            }, "Unknown");
            return <Tag key={`notification_${index}`} round={true} style={{ margin: 4 }} rightIcon="delete" onClick={this.deleteNotification.bind(null, row.id)}>{offset}</Tag>
         });
            
        return <div> 
            <div> Scheduled Notifications </div>
            <div style={{ marginTop: 5 }}>{pendingNotificationElements}</div>
            <div style={{ marginTop: 5, marginBottom: 5 }}>
                <ControlGroup>
                    <HTMLSelect value={this.state.offsetDuration} onChange={this.setOffsetDuration}>
                        <option value="15"> 15m </option>
                        <option value="30"> 30m </option>
                        <option value="60"> 1h </option>
                        <option value="120"> 2h </option>
                        <option value="1440"> 1d </option>
                    </HTMLSelect>
                    <Button onClick={this.createNotification} icon="add" text="Add Notification"/>
                </ControlGroup>
            </div>
        </div>
    }
}
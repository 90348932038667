
'use strict';
import React from 'react'
import DataManager from "data-modules/DataManager"
import MetricsData from "data-modules/MetricsData"
let DM = new DataManager();
import UserData from "data-modules/UserData";
import ServiceData from "data-modules/ServiceData";
import SearchPicker from "generic-components/SearchPicker";
import { FormGroup, Button, HTMLSelect, InputGroup, ControlGroup, HTMLTable, Tab, Tabs, Spinner } from '@blueprintjs/core';

class ServiceUserTagManager extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            tagList: [],
            pageSize: 5,
            currentPage: 1,
            recordCount: 0,
            isLoadingList: true,
            pendingValue: "",
            pendingName: "",
            pendingUser: null,
            searchKey: "user::full_name",
            searchValue: "",
            pendingMode: 'wizard'
        }

        this.componentDidMount = this.componentDidMount.bind(this)
        this.componentWillUnmount = this.componentWillUnmount.bind(this)
        this.onTagsLoaded = this.onTagsLoaded.bind(this);
        this.onTagUpdated = this.onTagUpdated.bind(this);
        this.onTagCreated = this.onTagCreated.bind(this);
        this.createNewTag = this.createNewTag.bind(this);
        this.deleteTag = this.deleteTag.bind(this);
        this.advancePage = this.advancePage.bind(this);
        this.retreatPage = this.retreatPage.bind(this);
        this.getTagRecords = this.getTagRecords.bind(this);
    }
    componentDidMount() {
        DM.add(ServiceData.registerListener("SERVICE_USER_TAG_LIST_LOADED", this.onTagsLoaded), ServiceData);
        DM.add(ServiceData.registerListener("SERVICE_USER_TAG_CREATED", this.onTagCreated), ServiceData);
        DM.add(ServiceData.registerListener("SERVICE_USER_TAG_UPDATED", this.onTagUpdated), ServiceData);
        DM.add(ServiceData.registerListener("SERVICE_USER_TAG_DELETED", this.onTagUpdated), ServiceData);
        this.setState({ isLoadingList: true  }, this.getTagRecords);
        MetricsData.recordClientMetric({
            metric: "component-mount",
            feature: "user-service-tag-management",
            numerical_value: 1,
            precision: 0,
          })
    }
    componentWillUnmount() {
        DM.clear()
    }

    getTagRecords() {
        ServiceData.getServiceTags(this.props.record.id, this.state.currentPage, this.state.pageSize, this.state.searchKey, this.state.searchValue);
    }

    onTagsLoaded(results) { this.setState({ tagList: results.records, recordCount: results.count, isLoadingList: false }) }
    onTagUpdated(tag) { this.getTagRecords(); }
    onTagCreated() { this.setState({ pendingUser: null, pendingName: "", pendingValue: ""}, this.getTagRecords); }
    createNewTag() { 
        if(`${this.state.pendingValue}`.length < 1) { alert("Cannot create tag with empty value"); return; }
        if(`${this.state.pendingName}`.length < 1) { alert("Cannot create tag with empty name"); return; }
        ServiceData.upsertServiceTag({ user_id: this.state.pendingUser, service_id: this.props.record.id, tag_name: this.state.pendingName, tag_value: this.state.pendingValue }); 
    }
    deleteTag(tag) { 
        if(confirm(`Are you sure you want to delete tag "${tag.tag_name}" for [${tag.service.name}]`)) {
            ServiceData.deleteServiceTag(tag);
        }
    }
    advancePage() {
        let maxPage = Math.ceil(this.state.recordCount/this.state.pageSize)
        if(this.state.currentPage < maxPage) { this.setState({ currentPage: this.state.currentPage + 1 }, this.getTagRecords); }
    }
    retreatPage() {
        if(this.state.currentPage > 1) { this.setState({ currentPage: this.state.currentPage - 1 }, this.getTagRecords); }
    }

    render() {
        let self = this;
        let recordRows = this.state.tagList.map((tag) => { return <tr key={`${tag.user_id}_${tag.service_id}_${tag.tag_name}`}>
            <td>{tag.user.full_name}</td>
            <td>{tag.tag_name}</td>
            <td>{tag.tag_value}</td>
            <td><Button icon="delete" intent="danger" onClick={this.deleteTag.bind(null, tag)}>Delete</Button></td>
        </tr> })

        let wizardValueSelectValues = [<option key="wsv_empty" value="" disabled> Empty </option>]
        switch(this.state.pendingName) {
            case "allow_schedule_in_lead": {
                wizardValueSelectValues = [
                    <option key="wsv_empty" value="" disabled> Empty </option>,
                    <option key="wsv_0" value="deny"> Deny </option>,
                    <option key="wsv_1" value="create"> Allow </option>
                ]
            } break;                                 
            case "user_training_status": {
                wizardValueSelectValues = [
                    <option key="wsv_empty" value="" disabled> Empty </option>,
                    <option key="wsv_0" value="untrained"> No Training </option>,
                    <option key="wsv_1" value="trained"> Trained </option>,
                    <option key="wsv_2" value="instructor"> Instructor </option>
                ]
            } break;
        }
        return (<div style={{ padding: 10 }}>
            <HTMLTable>
                <thead>
                    <tr>
                        <th colSpan={4}> 
                            <FormGroup label="Search">
                                <ControlGroup>
                                    <HTMLSelect value={this.state.searchKey} onChange={(e) => { this.setState({ searchKey: e.target.value}, this.getTagRecords) }}>
                                        <option value="user::full_name"> User Full Name </option>
                                        <option value="user::username"> Username </option>
                                        <option value="tag::name"> Tag Name </option>
                                    </HTMLSelect>
                                    <InputGroup value={this.state.searchValue} onChange={(e) => { this.setState({ searchValue: e.target.value}, this.getTagRecords) }}></InputGroup>
                                </ControlGroup>
                            </FormGroup>
                        </th>
                    </tr>
                    <tr>
                        <th> Service </th>
                        <th> Tag Name </th>
                        <th> Tag Value </th>
                        <th> Delete </th>
                    </tr>
                </thead>
                <tbody>
                    {(self.state.isLoadingList ? <tr><td colSpan={4}><Spinner/></td></tr> : recordRows)}
                </tbody>
                <tfoot>
                    <tr>
                        <td> <Button icon="arrow-left" onClick={this.retreatPage} minimal={true}/> </td>
                        <td colSpan={2}> Page {this.state.currentPage} of {Math.floor(this.state.recordCount/this.state.pageSize) + 1} ({this.state.recordCount} Records)</td>
                        <td> <Button icon="arrow-right" onClick={this.advancePage} minimal={true}/> </td>
                    </tr>
                    <tr>
                        <td colSpan={4}>
                            <Tabs id="tag_add_tabs" onChange={(newTab) => { this.setState({ pendingMode: newTab, pendingName: "", pendingValue: "" }); }} selectedTabId={this.state.pendingMode}>
                                <Tab id='wizard' title='Wizard' panel={<div>
                                    <FormGroup label="Select User">
                                        <SearchPicker value={this.state.pendingUser} fieldKey={"pendingUser"} config={{ rowSelectKey: "id", rowDisplayKey: "full_name", dataSource : UserData, searchConfig : UserData.searchConfig, listMapping : UserData.listMapping }} onSelect={(newValue) => { this.setState({ pendingUser: newValue }) }} dataSource={UserData}/>
                                    </FormGroup>
                                    <FormGroup label="Tag Config">
                                        <ControlGroup>
                                            <HTMLSelect value={this.state.pendingName || ""} onChange={(event) => { this.setState({ pendingName: event.target.value, pendingValue: "" })}}>
                                                <option value={""} disabled> -- Select Option -- </option>
                                                <option value={"allow_schedule_in_lead"}> Allow User to Schedule Inside Lead Time </option>
                                                <option value={"user_training_status"}> Set User Training Status </option>
                                            </HTMLSelect>
                                            <HTMLSelect value={this.state.pendingValue || ""} onChange={(event) => { this.setState({ pendingValue: event.target.value })}}>
                                                {wizardValueSelectValues}
                                            </HTMLSelect>
                                            <Button intent={"success"} icon="plus" onClick={this.createNewTag}> Add / Update Tag </Button>
                                        </ControlGroup>
                                    </FormGroup>
                                </div>}/>
                                <Tab id='manual' title='Manual'  panel={<div>
                                    <FormGroup label="Select User">
                                        <SearchPicker value={this.state.pendingUser} fieldKey={"pendingUser"} config={{ rowSelectKey: "id", rowDisplayKey: "full_name", dataSource : UserData, searchConfig : UserData.searchConfig, listMapping : UserData.listMapping }} onSelect={(newValue) => { this.setState({ pendingUser: newValue }) }} dataSource={UserData}/>
                                    </FormGroup>
                                    <FormGroup label="Tag Config">
                                        <ControlGroup>
                                            <InputGroup type="text" placeholder="Tag Name" value={this.state.pendingName} onChange={(event) => { this.setState({ pendingName: `${event.target.value}`.toLowerCase().replace(" ", "_") })}}/>
                                            <InputGroup type="text" placeholder="Tag Value" value={this.state.pendingValue} onChange={(event) => { this.setState({ pendingValue: `${event.target.value}`.toLowerCase().replace(" ", "_") })}}/>
                                            <Button intent={"success"} icon="plus" onClick={this.createNewTag}> Add / Update Tag </Button>
                                        </ControlGroup>
                                    </FormGroup>
                                </div>}/>
                            </Tabs>
                        </td>
                    </tr>
                </tfoot>
            </HTMLTable>
        </div>)
    }
}

export default ServiceUserTagManager;

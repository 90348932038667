
	'use strict';

	/*

		Line Item Module Event Bus. Handles all interactions with Line Item Orders.
		Operates mostly as a passthrough to the API endpoints.

	*/
	import Eventer from "../Eventer";
	import { get, post, put, patch, del, getErrorPayload  } from "data-modules/SystemREST";
	var LineItem = Eventer.createBus("LINE");
	// AppData.injectJWTErrorListener(LineItem);

  /*
    Service Types
    0 - Scheduled
    1 - Linked
    2 - Custom
    3 - Line Item
    4 - Form Template
  */

	LineItem.loadOrderLines = function (order_id) {
		return get(`/lineitem/forOrder/${order_id}`).send().then(response => { return response.body.lines;});
	}

	LineItem.createOrder = async function (recordData) {
		recordData.lines = recordData.lines.reduce(function(lines, line) {
			var include = true;
			// To prevent confusion regarding zero dollar value lines we strip them out.
			if(line.quantity <= 0) {include = false;}
			if(line.rate == 0) {include = false;}
			if(include) {
					return lines.concat([line]);
			} else {
					return lines;
			}

		}, []);
		return post("/lineitem/create").send({order : recordData}).then(function(res) {
			var payload = res.body;
			LineItem.notifyListeners("ORDER_CREATED", payload.order_id);
			return payload;
		}, function (err) {
			LineItem.notifyListeners("ORDER_CREATE_ERROR", getErrorPayload(err));
			return { status: false, error: err };
		});
	};

	LineItem.addLine = function (order_id, line_data) {
		return post("/lineitem/forOrder/"+order_id).send(line_data).then(function(res) {
			var payload = res.body;
			LineItem.notifyListeners("RECORD_CREATED", payload.order_id);
			return payload.order_id;
		}, function (err) {
			LineItem.notifyListeners("RECORD_CREATE_ERROR", getErrorPayload(err));
		});
	};

	LineItem.voidLine = function (line_id) {
		return del("/lineitem/"+line_id).send({}).then(function(res) {
			var payload = res.body;
			LineItem.notifyListeners("RECORD_UPDATED", payload.order_id);
			return payload.order_id;
		}, function (err) {
			LineItem.notifyListeners("RECORD_UPDATE_ERROR", getErrorPayload(err));
		});

	};


	LineItem.updateRecord = function (recordData) {
		return post("/lineitem/update").send({line : recordData}).then(function(res) {
			var payload = res.body;
			LineItem.notifyListeners("RECORD_UPDATED", payload.line);
			return payload.line;
		}, function (err) {
			LineItem.notifyListeners("RECORD_UPDATE_ERROR", getErrorPayload(err));
		});
	};

	LineItem.removeRecord = function(record) {
		LineItem.notifyListeners("RECORD_REMOVED", record);
	};

  export default LineItem;

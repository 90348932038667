
'use strict';

import React from 'react';
import { createRoot } from 'react-dom/client';
import ReactDOM from 'react-dom';
import { HashRouter, Route, Routes, Navigate } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import withRouter from "lib/withRouter";
import { Spinner, ProgressBar } from "@blueprintjs/core"
import loadable from "@loadable/component"

// Data Includes
import AppData from 'data-modules/AppData';
import Navbar from './Navigation/Navbar';
import DataManager from "data-modules/DataManager"
let DM = new DataManager();

// Site Dialog
import SiteDialogData from 'data-modules/SiteDialogData'
import SiteDialogContainer from "generic-components/SiteDialogContainer";
import ErrorBoundary from "generic-components/ErrorBoundary"

// Sentry Includes
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import LoginPage from "./Login/LoginPage"
import LogoutPage from "./Login/LogoutPage"
import Home from "./Home"
import Service from "./Service"
import MyOrders from "./MyOrders"
import MyAccount from "./MyAccount"
import Group from "./Group"
import OrderViewer from "./OrderViewer"
import CustomForm from "./CustomForm"
import Message from "./Message"
import GroupCalendar from "./Calendar/GroupCalendar"
import ServiceCalendar from "./Calendar/ServiceCalendar"

let Router = HashRouter;
let NavbarWithRouter = withRouter(Navbar);
let LoginPageWithRouter = withRouter(LoginPage);
let GroupPage = withRouter(Group);
let OrderViewerPage = withRouter(OrderViewer);
let AuditPage = loadable(() => import("./Audit"), { fallback: <Spinner/> });
let InvoicePage = loadable(() => import("./Manage/Invoice"), { fallback: <Spinner/> });
let ReportPage = loadable(() => import("./Manage/Report"), { fallback: <Spinner/> });
let ZebrafishPage = loadable(() => import("./Zebrafish"), { fallback: <Spinner/> });
let FlyFoodPage = loadable(() => import("./FlyFood"), { fallback: <Spinner/> });
let DNAPeptidePage = loadable(() => import("./DNAPeptide"), { fallback: <Spinner/> });
let GSIPTPage = loadable(() => import("./GSIPT"), { fallback: <Spinner/> });
let BBSPPage = loadable(() => import("./BBSP"), { fallback: <Spinner/> });
let MSSPPage = loadable(() => import("./MSSP"), { fallback: <Spinner/> });
let ManagePage = loadable(() => import("./Manage"), { fallback: <Spinner/> });
let AdminPage = loadable(() => import("./Admin"), { fallback: <Spinner/> });
let WalkupPage = loadable(() => import("./Walkup"), { fallback: <Spinner/> });
let QuotesPage = loadable(() => import("./Quotes"), { fallback: <Spinner/> });
let FASTAProcessing = loadable(() => import("./FASTAProcessing"), { fallback: <Spinner/> });


/*
  Express Route Wrapper. Validates route required authorization against the group permissions assigned to the user and redirectes to login
  page on authorization failure. Created so that authorization code isn't replicated at every endpoint.
*/
const AuthorizedRoute = ({ children, requiredAuth }) => {
		let authToken = AppData.get('authToken');
		if(typeof authToken == 'undefined' || authToken == "") {
			let storedAuthToken = sessionStorage.getItem("resource_authToken");
			if(typeof storedAuthToken === "string" ) {
				AppData.set("authToken", storedAuthToken);
				AppData.set("userType", sessionStorage.getItem("resource_authType"));
				AppData.set("userID", sessionStorage.getItem("resource_userID"));
				AppData.set("groupPermissions", JSON.parse(sessionStorage.getItem("resource_groupPermissions")));
				AppData.set("isLoggedIn", true);
			} else {
				return <Navigate to={'/login?reason=authfail'}/>
			}
		}
		if(!AppData.authorize(requiredAuth.minAuth, requiredAuth.group)) {
			return <Navigate  to={'/login?reason=authfail'}/>
	 	}

    return <ErrorBoundary>{children}</ErrorBoundary>;
}



/*
Main App Component. Entrypoint for application and main route definitions. Note that the routes
are designed to be fractal so only the base route that is attached to the module is listed here.
Additional module routing is handled in the module itself. Any component in a Loadable is
subject to code splitting via Webpack to improve loading times with a BlueprintJS Spinner to indicate loading.
*/

class App extends React.Component {

	constructor(props) {
    super(props)
    this.componentDidMount = this.componentDidMount.bind(this);
    this.componentWillUnmount = this.componentWillUnmount.bind(this);
    this.authErrorHandler = this.authErrorHandler.bind(this);
    this.requestErrorHandler = this.requestErrorHandler.bind(this);
    this.state = {
      loadingUser: true
    }
  }
  componentDidMount() {
    DM.add(AppData.registerListener("REQUEST_SERVER_ERROR", this.requestErrorHandler), AppData);
    DM.add(AppData.registerListener("REQUEST_GATEWAY_ERROR", this.requestErrorHandler), AppData);
    DM.add(AppData.registerListener("REQUEST_SUBMISSION ERROR", this.requestErrorHandler), AppData);
    DM.add(AppData.registerListener("REQUEST_AUTHORIZATION_DENIED", this.authErrorHandler), AppData);
    DM.add(AppData.registerListener("REQUEST_RESOURCE_MISSING", this.requestErrorHandler), AppData);
    AppData.fetchAppConfig();
    AppData.fetchFeaturesConfig();
    AppData.getUserConfig().then((result) => { 
      this.setState({ loadingUser: false }); 
      if(!result) { window.location = "/auth/login"; }
    })
  }
  componentWillUnmount() {
    DM.clear();
  }
  requestErrorHandler(payload, action) {
    SiteDialogData.open("Request Error", <div>{payload.message || payload.debug}</div>, () => {})
  }
  authErrorHandler(payload, action) {
    if(payload.debug.name == "TokenExpiredError") {
      SiteDialogData.open("Access Error", "It looks like your login has expired. Please login again to gain access.", () => {
        AppData.logout();
        window.location = "/auth/login";
      })
    } else {
      SiteDialogData.open("Access Error", `You do not have sufficient permissions for the resource you just tried to access. (${ payload.route})`, () => {})
    }
  }
	render() {
    if(this.state.loadingUser) { return <div style={{ padding: 10 }}><ProgressBar/></div> }
		return (<Router>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <NavbarWithRouter/>
        <Routes>
          <Route path="/login" element={<LoginPageWithRouter/>}/>
          <Route path="/logout" element={<LogoutPage/>}/>

          <Route index element={<AuthorizedRoute requiredAuth={{ minAuth: 10 }}> <Home/> </AuthorizedRoute>}/>
          <Route path="/home" element={<AuthorizedRoute requiredAuth={{ minAuth: 10 }}> <Home/> </AuthorizedRoute>}/>

          <Route path="/my_orders" element={<AuthorizedRoute requiredAuth={{ minAuth: 10 }}> <MyOrders/></AuthorizedRoute>}/>

          <Route exact path="/audit/:mode/:submode/:value" element={<AuthorizedRoute requiredAuth={{ minAuth: 19 }}> <AuditPage/> </AuthorizedRoute>}/>
          <Route exact path="/invoice" element={<AuthorizedRoute requiredAuth={{ minAuth: 19 }}> <InvoicePage/> </AuthorizedRoute>}/>
          <Route exact path="/report" element={<AuthorizedRoute requiredAuth={{ minAuth: 19 }}> <ReportPage/> </AuthorizedRoute>}/>

          <Route exact path="/audit" element={<AuthorizedRoute requiredAuth={{ minAuth: 19 }}> <AuditPage/> </AuthorizedRoute>}/>
          <Route path="/my_account" element={<AuthorizedRoute requiredAuth={{ minAuth: 10 }}> <MyAccount/> </AuthorizedRoute>}/>

          <Route path="/zebrafish/*" element={<AuthorizedRoute requiredAuth={{ minAuth: 10 }}> <ZebrafishPage/></AuthorizedRoute>}/>

          <Route path="/flyfood" element={<AuthorizedRoute requiredAuth={{ minAuth: 10 }}> <FlyFoodPage/> </AuthorizedRoute>}/>

          <Route path="/dna_peptide/*" element={<AuthorizedRoute requiredAuth={{ minAuth: 10 }}> <DNAPeptidePage/></AuthorizedRoute>}/>

          <Route path="/GSIPT/:group_id/*" element={<AuthorizedRoute requiredAuth={{ minAuth: 10 }}> <GSIPTPage/> </AuthorizedRoute>}/>

          <Route path="/BBSP/:group_id/*" element={<AuthorizedRoute requiredAuth={{ minAuth: 10 }}> <BBSPPage/></AuthorizedRoute>}/>

          <Route path="/MSSP/:group_id/*" element={<AuthorizedRoute requiredAuth={{ minAuth: 10 }}> <MSSPPage/></AuthorizedRoute>}/>

          <Route path="/FASTA/:group_id/*" element={<AuthorizedRoute requiredAuth={{ minAuth: 10 }}> <FASTAProcessing/></AuthorizedRoute>}/>

          <Route path="/group/:id" element={<AuthorizedRoute requiredAuth={{ minAuth: 10 }}> <GroupPage/> </AuthorizedRoute>}/>

          <Route path="/order/:id" element={<AuthorizedRoute requiredAuth={{ minAuth: 10 }}> <OrderViewerPage/> </AuthorizedRoute>}/>

          <Route path="/form/*" element={<AuthorizedRoute requiredAuth={{minAuth: 10}}> <CustomForm/> </AuthorizedRoute>}/>

          <Route path="/message" element={<AuthorizedRoute requiredAuth={{ minAuth: 20 }}> <Message/> </AuthorizedRoute>}/>

          <Route path="/group-calendar/:group_id/*" element={<AuthorizedRoute requiredAuth={{ minAuth: 10 }}> <GroupCalendar/> </AuthorizedRoute>}/>
          <Route path="/service-calendar/:service_id/*" element={<AuthorizedRoute requiredAuth={{ minAuth: 10 }}> <ServiceCalendar/> </AuthorizedRoute>}/>

          <Route path="/manage/*" element={<AuthorizedRoute requiredAuth={{ minAuth: 20 }}> <ManagePage/> </AuthorizedRoute>}/>

          {/* <Route path="/admin/*" element={<AuthorizedRoute requiredAuth={{ minAuth: 30 }}> <AdminPage/> </AuthorizedRoute>}/> */}

          <Route path="/walkup/:service_id/*" element={<AuthorizedRoute requiredAuth={{ minAuth: 10 }}> <WalkupPage/> </AuthorizedRoute>}/>

          <Route path="/quotes" element={<AuthorizedRoute requiredAuth={{ minAuth: 20 }}> <QuotesPage/> </AuthorizedRoute>}/>

          <Route exact path="/service/:service_id" element={<AuthorizedRoute requiredAuth={{ minAuth: 10 }}> <Service/> </AuthorizedRoute>}/>
        </Routes>
        <SiteDialogContainer/>
      </QueryParamProvider>
		</Router>);
	}
}

fetch("/config/CLIENT_SENTRY_DSN,SENTRY_ENV").then((response) => { return response.json() }).then((response) => {
  if(response.CLIENT_SENTRY_DSN) {
    const sentryEnv = response.SENTRY_ENV || "production";
    Sentry.init({
      dsn: response.CLIENT_SENTRY_DSN,
      integrations: [new BrowserTracing()],
      environment: sentryEnv,
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: (sentryEnv === "production" ? 0.1 : 1.0),
    });
  }

  const container = document.getElementById('content')
  const root = createRoot(container)
  root.render(<App/>);

}).catch(err => {
  console.log(err)
  const container = document.getElementById('content')
  const root = createRoot(container)
  root.render(<App/>);

})



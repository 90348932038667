
	'use strict';

	/*

		Group Data Event Bus

	*/

  import AppData from "data-modules/AppData";
	import Eventer from "../Eventer";
  import queryString from 'query-string';
  import { get, post, put, patch, del, getErrorPayload  } from "data-modules/SystemREST";
  var GroupData = Eventer.createBus("GRP");
  // AppData.injectJWTErrorListener(GroupData)

  // Management UI Row Display Functions
	GroupData.listMapping = {
		title : function (group) { return group.name; },
		subtitle : function (group) { return group.short_name;}
	};

  // Managment UI List Search Options
	GroupData.searchConfig = { options: [
		{value: "name", title: "Name", type: "text"},
		{value: "short_name", title: "Short Name", type: "text"},
		{value: "parent_group", title: "Parent Group", type: "text"},
		{value: "id", title: "Group ID", type: "text"}
	] };

	GroupData.getRecordList = function (opts) {
    
		let options = opts || {};
    let queryOptions = {};

    // Apply pagination if exists
    if(options._page && options._pageSize) {
      queryOptions._pageSize = options._pageSize;
      queryOptions._page = options._page;
    }

    // Apply query options if it exists
    if(options.where) { queryOptions = Object.assign(queryOptions, options.where) }

    if(options._managed) { queryOptions._managed = options._managed; } // Group Management Specific Switch to display only groups managed by the user.

    let requestURL = `/groups`;
    if(queryString.stringify(queryOptions).length > 0) { requestURL = `${requestURL}?${queryString.stringify(queryOptions)}`}

    return get(requestURL).send().then(function(res) {
      var payload = res.body;
      GroupData.notifyListeners("LIST_LOADED", { records: payload.groups, count: payload.count });
      return { records: payload.groups, count: payload.count }
    }, function (err) {
      GroupData.notifyListeners("LIST_LOAD_ERROR", getErrorPayload(err));
      return err;
    });

  };

	GroupData.recordSearch = function (key, value) {
    post("/group/search").send({"key" : key, "value" : value}).then(function(res) {
      var payload = res.body;
      GroupData.notifyListeners("LIST_LOADED", payload.groups);
    }, function (err) {
      GroupData.notifyListeners("LIST_LOAD_ERROR", getErrorPayload(err));
    });
  };

	GroupData.updateRecord = function (recordData) {
    post("/group/update").send({group : recordData}).then(function(res) {
        var payload = res.body;
        GroupData.notifyListeners("RECORD_UPDATED", payload.group);
    }, function (err) {
      GroupData.notifyListeners("RECORD_UPDATE_ERROR", getErrorPayload(err));
    });

  };

	GroupData.createRecord = function (recordData) {
    post("/group/create").send({group : recordData}).then(function(res) {
        var payload = res.body;
        GroupData.notifyListeners("RECORD_CREATED", payload.group);
    }, function (err) {
      GroupData.notifyListeners("RECORD_CREATE_ERROR", getErrorPayload(err));
    });

  };

	GroupData.getRecord = function (record_id) {
    return post("/group/"+record_id).send().then(function(res) {
      var payload = res.body;
      GroupData.notifyListeners("RECORD_LOADED", payload.group);
      return payload.group;
    }, function (err) {
      GroupData.notifyListeners("RECORD_LOAD_ERROR", getErrorPayload(err));
    });

  };

	// Aliases / Specific Methods

	GroupData.getGroups = function (opts) {
		return GroupData.getRecordList(opts);
	};

	GroupData.getGroup = function (group_id) {
		return GroupData.getRecord(group_id);
	};

	GroupData.getGroupServices = function (group_id, includeRates = false, types = []) {
    let requestURL = "/group/"+group_id+"/services";
    let params = new URLSearchParams({
       ...(includeRates ? { _rates: true, _presets: true } : {}),
       ...(types.length > 0 ? { type: types.join(",") } : {})
    });
    if (params.size > 0) { requestURL = `${requestURL}?${params.toString()}` }
		return post(requestURL).send().then(function(res) {
      var payload = res.body;
      GroupData.notifyListeners("SERVICES_LOADED", payload.services);
      return payload.services;
    }, function (err) {
      GroupData.notifyListeners("SERVICES_LOAD_ERROR", getErrorPayload(err));
    });
  };
  
  GroupData.getParentUnits = function () {
    get("/group/parent_units").send().then(function (res) {
      var payload = res.body;
      GroupData.notifyListeners("PARENT_UNITS_LOADED", payload);
    }, function (err) {
      GroupData.notifyListeners("PARENT_UNITS_ERROR", getErrorPayload(err));
    });
  };

	GroupData.getGroupNews = function (group_id, include_hidden) {
		post(`/group/${group_id}/news`).send().then(function(res) {
      var payload = res.body;
      GroupData.notifyListeners("NEWS_LOADED", payload.news);
    }, function (err) {
      GroupData.notifyListeners("NEWS_LOAD_ERROR", getErrorPayload(err));
    });
	};


  export default GroupData;

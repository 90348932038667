
'use strict';

import React from 'react';
import Config from "../config";
import DataManager from "data-modules/DataManager"
let DM = new DataManager();
import {AnchorButton, Navbar, NavbarGroup, ButtonGroup, Button, Classes, Alignment } from "@blueprintjs/core"

// Data Includes
import AppData from 'data-modules/AppData';
import UserData from 'data-modules/UserData';

class SiteNavbar extends React.Component {
  constructor() {
    super();

    this.componentDidMount = this.componentDidMount.bind(this)
    this.componentWillUnmount = this.componentWillUnmount.bind(this)
    this.title_click = this.title_click.bind(this)
    this.toggleManageMode = this.toggleManageMode.bind(this)
  }

  componentDidMount () {
    DM.add(AppData.registerListener("USER_LOGGED_OUT", () => { this.setState({}); }), AppData);
    AppData.fetchAppConfig().then(() => { document.title = AppData.site_title(); this.setState({}); });
    AppData.fetchFeaturesConfig();
    if(AppData.get("full_name") == null) { if(AppData.get("userID") != null) { console.log("Honk?"); UserData.getRecord(AppData.get("userID")).then((user) => {
      AppData.set("full_name",  user.full_name)
      this.setState({}); 
    });  } }
  }

  componentWillUnmount() {
    DM.clear()
  }

  title_click (event) {
    window.location = "#/";
  }

  toggleManageMode () {
    AppData.set("manageMode", !AppData.get("manageMode"));
    this.setState({});
  }

  render () {

    var style = {
      container : {
        width : "100%",
        backgroundColor : "#aa1501",
      },
      title_container : {
        width: "100%"
      },
      site_title : {
        fontWeight: "bold",
        fontSize: "2rem",
        color : "white",
        display: "inline-block",
        cursor : "default",
        fontFamily: "Montserrat, sans-serif",
        textTransform: "uppercase",
      },
      menu_container : {
        width : "100%",
        backgroundColor : "#434343"
      }

    };


    var menu = [];

    Config.navbar.forEach(function (item, index, sourceArray) {
      if(AppData.authorize(item.minAuth)) {
        menu.push(
          <AnchorButton icon={item.icon} minimal={true} large={true} key={index} href={item.path} target={item.target || "_self"}> {item.title} </AnchorButton>
        );
      }
    });

    var showLogout = AppData.isLoggedIn();
    var menuElement = <div></div>;
    var logoutElement = <span></span>;
    if(showLogout) {
      logoutElement = (<NavbarGroup>
        <ButtonGroup> 
            <Button icon="user" minimal={true}> {AppData.get("full_name") || "unknown"}</ Button>
            <AnchorButton icon="lock" key="logout_link" style={style.loginLink} href="/auth/logout"> Logout </AnchorButton>
        </ButtonGroup>
      </NavbarGroup>)
      menuElement = (<Navbar style={{ backgroundColor: "#372c2c" }}>
        <NavbarGroup>
          <ButtonGroup>
            {menu}
          </ButtonGroup>
        </NavbarGroup>
      </Navbar>)
    } else {
      logoutElement = <AnchorButton icon="lock" key="login_link" style={style.loginLink} href="#/login"> Login </AnchorButton>;
    }

    return (
      <div className={"bp5-dark"}>
        <Navbar style={style.container}>
          <NavbarGroup align={Alignment.LEFT}>
            <div style={style.site_title} className={Classes.NAVBAR_HEADING}> {AppData.site_title()} </div>
          </NavbarGroup>
          <NavbarGroup align={Alignment.RIGHT}>
            <span> {logoutElement} </span>
          </NavbarGroup>
        </Navbar>
        {menuElement}
      </div>
    );
  }
}

export default SiteNavbar;

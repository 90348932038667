import { useEffect, useState, useRef, useCallback } from "react"
import { debounce } from "lodash"
import { NumericInput, ControlGroup, HTMLSelect, Tag, Button } from "@blueprintjs/core"


export default function DurationInput({
    value,
    minUnitBlock = 1,
    onChange = () => {},
    readOnly = false,
}) {

    const [pendingValue, setPendingValue] = useState(value);
    const [hourIsFocused, setHourIsFocused] = useState(false)
    const [minuteIsFocused, setMinuteIsFocused] = useState(false)
    const hourInputRef = useRef(null)
    const minuteInputRef = useRef(null)
    const debouncedOnChange = useCallback(debounce(onChange, 750), [onChange])

    useEffect(() => { setPendingValue(value); }, [value])
    
    useEffect(() => { if(hourInputRef !== null) { 
        hourInputRef.current.readOnly = true; 
        hourInputRef.current.onfocus = () => { setHourIsFocused(true) }
        hourInputRef.current.onblur = () => { setHourIsFocused(false) }
    } }, [hourInputRef])

    useEffect(() => { if(minuteInputRef !== null) { 
        minuteInputRef.current.readOnly = true; 
        minuteInputRef.current.onfocus = () => { setMinuteIsFocused(true) }
        minuteInputRef.current.onblur = () => { setMinuteIsFocused(false) }
    } }, [minuteInputRef])

    let hourCount = Math.floor(pendingValue/60)
    let minuteCount = pendingValue % 60;
    let minUnitHourCount = Math.floor(minUnitBlock / 60)
    let minUnitMinuteCount = minUnitBlock % 60
    
    const updateHourCount = (number, string) => {
        if(readOnly) { return }
        if(isNaN(number)) { number = minUnitHourCount }
        let totalDuration = ((number * 60) + minuteCount)
        if(totalDuration < minUnitBlock) { totalDuration = minUnitBlock }
        setPendingValue(totalDuration)
        debouncedOnChange(totalDuration)
    }
    const updateMinuteCount = (number, string) => {
        if(readOnly) { return }
        if(isNaN(number)) { number = minUnitMinuteCount }
        let hourAdjustment = Math.floor(number/60);
        let blockMinuteValue = Math.ceil((number % 60) / minUnitBlock) *  minUnitBlock;
        let totalDuration = (((hourCount + hourAdjustment) * 60) + (blockMinuteValue % 60))
        if(totalDuration < minUnitBlock) { totalDuration = minUnitBlock } 
        setPendingValue(totalDuration)
        debouncedOnChange(totalDuration)
    } 

    const incrementDuration = () => {
        if(readOnly) { return }
        let newDuration = pendingValue + minUnitBlock;
        setPendingValue((newDuration < minUnitBlock ? minUnitBlock : newDuration))
        if(newDuration < minUnitBlock) { newDuration = minUnitBlock };
        debouncedOnChange(newDuration)
    }
    const decrementDuration = () => {
        if(readOnly) { return }
        let newDuration = pendingValue - minUnitBlock
        setPendingValue((newDuration < minUnitBlock ? minUnitBlock : newDuration))
        if(newDuration < minUnitBlock) { newDuration = minUnitBlock };
        debouncedOnChange(newDuration)
    }
    let minHourStep = (Math.floor(minUnitBlock/60) < 1 ? 1 : Math.floor(minUnitBlock/60))
    return <ControlGroup style={{ width: 400 }}> 
        <Button icon={"less-than"} disabled={readOnly} onClick={decrementDuration}></Button>
        <NumericInput readOnly={readOnly} fill={true} inputRef={hourInputRef} leftElement={<Tag intent={( hourIsFocused ? "success" : "primary" )}>Hours</Tag>} min={0} value={hourCount} stepSize={minHourStep} majorStepSize={minHourStep} minorStepSize={minHourStep} onValueChange={updateHourCount}/> 
        <NumericInput readOnly={readOnly} fill={true} inputRef={minuteInputRef} leftElement={<Tag intent={( minuteIsFocused ? "success" : "primary" )}>Minutes</Tag>} min={0} value={minuteCount} stepSize={minUnitBlock} majorStepSize={minUnitBlock} minorStepSize={minUnitBlock} onValueChange={updateMinuteCount}/> 
        <Button icon={"greater-than"} disabled={readOnly} onClick={incrementDuration}></Button>
    </ControlGroup>
}
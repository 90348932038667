import React from "react"
import { Button, Intent, ControlGroup, FileInput, H3, Icon, Callout, H5, Alert, Spinner, Popover, Tooltip } from "@blueprintjs/core"
import DataManager from "data-modules/DataManager"
let DM = new DataManager();
import UserData from "data/UserData";

export class ProfilePictureManager extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      currentProfilePic: false,
      newPictureFile: null,
      didUpdate: false,
      deleteConfirmationOpen: false,
      didDelete: false,
      loadingNewPicture: false,
    }

    this.fileSelected = this.fileSelected.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.handleDelConfirm = this.handleDelConfirm.bind(this);
    this.profilePictureLoaded = this.profilePictureLoaded.bind(this);
    this.profilePictureUploaded = this.profilePictureUploaded.bind(this);
    this.profilePictureDeleted = this.profilePictureDeleted.bind(this);
  }

  componentDidMount () {
    DM.add(UserData.registerListener("PROFILE_PIC_LOADED", this.profilePictureLoaded ), UserData);
    DM.add(UserData.registerListener("PROFILE_PIC_UPDATED", this.profilePictureUploaded ), UserData);
    DM.add(UserData.registerListener("PROFILE_PIC_DELETED", this.profilePictureDeleted ), UserData);
    if (this.props.record?.id) {
      // Retrieve current profile pic if it exists
      this.setState({loadingNewPicture: true});
      UserData.getProfilePicture(this.props.record.id);
    }
  }

  componentWillUnmount() {
    DM.clear();
  }

  componentDidUpdate( prevProps) {
    if ( this.props.record !== prevProps.record ) {
      // Retrieve current profile pic if it exists
      this.setState({currentProfilePic: false, loadingNewPicture: true})
      UserData.getProfilePicture(this.props.record.id);
    }
  }

  profilePictureLoaded (profile_pic) {
    if (profile_pic) {
      this.setState({
        currentProfilePic: true,
        loadingNewPicture: false});
    } else {
      this.setState({loadingNewPicture: false});
    }
  }

  fileSelected(event) {
    let filename = event.target.value;
    let file = event.target.files[0];

    this.setState({ didUpdate: false, newPictureFile: file, didDelete: false});
  }

  uploadFile() {
    let fileToUpload = this.state.newPictureFile;
    UserData.updateProfilePicture(this.props.record.id, fileToUpload);
  }

  profilePictureUploaded () {
    this.setState({ didUpdate: true, newPictureFile: null, loadingNewPicture: true});
    UserData.getProfilePicture(this.props.record.id);
  }

  handleDelConfirm() {
    UserData.deleteProfilePic(this.props.record.id);
  }

  profilePictureDeleted () {
    this.setState({
      didUpdate: false,
      currentProfilePic: null,
      newPictureFile: null,
      didDelete: true,
      deleteConfirmationOpen: false
    });
  }


  render () {
    let maxPictureHeight = this.props.maxHeight || "168px";

    let picturePreview = <div style={{margin: 20}}>
      <Icon icon={"media"} size={60} intent={"warning"}/>
      <p>No picture</p>
    </div>;
    let previewBorder = "white";
    let delButton = <div></div>;

    if(this.state.loadingNewPicture){
      picturePreview = <Spinner />;
    } else if(this.state.newPictureFile){
      picturePreview = <img style={{ maxHeight: "100%", maxWidth: "100%" }} src={URL.createObjectURL(this.state.newPictureFile)} alt={this.state.newPictureFile.name || ''} />;
      previewBorder = "red";
    } else if(this.state.currentProfilePic){
      picturePreview = <img
          style={{ maxHeight: "100%", maxWidth: "100%" }}
          src={`/user/${this.props.record.id}/picture`}
          alt={this.state.currentProfilePic.name || ''}
      />;
      delButton = this.props.isEditable && <Popover content="Delete profile picture"  style={{margin: 2, height: "min-content" }} isOpen={false} shouldReturnFocusOnClose={false}>
        <Tooltip content="Delete profile picture" openOnTargetFocus={false}>
            <Button intent="danger" icon="delete" style={{margin: 2 }} onClick={()=>{ this.setState({deleteConfirmationOpen: true, didUpdate: false}); }} />
        </Tooltip>
      </Popover>;
    }

    let updateUploadText = this.state.currentProfilePic ? 'Update' : 'Upload';
    let updateButton = <Button disabled={true} icon={"upload"}>{`${updateUploadText} Profile Picture`}</Button>
    if(this.state.newPictureFile) { updateButton = <Button intent={Intent.PRIMARY} icon={"upload"} onClick={this.uploadFile} >{`${updateUploadText} Profile Picture`}</Button> }
    if(this.state.didUpdate) {
      updateButton = <Callout intent={Intent.SUCCESS} style={{ paddingTop: 4, paddingBottom: 4, maxWidth: 230 }} icon="" >
        <Icon icon="tick" intent={"success"} style={{marginTop: -4}} />
        <H5>Profile Picture Updated</H5>
      </Callout>
    }
    if(this.state.didDelete) {
      updateButton =  <Callout intent={Intent.SUCCESS} style={{ paddingTop: 4, paddingBottom: 4, maxWidth: 230 }} icon="" >
        <Icon icon="trash" intent={"success"} style={{marginTop: -4}} />
        <H5>Profile Picture Deleted</H5>
      </Callout>
    }

    let inputText = "Select Image";
    if(this.state.newPictureFile){ inputText = this.state.newPictureFile.name; }

    let controlElements = this.props.isEditable && <div >
      <H3> Update Picture </H3>
      <ControlGroup>
        <FileInput large text={inputText} onInputChange={this.fileSelected} inputProps={{ accept:'image/*', onClick: (e) => { e.target.value = null; }}}/>
      </ControlGroup>
      <div style={{ marginTop: 6 }}>
        <Alert
            cancelButtonText="Cancel"
            confirmButtonText="Delete Profile Picture"
            icon="trash"
            intent={Intent.DANGER}
            isOpen={this.state.deleteConfirmationOpen}
            onCancel={()=>{ this.setState({ deleteConfirmationOpen: false}); }}
            onConfirm={this.handleDelConfirm}
        >
          <p>
            Are you sure you want to delete your profile picture?
          </p>
        </Alert>
        {updateButton}
      </div>
    </div>

    return (
      this.props.displayOnly && !this.state.currentProfilePic && <div></div> ||
        <div>
          <div style={{ display: "flex", marginBottom: 8}}>
            <div style={{height: maxPictureHeight, width: "fit-content", padding: 2, backgroundColor: "white", borderRadius: 2, border: `1px solid ${previewBorder}` }} elevation={1}>
              {picturePreview}
            </div>
            {delButton}
          </div>
          {controlElements}
        </div>
    )
  }
}

'use strict'

import { InputGroup, ProgressBar } from "@blueprintjs/core";
import ServiceButton from "../Group/ServiceButton";

const ServiceSearchResults = ({ searchValue, updateSearchValue, searchResults, getIsManager, isLoading = false }) => {
    

    let serviceButtons = searchResults.map((service, index) => {
        return (<ServiceButton key={index} service={service} showGroup={true} color={"gray"} showTagManager={false} getIsManager={getIsManager} />);
    })

    return (
        <div style={{ margin: 5, padding: 5, backgroundColor: "gray", borderRadius: 3, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", gap: 5 }}>
            <InputGroup style={{ border: "1px solid black", backgroundColor: "lightgray", borderRadius: 5 }} fill={true} leftIcon="filter" value={searchValue} onChange={updateSearchValue} placeholder="Search for a service..." />
            {( serviceButtons.length > 0 && <div>
                {(isLoading ? <ProgressBar/> : serviceButtons )}
            </div>)}
        </div>
    )
}

export default ServiceSearchResults
'use strict'

import OrgContainer from "./OrgContainer";

const UnitList = ({ units, getIsManager }) => {
    var unitElements = [];
    Object.keys(units).map(function (key, index) {
        var unit = units[key];
        unitElements.push(<OrgContainer key={index} unit={unit} getIsManager={getIsManager} />);
    });
    return (<div>
        {unitElements}
    </div>);
}

export default UnitList;

	'use strict';

	/*

		User Module Event Bus

	*/

	import moment from "moment";
  import AppData from "data-modules/AppData";
	import Eventer from "../Eventer";
  import queryString from 'query-string';
  import { get, post, del, put, patch, getErrorPayload } from "./SystemREST";
  import { user_types, account_statuses, account_permission_types } from "data-modules/Enum"
  var UserData = Eventer.createBus("USR");
  // AppData.injectJWTErrorListener(UserData);

  // Generates colors and styling for list rows
	UserData.listMapping = {
		title : function (user) {
      let userNameString = `${user.last_name || '<Not Set>'}, ${user.first_name || '<Not Set>'}`;
			if(user.type == user_types.DISABLED) { return "[Disabled] - "+ userNameString; }
      if(user.type == user_types.BANNED) { return "[BANNED] - "+ userNameString; }
			return userNameString;
		},
		subtitle : function (user) { return user.username; },
		rowStyle : function (user) {
			if(user.type == user_types.DISABLED) { return { backgroundColor: "rgba(255,0,0,0.2)" }; }
      if(user.type == user_types.BANNED) { return { backgroundColor: "rgba(255, 173,0,1)" }; }
			return {};
		}
	};

  // Controls searchable fields on user management list
	UserData.searchConfig = { options: [
    { value: "full_name", title: "Name" },
    { value: "username", title: "Username" },
		{ value:"id", title : "ID" },
		{ value:"email", title : "Email" }
	]};

	UserData.getRecordList = function (opts) {

    let options = opts || {};
    let queryOptions = {};

    // Apply pagination if exists
    if(options._page && options._pageSize) {
      queryOptions._pageSize = options._pageSize;
      queryOptions._page = options._page;
    }

    // Apply query options if it exists
    if(options.where) { queryOptions = Object.assign(queryOptions, options.where) }

    let requestURL = `/users`;
    if(queryString.stringify(queryOptions).length > 0) { requestURL = `${requestURL}?${queryString.stringify(queryOptions)}`}

    return get(requestURL).send().then(function(res) {
      var payload = res.body;
      UserData.notifyListeners("LIST_LOADED", { records: payload.users, count: payload.count });
      return { records: payload.users, count: payload.count }
    }, function (err) {
      UserData.notifyListeners("LIST_LOAD_ERROR", getErrorPayload(err));
      return err
    });

  };

	UserData.recordSearch = function (key, value) {
    get("/users/search/"+key+"/"+value).send().then(function(res) {
      var payload = res.body;
      UserData.notifyListeners("LIST_LOADED", payload.users);
    }, function (err) {
      UserData.notifyListeners("LIST_LOAD_ERROR", getErrorPayload(err));
    });

  };

	UserData.getRecord = function (record_id) {
    if(record_id == null) { return; }
    return get("/user/"+record_id).send().then(function(res) {
      var payload = res.body;
      UserData.notifyListeners("RECORD_LOADED", payload.user);
      return payload.user;
    }).catch(function (err) {
      UserData.notifyListeners("RECORD_LOAD_ERROR", getErrorPayload(err));
      throw getErrorPayload(err)
    });

  };

	UserData.updateRecord = function (recordData) {
		recordData.full_name = recordData.first_name + " " + recordData.last_name;
    post("/user/update").send({user : recordData}).then(function(res) {
      var payload = res.body;
      UserData.notifyListeners("RECORD_UPDATED", payload.user);
    }, function (err) {
      UserData.notifyListeners("RECORD_UPDATE_ERROR", getErrorPayload(err));
    });
  };

	UserData.createRecord = function (recordData) {
		recordData.full_name = recordData.first_name + " " + recordData.last_name;
    post("/user/create").send({user : recordData}).then(function(res) {
      var payload = res.body;
      UserData.notifyListeners("RECORD_CREATED", payload.user);
    }, function (err) {
      UserData.notifyListeners("RECORD_CREATE_ERROR", getErrorPayload(err));
    });
  };

	UserData.getActiveAccounts = function (user_id) {
		if(user_id == null) {
			UserData.notifyListeners("ACCOUNT_LIST_LOAD_ERROR", "Null User - Unable to Load Accounts");
		}

    get("/user/"+user_id+"/accounts").send().then(function(res) {
      var payload = res.body;
      var today = moment();
      var accounts = payload.accounts.reduce(function (prev, account, index, sourceArray) {
        var include = true;
        if(account.permission_type == account_permission_types.DISABLED)  { include = false; } // Permission Disabled
        if(moment(account.valid_to) < today) { include = false; } // Account Expired
        if(account.status !== account_statuses.ACTIVE) { include = false; } // Account Not Enabled
        if(include) { prev.push(account); }
        return prev;
      }, []);
      UserData.notifyListeners("ACCOUNT_LIST_LOADED", accounts);
    }, function (err) {
      UserData.notifyListeners("ACCOUNT_LIST_LOAD_ERROR", getErrorPayload(err));
    });

  };

	UserData.getOrders = function (user_id, range) {
		if(user_id == null) {
			UserData.notifyListeners("ORDER_LIST_LOAD_ERROR", "Null User - Unable to Load Orders");
		}
		let requestURL = "/user/"+user_id+"/orders";
		if(range) {
			let rangeString = moment(range[0]).format("YYYYMMDD")+":"+moment(range[1]).format("YYYYMMDD")
			requestURL = "/user/"+user_id+"/orders/"+rangeString;
		}

    get(requestURL).send().then(function(res) {
      var payload = res.body;
      UserData.notifyListeners("ORDER_LIST_LOADED", payload.orders);
    }, function (err) {
      UserData.notifyListeners("ORDER_LIST_LOAD_ERROR", getErrorPayload(err));
    });

  };

	UserData.updatePassword = function (user, password) {
    post("/user/password").send({ user : user, new_password : password }).then(function(res) {
        UserData.notifyListeners("PASSWORD_UPDATED", null);
    }, function (err) {
      UserData.notifyListeners("PASSWORD_UPDATE_ERROR", getErrorPayload(err));
    });
  };

	UserData.setDefaultAccount = function (user, new_account) {
    post("/user/"+user.id+"/account/setDefault").send({ user : user, account : new_account }).then(function(res) {
      var payload = res.body;
      UserData.notifyListeners("DEFAULT_ACCOUNT_UPDATED", payload.new_default);
    }, function (err) {
      UserData.notifyListeners("DEFAULT_ACCOUNT_UPDATE_ERROR", getErrorPayload(err));
    });
  };

  UserData.checkUsernameCollision = function (username) {
    return get(`/username_collision/${username}`).send({}).then(function(res) {
      var payload = res.body;
      UserData.notifyListeners("USERNAME_COLLISION_STATUS_UPDATE", payload);
      return payload;
    }, function (err) {
      UserData.notifyListeners("USERNAME_COLLISION_STATUS_ERROR", getErrorPayload(err));
      throw new Error(`Error Checking Username Status: ${JSON.stringify(getErrorPayload(err))}`)
    });
  };

  UserData.getMyInvoices = function (range) {
    let url = "/invoices/mine";
    if(Array.isArray(range) && range.length == 2) {
      let range_start = moment(range[0]);
      let range_end = moment(range[1]);
      url += "?range_start=" + range_start.format("YYYY-MM-DD") + "&range_end=" + range_end.format("YYYY-MM-DD")
    }
    get(url).send().then(function (res) {
      var payload = res.body;
      let results = payload.invoices.map((row) => {
        let dateString = row.invoice_id.split("-")[2];
        row.invoice_date = moment(dateString, "YYYYMMDD")
        return row;
      }).sort((a, b) => {
        return parseInt(b.invoice_id.split("-")[2], 10) - parseInt(a.invoice_id.split("-")[2], 10);
      })
      UserData.notifyListeners("MY_INVOICES_LIST_LOADED", results);
    }, function (err) {
      UserData.notifyListeners("MY_INVOICES_LIST_LOAD_ERROR", getErrorPayload(err));
    });
  }

  UserData.hasServiceTag = function (user_id, service_id, tag_name, tag_value) {
    let queryConfig = { user_id: user_id, service_id: service_id, tag_name: tag_name }
    if(tag_value !== undefined) { queryConfig.tag_value = tag_value; }
    return get('/user_service_tags').query(queryConfig).then((res) => {
      return { hasTag: (res.body?.tags.length > 0) }
    })
  }

  UserData.getServiceTags = function (user_id, page, pageSize, searchKey, searchValue) {
    let queryConfig = { user_id: user_id, _page: page || 1, _pageSize: pageSize || 10 }
		if(searchKey && `${searchValue}`.length > 0) { queryConfig[searchKey] = searchValue; }
		return get(`/user_service_tags`).query(queryConfig).then((res) => {
      UserData.notifyListeners("USER_SERVICE_TAG_LIST_LOADED", { records: res.body?.tags || [], count: res.body.count })
      return { records: res.body?.tags || [], count: res.body.count };
    })
  }

  UserData.upsertServiceTag = function (data) {
    return post('/user_service_tag').send(data).then((res) => {
      let payload = res.body;
      if(payload.mode == "create") {
        UserData.notifyListeners("USER_SERVICE_TAG_CREATED", payload.tag);
      } else {
        UserData.notifyListeners("USER_SERVICE_TAG_UPDATED", payload.tag);
      }
      return payload;
    })
  }

  UserData.deleteServiceTag = function (data) {
    return del('/user_service_tag').query({ service_id: data.service_id, user_id: data.user_id, tag_name: data.tag_name }).then((res) => {
      let payload = res.body;
      UserData.notifyListeners("USER_SERVICE_TAG_DELETED", payload.tag);
      return payload;
    })
  }

  UserData.getProfilePicture = function (user_id) {
      if(user_id == null) {
          UserData.notifyListeners("PROFILE_PIC_LOAD_ERROR", "Null User - Unable to Get Profile Picture");
      }

      get("/user/"+user_id+"/picture").send().then(function(res) {
          let no_profile_pic = res.body?.no_profile_pic;
          UserData.notifyListeners("PROFILE_PIC_LOADED", !no_profile_pic);
          return !no_profile_pic;
      }, function (err) {
          UserData.notifyListeners("PROFILE_PIC_LOAD_ERROR", getErrorPayload(err));
      });

  };

  UserData.updateProfilePicture = function (user_id, profile_pic) {
      if(user_id == null) {
          UserData.notifyListeners("PROFILE_PIC_LOAD_ERROR", "Null User - Unable to Update Profile Picture");
      }

      post("/user/"+user_id+"/picture").attach("file",profile_pic).then(function(res) {
          UserData.notifyListeners("PROFILE_PIC_UPDATED", `Profile picture set to ${profile_pic.name} for userId: ${user_id}`);
      }, function (err) {
          UserData.notifyListeners("PROFILE_PIC_UPDATE_ERROR", getErrorPayload(err));
      });

  };

  UserData.deleteProfilePic = function (user_id) {
    if(user_id == null) {
      UserData.notifyListeners("PROFILE_PIC_LOAD_ERROR", "Null User - Unable to Delete Profile Picture");
    }

    return del("/user/"+user_id+"/picture").then((res) => {
      UserData.notifyListeners("PROFILE_PIC_DELETED", "Profile picture deleted for userID: " + user_id);
    }, function (err) {
      UserData.notifyListeners("PROFILE_PIC_DELETE_ERROR", getErrorPayload(err));
    })
  }

  UserData.getMyPendingCharges = function () {
    return get("/audit/pending").then((results) => {
      UserData.notifyListeners("PENDING_CHARGES_LOADED", results?.body?.orders || [])
    }, function (err) {
      UserData.notifyListeners("PENDING_CHARGES_LOAD_ERROR", getErrorPayload(err))
    })
    
  }

  export default UserData;


'use strict';

import React from "react"
import { Button, InputGroup, Icon, Tag } from "@blueprintjs/core"
import { account_types } from "data-modules/Enum"
import { areRequiredDataFieldsValid, DataInputField } from "generic-components/DataInputField";

/*
  There are a lot of validation checks and UI components that need to be run for the individual lines. 
  This is a line item UI element to standarize that checking.
*/
class OrderLine extends React.Component {
  constructor(props) {
    super(props)

    this.onChange = this.onChange.bind(this)
    this.removeAction = this.removeAction.bind(this)
  }

  onChange(key, evt) {
    var newValue = evt.target.value;
    this.props.onChange(this.props.index, key, newValue);
  }

  removeAction() {
    this.props.onRemove(this.props.index);
  }

  render() {
    var style={
      container : {display : "block", margin : 5, width : "100%" },
      input : { display : "inline-block", minWidth: 150 },
      total : { display : "inline-block", minWidth: 80 },
      inputTitle : { fontWeight : "bold", fontSize : "0.75rem", marginBottom : 5, color: "gray" },
      inputTitleError : { fontWeight : "bold", fontSize : "0.75rem", marginBottom : 5, color : "red" },
    }

    var self = this;
    var type = this.props.type;
    var data = this.props.data;
    var index = this.props.index;
    var account = this.props.account;
    let preset = this.props.preset || {};

    var optionalElements = this.props.optionals.map(function (optional, index) {
      let field_index = optional.data_index;
      return (<div key={"optional_"+self.props.index+"_"+index} style={style.input}>
        {/* <div style={style.inputTitle}> {optional.label} </div> */}
        {/* <textarea style={{ marginRight: 5 }} value={data[optional.key]} placeholder="(Optional)" onChange={self.onChange.bind(null, optional.key)}></textarea> */}
        <DataInputField service={{ [`data_label_${field_index}`] : optional.label }} fieldIndex={field_index} value={data[optional.key] || ""} onChange={self.onChange.bind(null, optional.key)}/>
      </div>);
    });
    let descriptionValid = true;
    let rateValid = true;
    let commercialValid = true;
    let quantityValid = true;
    let rateLowWarning = false;
    let errorMessages = [];

    if(parseFloat(data.quantity) < parseFloat(preset.min_quantity)) { quantityValid = false; errorMessages.push(` Quantities less than ${preset.min_quantity} not allowed.`); }
    if(parseFloat(preset.max_quantity) !== 0 && parseFloat(data.quantity) > parseFloat(preset.max_quantity)) { quantityValid = false; errorMessages.push(` Quantities greater than ${preset.max_quantity} not allowed.`); }

    if(data.commercial_rate == 0) { commercialValid = false; }
    if(data.rate == 0) { rateValid = false; }
    if(data.quantity == 0) { quantityValid = false; }
    if(data.description.length < 1) { descriptionValid = false; }
    if((data.commercial_rate > 0 && data.rate > 0) && (data.commercial_rate < (data.rate * data.oce_multiplier))) { commercialValid = false; rateLowWarning = true; }
    /* The line type is based on the form type and a specification of the line. All data comes from a line_preset object
      Type 0 : Manager only freeform field
      Type 1 : User editable quantity field
      Type 2 : Un-editable fee field
    */
    switch(type) {
      case 0 : {
        return (
          <React.Fragment>
            <tr>
              <td>
                <InputGroup style={{ marginRight: 5 }} rightElement={(descriptionValid ? "" : <Icon style={{ margin: 7 }} icon="error" intent="danger"/>)} type="text" value={data.description} onChange={this.onChange.bind(null, 'description')} placeholder="Item Description"/>
              </td>
              <td>
                <InputGroup style={{ marginRight: 5 }} rightElement={(quantityValid ? "" : <Icon style={{ margin: 7 }} icon="error" intent="danger"/>)} type="text" value={data.quantity} onChange={this.onChange.bind(null, 'quantity')}/>
              </td>
              <td style={{ display: "flex", gap: 10, flexDirection: "column" }}>
                <InputGroup leftElement={ <Button minimal={true} intent="success" alignText="right" style={{ width: 150 }}>Internal Rate $ </Button>} rightElement={(rateValid ? "" : <Icon style={{ margin: 7 }} icon="error" intent="danger"/>)} style={{ marginRight: 5 }} type="text" value={data.rate} onChange={this.onChange.bind(null, 'rate')}/>
                <InputGroup leftElement={ <Button minimal={true} intent="primary" alignText="right" style={{ width: 150 }} onClick={() => this.onChange('commercial_rate', { target: { value:  data.rate * data.oce_multiplier } })}>Commercial Rate $ </Button>}  rightElement={(commercialValid ? "" : <Button icon="error" intent="danger" onClick={() => this.onChange('commercial_rate', { target: { value:  data.rate * data.oce_multiplier } })}>{`(Min: ${(data.rate * data.oce_multiplier).toLocaleString("en-us", { style: "currency", currency: "USD",  minimumFractionDigits: 2 })})`}</Button>)} style={{ marginRight: 5 }} type="text" value={data.commercial_rate} onChange={this.onChange.bind(null, 'commercial_rate')}/>
              </td>
              <td>
                <span style={{ color: "green" }}> ${(account && account.type == 2) ? `${(data.commercial_rate * data.quantity).toFixed(2)}` : (data.rate * data.quantity).toFixed(2)} </span>
                <Button key={"remove_index_"+index} icon="cross-circle" style={{ marginLeft : 20 }} intent="danger" onClick={this.removeAction}/>
              </td>
            </tr>
            {(optionalElements.length > 0 ? <tr>
              <td style={{ padding: 6, color: "gray", textAlign: "right" }}>Requested Data Fields</td>
              <td colSpan={4}>
                <div style={{ display: "flex", gap: 10, flexDirection: "row"}}>
                  {optionalElements}
                </div>
              </td>
            </tr> : "")}
          </React.Fragment>
        );
      } break;
      case 1 : {
        console.log(data);
        let rateElement = <span>${parseFloat(data.rate).toFixed(2)}</span>
        if(account && account.type == account_types.COMMERCIAL ) { rateElement = <span>${parseFloat(data.commercial_rate).toFixed(2)}</span>}
        return (<React.Fragment>
          <tr>
            <td>
              {data.description}
              {(descriptionValid ? "" : <Icon icon="error" intent="danger"/>)}
            </td>
            <td>
              <InputGroup style={{ marginRight: 5 }} type="text" value={data.quantity} rightElement={(quantityValid ? "" : <Icon style={{ margin: 7 }} icon="error" intent="danger"/>)} leftElement={(<Button intent="none" disabled={true} minimal={true}>{`[${preset?.min_quantity || "any"}-${(preset?.max_quantity || "any" )}]` }</Button>)} onChange={this.onChange.bind(null, 'quantity')}/>
            </td>
            <td>
              {rateElement}
              {(rateValid ? "" : <Icon icon="error" intent="danger"/>)}
            </td>
            <td colSpan={2}>
              <span style={{ color: "green" }}> ${(account && account.type == 2) ? `${(data.commercial_rate * data.quantity).toFixed(2)}` : (data.rate * data.quantity).toFixed(2)} </span>
            </td>
          </tr>
          {(optionalElements.length > 0 ? <tr>
            <td style={{ padding: 6, color: "gray", textAlign: "right" }}>Requsted Data Fields</td>
            <td colSpan={4}>
              <div style={{ display: "flex", gap: 10, flexDirection: "row"}}>
                {optionalElements}
              </div>
            </td>
          </tr> : "")}
        </React.Fragment>)
      } break;
      case 2 : {
        let rateElement = <span>${parseFloat(data.rate).toFixed(2)}</span>
        if(account && account.type == account_types.COMMERCIAL ) { rateElement = <span>${parseFloat(data.commercial_rate).toFixed(2)}</span>}
        return (<React.Fragment>
          <tr>
            <td>
              {data.description}
              {(descriptionValid ? "" : <Icon icon="error" intent="danger"/>)}
            </td>
            <td>
              {data.quantity}
              {(quantityValid ? "" : <Icon icon="error" intent="danger"/>)}
            </td>
            <td>
              {rateElement}
              {(rateValid ? "" : <Icon icon="error" intent="danger"/>)}
            </td>
            <td colSpan={2}>
              <span style={{ color: "green" }}> ${(account && account.type == 2) ? `${(data.commercial_rate * data.quantity).toFixed(2)}` : (data.rate * data.quantity).toFixed(2)} </span>
            </td>
          </tr>
        </React.Fragment>)
      } break;
      default : {
        return (<div style={style.container}> Invalid Rate/Row Type </div>)
      } break;
    }
  }
}

export default OrderLine;

import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

export default function withRouter(Component) {
    function ComponentWithRouterProp(props) {
      let [searchParams, setSearchParams] = useSearchParams()
      return (
        <Component
          {...props}
          params={useParams()}
          location={useLocation()}
          navigate={useNavigate()}
          query={searchParams}
        />
      );
    }
  
    return ComponentWithRouterProp;
  }
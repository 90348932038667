
	'use strict';

	/*

    Site Dialog Event Bus. Handles communication from individual modules to the master site dialog element.

	*/
	import Eventer from "../Eventer";
	var SiteDialogData = Eventer.createBus("SDD");

  var isOpen = false;
  var title = "";
  var message = "";
	var closeAction = null;
	var actionButtons = [];


  SiteDialogData.isOpen = function () {
    return isOpen;
  };

  SiteDialogData.title = function () {
    return title;
  };

  SiteDialogData.message = function () {
    return message;
  };

  SiteDialogData.actionButtons = function () {
    return actionButtons;
  }

  SiteDialogData.open = function (newTitle, newMessage, actionConfig) {
    isOpen = true;
    title = newTitle;
    message = newMessage;
    closeAction = null;
		if(typeof actionConfig == "function") { closeAction = actionConfig; }
    if(Array.isArray(actionConfig)) { actionButtons = actionConfig }
    if(typeof actionConfig == "object") {
      if(typeof actionConfig.onClose == "function") { closeAction = actionConfig.onClose; }
      if(Array.isArray(actionConfig.actions)) { actionButtons = actionConfig.actions; } 
    }
    SiteDialogData.notifyListeners("DIALOG_OPENED", { "title" : title, "message" : message });
  };

  SiteDialogData.close = function () {
    isOpen = false;
    SiteDialogData.notifyListeners("DIALOG_CLOSED", { "title" : title, "message" : message });
		if(closeAction !== null) {
			closeAction();
			closeAction = null;
		}
  };

  SiteDialogData.clear = function () {
    isOpen = false;
    title = "";
    message = "";
		closeAction = null;
    SiteDialogData.notifyListeners("DIALOG_CLEARED", { "title" : title, "message" : message });
  };

  export default SiteDialogData;

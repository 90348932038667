
'use strict';

import React from "react";

import GroupButton from "./GroupButton";

import { Icon } from "@blueprintjs/core";

const OrgContainer = ({ unit, getIsManager }) => {

    let unit_name = unit.name;
    let groups = unit.groups;
    let color = unit.color;
    let groupButtons = [];

    const style = {
      container : {
        // border : "1px solid black",
        // backgroundColor : "gray",
        padding : 20,
        margin : 5,
        borderRadius : 10,
      },
      title : {
        fontSize : "1.25rem",
        fontWeight: "bold",
        paddingBottom: 10,
        marginBottom: 10,
        color: "rgb(112, 142, 153)",
        borderBottom: `2px solid #BE0000`,
        textTransform: "uppercase",
        display: "flex",
        alignItems: "center",
        gap: "10px",
        fontFamily: "Montserrat, sans-serif",
    
      },
      group_list : {
        display : "flex",
        gap: "10px",
        // width : "100%",
        flexBasis: "100px",
        flexWrap: "wrap",
        justifyContent: "center"
      }
    };

    groups.sort(function (a, b) {
      if(a.name > b.name) { return 1; }
      if(a.name < b.name) { return -1; }
      if(a.name == b.name) { return 0; }
    });

    groups.map(function (group) {
      if(group.status > 0) {
        const isManager = getIsManager(group.id);
        if(group.status == 2 && !isManager) { return; }
        groupButtons.push(<GroupButton group={group} color={color} key={group.id}/>);
      }
    });

    return ( <div style={style.container}>
      <div style={style.title}>
        <Icon icon={"office"} style={{ color: color }} size={24}/> {unit_name}
      </div>
      <div style={style.group_list}>
        {groupButtons}
      </div>
    </div> );
}

export default OrgContainer;

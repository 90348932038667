import React from "react"
import { ChunkLoadError } from "react";
import { NonIdealState, Callout, Button, FormGroup } from "@blueprintjs/core"
import MessageData from "data-modules/MessageData";
import AppData from "data-modules/AppData";

export default class ErrorBoundary extends React.Component {
    constructor(props) { super(props); this.state = { hasError: false, hasSentReport: false, userExplaination: "" }; }
    static getDerivedStateFromError(error) { return { hasError: true }; }
    componentDidCatch(error, errorInfo) { 
        this.setState({ error: error, errorInfo: errorInfo, hasSentReport: false, userExplaination: "" }); 
        console.error(error, errorInfo); 
        if(error.name === "ChunkLoadError") {
            alert("There has been an update to the site since you last loaded this module. Reloading to fetch most recent version.")
            window.location.reload(false)
        } 
    }

    sendBugReport() {
        try { 
            MessageData.sendErrorReport({ error: this.state.error.toString(), errorInfo: this.state.errorInfo, trace: this.state.error.stack, url: `${window.location.toString()}`, userExplaination: this.state.userExplaination });
            this.setState({ hasSentReport: true }); 
            setTimeout(() => { 
                this.setState({ hasSentReport: false, userExplaination: ""
            }); }, 10000);
        } catch (err) { console.error(err); }
    }


    render() { if (this.state.hasError) { return <div style={{ background: "white", padding: 5, borderRadius: 5 }}> <Callout><NonIdealState icon="error" title={"Component Error"} description={
        <div>
            The following error occured in this component or it's children ({window.location.toString()}):
            <Callout style={{ marginTop: 10, marginBottom: 10 }} intent="danger">{this.state.error?.message || "Unknown"}</Callout>
            <Callout style={{ marginTop: 10, marginBottom: 10 }} intent="info">
                <FormGroup label="Steps to Reproduce" helperText="Please briefly describe what you were trying to do when this error occured">
                    <textarea style={{ width: "100%" }} value={this.state.userExplaination} onChange={(e) => { this.setState({ userExplaination: e.target.value }); }}></textarea>
                </FormGroup>
            </Callout>
            <Button onClick={this.sendBugReport.bind(this)} intent={(this.state.hasSentReport ? "success" : "warning")} disabled={this.state.hasSentReport}>{(this.state.hasSentReport ? "Report Sent" : "Send Bug Report")}</Button>
            {(this.state.hasSentReport ? <Callout style={{ marginTop: 10 }} intent="success">
                Your bug report has been sent. Thanks for helping us improve this system. You may now close this page or <Button onClick={() => { window.location.href = `/#/`; window.location.reload(false); }}> return to the homepage</Button>.
            </Callout> : <></>)}
            {( this.props.module !== undefined ? <Callout style={{ marginTop: 10, marginBottom: 10 }} intent="info">{this.props?.module || "Unknown Module"}</Callout> : "")}
        </div>
    }/></Callout></div>; } return this.props.children; } 
}
class DebounceManager {
    constructor() {
        this.debounceTimers = {}
        this.add = this.add.bind(this);
        this.clear = this.clear.bind(this);
    }

    add(key, duration, callback) {
        if(this.debounceTimers[key] !== "undefined") { clearTimeout(this.debounceTimers[key]); }
        this.debounceTimers[key] = setTimeout(() => { 
            callback(); 
            clearTimeout(this.debounceTimers[key]);
            delete this.debounceTimers[key];
        }, duration);
    }

    clear(key) {
        if(this.debounceTimers[key] !== "undefined") { clearTimeout(this.debounceTimers[key]); }
    }
}

export default new DebounceManager()
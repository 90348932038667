
'use strict';

import React from 'react';
import AppData from 'data-modules/AppData';
import UserData from 'data-modules/UserData';
import ServiceData from 'data-modules/ServiceData';
import MessageData from 'data-modules/MessageData';
import MetricsData from "data-modules/MetricsData";
import GroupPermissionData from 'data-modules/GroupPermissionData';
import DataManager from "data-modules/DataManager";
import SecureForm from "generic-components/SecureForm";

let DM = new DataManager();
import { Classes, FormGroup, InputGroup, TextArea, Tag, Button, Radio, RadioGroup, Callout, NonIdealState, Tabs, Tab, Intent, ControlGroup, HTMLSelect, AnchorButton, FileInput } from "@blueprintjs/core"
import { DateRangeInput } from "@blueprintjs/datetime"
import moment from "moment"
import QueryString from "query-string"
import { permission_types } from 'data-modules/Enum';

class MessagingPage extends React.Component {
    constructor(props) {
        super(props)
        let now = moment();
        this.state = {
            targets: [],
            subject: "",
            message: "",
            send_mode: "system",
            range: [moment(now).subtract(1, "month"), now],
            group_filter_value: "",
            service_filter_value: "",
            availableGroups: [],
            availableServices: [],
            user_search_value: "",
            userSearchResults: [],
            active_data_tab: "email",
            messageTemplates: [],
            messageTemplateInteractionMode: "add",
            recipient_list: [],
            pendingAttachments: []
        };

        this.sendBroadcast = this.sendBroadcast.bind(this)
        this.messageSent = this.messageSent.bind(this);
        this.messageSendError = this.messageSendError.bind(this);
        this.updateRange = this.updateRange.bind(this)
        this.componentDidMount = this.componentDidMount.bind(this)
        this.addTarget = this.addTarget.bind(this)
        this.removeTarget = this.removeTarget.bind(this);
        this.groupsLoaded = this.groupsLoaded.bind(this);
        this.servicesLoaded = this.servicesLoaded.bind(this);
        this.errorHandler = this.errorHandler.bind(this);
        this.updateUserSearchValue = this.updateUserSearchValue.bind(this);
        this.usersLoaded = this.usersLoaded.bind(this);
        this.clearForm = this.clearForm.bind(this);
        this.updateActiveDataTab = this.updateActiveDataTab.bind(this);
        this.loadMessageTemplates = this.loadMessageTemplates.bind(this);
        this.getRecipentList = this.getRecipentList.bind(this);
    }

    componentDidMount() {
        let self = this;
        let now = moment();
        let range = [moment(now).subtract(1, "month"), now];
        self.setState({ range: range });

        DM.add(GroupPermissionData.registerListener("LIST_LOADED", this.groupsLoaded), GroupPermissionData);
        DM.add(GroupPermissionData.registerListener("LIST_LOAD_ERROR", this.errorHandler), GroupPermissionData);
        DM.add(ServiceData.registerListener("LIST_LOADED", this.servicesLoaded), ServiceData);
        DM.add(ServiceData.registerListener("LIST_LOAD_ERROR", this.errorHandler), ServiceData);
        DM.add(UserData.registerListener("LIST_LOADED", this.usersLoaded), UserData);
        DM.add(UserData.registerListener("LIST_LOAD_ERROR", this.errorHandler), UserData);
        DM.add(MessageData.registerListener("MESSAGES_SENT", this.messageSent), MessageData);
        DM.add(MessageData.registerListener("MESSAGE_SEND_ERROR", this.messageSendError), MessageData);
        GroupPermissionData.myGroups()
        ServiceData.getMyServices()
        MetricsData.recordClientMetric({
            metric: "component-mount",
            feature: "message-module",
            numerical_value: 1,
            precision: 0,
        })
        this.loadMessageTemplates();
        
        
    }

    componentWillUnmount() {
        DM.clear();
    }

    loadMessageTemplates() {
        MessageData.getTemplates().then((templates) => {
            this.setState({ messageTemplates: templates })
        })
    }

    messageSent(results) {
        let self = this;
        let failureRows = results.fail.map((r) => {return `${r.target}`}).join("\n");
        let failureString = `${(results.fail.length > 0 ? "\n\n---- Sending Failed ----\n\n " : "")} ${(results.fail.length > 0 ? failureRows : "")}`
        let resultsString = `---- Successful Sends ----\n\n Message Count: ${results.success.length} ${failureString}`;
        alert(resultsString);
        self.clearForm()
    }

    messageSendError(err) {
        alert(`Messages failed to send. Please check the developer console for more information.\n\n ---- Error Message ----\n\n ${err.message}`);
        console.log(err);
    }

    errorHandler(err) {
        console.log(err)
    }

    usersLoaded(users) {
        let self = this;
        self.setState({ userSearchResults: users });
    }

    servicesLoaded(services) {
        let self = this;
        let targets = self.state.targets;
        let query = QueryString.parse(self.props.location.search);
        let preload_service = services.reduce((acc, row, _index) => {
            let exists = targets.reduce((e, t, _i) => {
                if (t.target == `service:${query.service}`) { return true;  }
                return e;
            }, false);
            if(row.id == query.service && !exists) {
                return row;
            }
            return acc;
        }, null)
        if(preload_service !== null) {
            const service_value = `service:${preload_service.id}`
            const service_label = `s:${preload_service.name}`
            targets.push({ target: service_value, label: service_label })
        }
        self.setState({ availableServices: services, targets: targets });
    }

    groupsLoaded(permissions) {
        let self = this;
        let groups = permissions.filter((permission) => {
            return permission.type >= permission_types.MANAGER;
        }).map((permission) => {
            return permission.group;
        })
        self.setState({ availableGroups: groups });
        
    }

    updateUserSearchValue(event) {
        let self = this;
        let new_value = event.target.value;
        if (new_value.length < 3) {
            self.setState({ userSearchResults: [], user_search_value: new_value });
        } else {
            self.setState({ user_search_value: new_value }, () => {
                UserData.recordSearch("full_name", new_value);
            })
            
            
        }
    }

    addTarget(target, label, postAction) {
        let self = this;
        let targets = self.state.targets;
        let exists = targets.reduce((acc, tgt) => {
            if(tgt.target == target) { return true; }
            return acc
        }, false)
        if(!exists) { targets.push({ target: target, label: label }) }
        self.setState({ targets: targets, recipient_list: [] });
        if(typeof postAction == "function") { postAction(); }
    }

    removeTarget(index) {
        let self = this;
        let targets = self.state.targets;
        targets.splice(index, 1)
        self.setState({ targets: targets, recipient_list: [] });
    }
   
    updateRange(new_range) {
        let self = this;
        let range = ((nr) => {
            let range_start = self.state.range[0];
            let range_end = self.state.range[1];
            if (nr[0] !== null) { range_start = nr[0] }
            if (nr[1] !== null) { range_end = nr[1] }
            if (moment(range_start).isAfter(moment(range_end))) {
                return [moment(range_end), moment(range_start)];
            } else {
                return [moment(range_start), moment(range_end)];
            }

        })(new_range);
        self.setState({ range: range, recipient_list: [] });
    }

    sendBroadcast() {
        let self = this;
        let isValid = true;
        let reasons = [];
        let subject = self.state.subject;
        let message = (() => {
            let messageString = self.state.message;
            return messageString;
        })()
        let targets = self.state.targets.map((t) => { return t.target; });
        let range = self.state.range;
        if (message.length < 10) { isValid = false; reasons.push("Message too short (less than 10 characters)") }
        if (subject.length < 5) { isValid = false; reasons.push("Subject too short (less than 5 characters)") }
        if (targets.length < 1) { isValid = false; reasons.push("Too few recipients (less than 1)") }
        if(!isValid) { alert(`Message Configuration Invalid : ${reasons.join(",")}`); return; }
        if (confirm("Are you sure you want to send this message to all the selected targets?")) {
            MessageData.sendBroadcast(targets, subject, message, range, self.state.send_mode, self.state.pendingAttachments);
        }
    }

    clearForm() {
        let self = this;
        let now = moment();
        self.setState({
            targets: [],
            subject: "",
            message: "",
            send_mode: "system",
            range: [moment(now).subtract(1, "month"), now],
            group_filter_value: "",
            service_filter_value: "",
            user_search_value: "",
            userSearchResults: [],
            pendingAttachments: []
        });
    }

    updateActiveDataTab(newTab) {
        this.setState({ active_data_tab: newTab });
    }

    getRecipentList() {
        let self = this;
        let targets = self.state.targets.map((t) => { return t.target; })
        let range = self.state.range;
        MessageData.getRecipientList(targets, range).then((results) => {
            self.setState({ recipient_list: results })
        })
    }

    render() {
        let self = this;
        
        let range = [self.state.range[0].toDate(), self.state.range[1].toDate()]

        let hasNonUserTarget = false;
        let targetTags = self.state.targets.map((target, index) => {
            let labelComponents = target.label.split(":");
            let intent = Intent.NONE;
            let recipientTagTitle = labelComponents[1];
            if(labelComponents[0] == "g") { hasNonUserTarget = true; recipientTagTitle = `Users of ${recipientTagTitle}`; intent = Intent.DANGER; }
            if(labelComponents[0] == "s") { hasNonUserTarget = true; recipientTagTitle = `Users of ${recipientTagTitle}`; intent = Intent.WARNING; }
            if(labelComponents[0] == "p") { hasNonUserTarget = true; recipientTagTitle = `Account PIs of ${recipientTagTitle}`; intent = Intent.SUCCESS; }
            if(labelComponents[0] == "c") { hasNonUserTarget = true; recipientTagTitle = `Account Contacts of ${recipientTagTitle}`; intent = Intent.NONE; }
            if(labelComponents[0] == "u") { intent = Intent.PRIMARY; }
            return (<Tag key={`recipient_tag_${index}`} style={{ marginRight: 5, marginBottom: 10 }} removable="true" intent={intent} onRemove={self.removeTarget.bind(null, index)}> {recipientTagTitle} </Tag>)
        });

        let groupAddButtons = self.state.availableGroups.filter((group) => {
            return group.name.toLowerCase().includes(self.state.group_filter_value.toLowerCase())
        }).map((group) => {
            return (<div key={`group_select_${group.id}`} style={{ paddingRight: 5, paddingBottom: 10 }}> 
                <ControlGroup>
                    <Button style={{ width: "50%"}} text={group.name} intent="none"/> 
                    <Button style={{ width: "18%"}} text={`Users`} intent="primary" onClick={self.addTarget.bind(null, `group:${group.id}`, `g:${group.name}`)}/> 
                    <Button style={{ width: "10%"}} text={"PIs"} intent="success" onClick={self.addTarget.bind(null, `pis:${group.id}`, `p:${group.name}`)}/> 
                    <Button style={{ width: "16%"}} text={"Admins"} intent="warning" onClick={self.addTarget.bind(null, `contacts:${group.id}`, `c:${group.name}`)}/> 
                </ControlGroup>
            </div>)
        });

        let serviceAddButtons = self.state.availableServices.filter((service) => {
            return service.name.toLowerCase().includes(self.state.service_filter_value.toLowerCase())
        }).sort((a,b) => { return `${a.name}`.localeCompare(`${b.name}`)}).map((service) => {
            return (<div key={`service_select_${service.id}`} style={{ paddingRight: 5, paddingBottom: 10 }}> <Button style={{ width: "100%" }} text={service.name} intent="warning" onClick={self.addTarget.bind(null, `service:${service.id}`, `s:${service.name}`)} /> </div>)
        });

        let userAddButtons = self.state.userSearchResults.map((user) => {
            return (<div key={`user_select_${user.id}`} style={{ paddingRight: 5, paddingBottom: 10 }}> <Button style={{ width: "100%" }} text={`(${user.id}) ${user.full_name}`} intent="primary" onClick={self.addTarget.bind(null, `user:${user.id}`, `u:(${user.id}) ${user.full_name}`)} /> </div>)
        });

        return (<div style={{ width: "100%", paddingLeft: "10%", paddingRight: "10%", paddingTop: 20 }}> 
        <h2 className={Classes.HEADING}> Messaging System </h2>
        <Callout style={{ marginTop: 5, marginBottom: 5 }}>
            <h3 className={Classes.HEADING}> Recipients </h3>
            <div>
                {(targetTags.length > 0 ? targetTags : <NonIdealState title="No recipients selected" description="Please select target recipients."/>)}
            </div>
        </Callout>
        {( hasNonUserTarget ? <Callout style={{ marginTop: 5, marginBottom: 5 }}>
            <h3 className={Classes.HEADING}> Select service/group usage date range </h3>
            <DateRangeInput value={range}
            formatDate={(date, locale) => { return moment(date).format("YYYY-MM-DD")}}
            parseDate={(date, locale) => { return moment(date).toDate(); }} 
            onChange={self.updateRange}/>
            <div> All users of the above services or groups who ordered in the specified date range will be added as recipients </div>
        </Callout> : <div></div>)}
        <Callout style={{ marginTop: 5, marginBottom: 5 }}>
            <div style={{ display: "flex", width: "100%", flexDirection: "row", gap: 5 }}>
                <div style={{ flexBasis: "33%" }}> 
                    <h3 className={Classes.HEADING}> User Picker </h3>
                    <InputGroup style={{ width: "100%", marginBottom: 10 }} placeholder="Search for User..." type="text" onChange={self.updateUserSearchValue} value={self.state.user_search_value} leftIcon="filter" />
                    <div style={{ width: "100%", overflowY: "scroll", height: 200 }}>
                        {userAddButtons}
                    </div>
                </div>
                <div style={{ flexBasis: "33%" }}> 
                    <h3 className={Classes.HEADING}> Service Picker </h3>
                    <InputGroup style={{ width: "100%", marginBottom: 10 }} placeholder="Filter for value..." type="text" onChange={(event) => { self.setState({ service_filter_value: event.target.value }) }} value={self.state.service_filter_value} leftIcon="filter" />
                    <div style={{ width: "100%", overflowY: "scroll", height: 200 }}>
                        {serviceAddButtons}
                    </div>
                </div>
                <div style={{ flexBasis: "33%" }}> 
                    <h3 className={Classes.HEADING}> Group Picker </h3>
                    <InputGroup style={{ width: "100%", marginBottom: 10 }} placeholder="Filter for value..." type="text" onChange={(event) => { self.setState({ group_filter_value: event.target.value }) }} value={self.state.group_filter_value} leftIcon="filter" />
                    <div style={{ width: "100%", overflowY: "scroll", height: 200 }}>
                        {groupAddButtons}
                    </div>
                </div>
            </div>
        </Callout>
        <Callout style={{ marginTop: 5, marginBottom: 5 }}>
            <Tabs onChange={this.updateActiveDataTab} selectedTabId={self.state.active_data_tab}>
                <Tab id="email" title="Send Email" panel={
                    <div>
                        <FormGroup label="Send As" labelInfo="(Required)" helperText="Who is sending this email?">
                            <RadioGroup inline={true} onChange={((e) => { self.setState({ send_mode: e.target.value }); })} selectedValue={self.state.send_mode}>
                                <Radio label="System Contact Email" value="system"/>
                                <Radio label="My Email" value="me" />
                            </RadioGroup>
                        </FormGroup>
                        <FormGroup label={"Message Subject"} helperText={"What is this about?"} labelInfo={"(Required)"}>
                            <InputGroup type="text" value={self.state.subject} onChange={(event) => { self.setState({ subject: event.target.value }) }} />
                        </FormGroup>
                        <FormGroup label="Message Templates">
                            <ControlGroup>
                                <HTMLSelect value="*" onChange={(evt) => {
                                    if(evt.target.value == "+") { 
                                        let template_name = prompt("Please enter a name for this template");
                                        MessageData.createTemplate({
                                            template_name: template_name,
                                            template_content: this.state.message,
                                            tags: []
                                        }).then(this.loadMessageTemplates);
                                        return;
                                    }

                                    if(this.state.messageTemplateInteractionMode == "delete") {
                                        console.log("Deleting template: " + evt.target.value);
                                        MessageData.deleteTemplate(this.state.messageTemplates[evt.target.value]).then(this.loadMessageTemplates);
                                        return;
                                    }

                                    self.setState({ message: self.state.messageTemplates[evt.target.value]["template_content"] })
                                }}>
                                    <option value="*" disabled={true}> -- Select a Template -- </option>
                                    <option value="+"> -- Add Current as New Template -- </option>
                                    {this.state.messageTemplates.map((template, index) => {
                                        return (<option key={`template_${index}`} value={index}> {template.template_name} </option>)
                                    })}
                                </HTMLSelect>
                                <Button 
                                    intent={(this.state.messageTemplateInteractionMode == "delete" ? "danger" : "primary")} 
                                    icon={(this.state.messageTemplateInteractionMode == "delete" ? "cross" : "edit")}
                                    onClick={() => { 
                                        self.setState({ messageTemplateInteractionMode: (this.state.messageTemplateInteractionMode == "delete" ? "add" : "delete") }) 
                                    }}
                                    text={(this.state.messageTemplateInteractionMode == "delete"? "Delete Template" : "Insert Existing Template")}
                                />
                            </ControlGroup>
                        </FormGroup>
                        <FormGroup label={"Message Body"} helperText={"Content Here"} labelInfo={"(Required)"}>
                            <TextArea autoResize={true} style={{ width: "100%" }} value={self.state.message} onChange={(event) => { self.setState({ message: event.target.value }) }} />
                        </FormGroup>
                        <FormGroup label={"Attachments"} helperText={self.state.pendingAttachments.map(f => f.name).join(", ")} labelInfo={"(Optional - 10 File Max; 1MB Max Per File)"}>
                            <FileInput onInputChange={(evt) => {
                                if(evt.target.files.length > 10) { 
                                    alert("You can only attach up to 10 files at a time.");
                                    return;
                                }
                                self.setState({ pendingAttachments: Array.from(evt.target.files) })
                            }} inputProps={{ multiple: true, onClick: (e) => { e.target.value = null; } }} text="Upload File(s)"/>
                        </FormGroup>
                        <div>
                            <Button style={{ marginRight: 5 }} intent="primary" label="Send Broadcast" onClick={self.sendBroadcast}> Send Broadcast </Button>
                            <Button style={{ marginRight: 5 }} intent="warning" label="Clear Form" onClick={self.clearForm}> Clear Form </Button>
                        </div>
                    </div>
                }/>
                <Tab id="download" title="Download List" panel={
                    <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                        <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
                            <Button style={{ marginRight: 5 }} intent="primary" label="Preview Selected Recipients" onClick={self.getRecipentList}>Preview Selected Recipients</Button>
                            <SecureForm action="/message/recipients" method="post" token={AppData.get("authToken")}>
                                <input type="hidden" name="targets" id="targets" value={self.state.targets.map((t) => { return t.target;  }).join(",")}/>
                                <input type="hidden" name="range_start" id="range_start" value={moment(self.state.range[0]).format("YYYY-MM-DD HH:mm:ss")}/>
                                <input type="hidden" name="range_end" id="range_end" value={moment(self.state.range[1]).format("YYYY-MM-DD HH:mm:ss")}/>
                                <Button type="submit" intent={Intent.PRIMARY} disabled={(this.state.recipient_list.length < 1)}> Download Selected Recipients as CSV </Button>
                            </SecureForm>
                            <AnchorButton style={{ marginRight: 5 }} intent="warning" disabled={(this.state.recipient_list < 1)} href={`mailto:?to=&bcc=${this.state.recipient_list.sort((a, b) => { return a.full_name.localeCompare(b.full_name); }).map((r, index) => {
                                return `${r.email}`.trim();
                            }).join(",")}`} target="_blank">Send Using Mail Client</AnchorButton>
                            <Button intent="danger" disabled={(this.state.recipient_list < 1)} onClick={() => { self.setState({ recipient_list: [] }); }}> Clear Recipient Preview </Button>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", gap: 5, flexWrap: "wrap" }}>
                            {this.state.recipient_list.sort((a, b) => { return a.full_name.localeCompare(b.full_name); }).map((r, index) => {
                                return <Tag key={`recipient_${index}`}>{r.full_name} - {r.email}</Tag>
                            })}
                        </div>
                        
                    </div>                    
                }/>
            </Tabs>
        </Callout>
        
        
        </div>);
    }
}

import withRouter from "lib/withRouter";
let Page = withRouter(MessagingPage);
export default Page;

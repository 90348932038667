
	'use strict';

	/*

		ServicePreset Data Store

	*/

	import Eventer from "../Eventer";
  import { get, post, del, put, patch, getErrorPayload } from "./SystemREST";
  var ServicePresetData = Eventer.createBus("SERVPRESET");
  // AppData.injectJWTErrorListener(ServicePresetData);

	ServicePresetData.getRecordList = function (service_id) {
    return get("/service/"+service_id+"/presets").send().then(function(res) {
      var payload = res.body;
      ServicePresetData.notifyListeners("LIST_LOADED", payload.presets);
      return payload.presets;
    }, function (err) {
      ServicePresetData.notifyListeners("LIST_LOAD_ERROR", getErrorPayload(err));
    });
  };

	ServicePresetData.updateRecord = function (service_id, preset) {
    post("/service/"+service_id+"/preset/update").send({preset : preset}).then(function(res) {
      var payload = res.body;
      ServicePresetData.notifyListeners("RECORD_UPDATED", payload.preset);
    }, function (err) {
      ServicePresetData.notifyListeners("RECORD_UPDATE_ERROR", getErrorPayload(err));
    });
  };

	ServicePresetData.createRecord = function (service_id, preset_type) {
    post("/service/"+service_id+"/preset/add").send({preset_type : preset_type}).then(function(res) {
      var payload = res.body;
      ServicePresetData.notifyListeners("RECORD_CREATED", payload.preset);
    }, function (err) {
      ServicePresetData.notifyListeners("RECORD_CREATE_ERROR", getErrorPayload(err));
    });
  };

	ServicePresetData.removeRecord = function (service_id, preset) {
    post("/service/"+service_id+"/preset/remove").send({preset : preset}).then(function(res) {
      var payload = res.body;
      ServicePresetData.notifyListeners("RECORD_REMOVED", payload.preset);
    }, function (err) {
      ServicePresetData.notifyListeners("RECORD_REMOVE_ERROR", getErrorPayload(err));
    });
  };

  export default ServicePresetData;


import React from 'react';
import AppData from 'data-modules/AppData';
import UserData from 'data-modules/UserData';
import { Dialog, H1, Classes } from "@blueprintjs/core";
import { DatePicker } from "@blueprintjs/datetime";
import EventData from "data-modules/EventData";
import MetricsData from "data-modules/MetricsData";
import ServiceRateData from "data-modules/ServiceRateData";
import DataManager from "data-modules/DataManager";
let DM = new DataManager();
import QueryString from "query-string";

import FullCalendar from '@fullcalendar/react';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import moment from "moment-timezone";
import "../common/calendar.css";
import EventDetailForm from "../common/eventDetailForm";
import { service_types } from "data-modules/Enum"

class ServiceCalendarPage extends React.Component {
    constructor(props) {
        super(props)
        
        this.calendarRef = React.createRef()
        this.state = {
            current_date: moment(),
            resource: { name : "Loading"},
            events: [],
            group: { name: "Loading" },
            activeView: "timeGridWeek",
            slot_duration: "00:30:00",
            actionInProgress: false,
            isCreating: false,
            isEditing: false,
            isError: false,
            isPickingDate: false
        }

        this.componentDidMount = this.componentDidMount.bind(this)
        this.eventsLoaded = this.eventsLoaded.bind(this);
        this.eventCreated = this.eventCreated.bind(this);
        this.eventUpdated = this.eventUpdated.bind(this);
        this.eventDeleted = this.eventDeleted.bind(this);

        this.fetchEvents = this.fetchEvents.bind(this);
        this.onEventDragEnd = this.onEventDragEnd.bind(this);

        this.eventDeleted = this.eventDeleted.bind(this);
        this.dateClicked = this.dateClicked.bind(this);
        this.eventClicked = this.eventClicked.bind(this);
        this.onCreate = this.onCreate.bind(this);
        this.onUpdate = this.onUpdate.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onError = this.onError.bind(this);

        this.reloadInterval = null;
    }

    fetchEvents(new_current) {
        let self = this;
        let params = this.props.params;
        if(new_current) {
            EventData.eventsForService(params.service_id, {
                start: moment(new_current).add(-2, "week"),
                end: moment(new_current).add(2, "week"),
                current: moment(new_current)
            }).catch((err) => {});
            this.setState({ current_date: moment(new_current) })
        } else {
            EventData.eventsForService(params.service_id, {
                start: moment(this.state.current_date).add(-2, "week"),
                end: moment(this.state.current_date).add(2, "week"),
                current: moment(this.state.current_date)
            }).catch((err) => {});
        }
        
    }

    componentDidMount() {
        let self = this;
        let params = this.props.params;
        
        DM.add(EventData.registerListener("EVENTS_LOADED", this.eventsLoaded), EventData);
        DM.add(EventData.registerListener("ORDER_CREATED", this.eventCreated), EventData);
        DM.add(EventData.registerListener("RECORD_UPDATED", this.eventUpdated), EventData);
        DM.add(EventData.registerListener("ORDER_DELETED", this.eventDeleted), EventData);
        // DM.add(EventData.registerListener("ORDER_CREATE_ERROR", this.onError), EventData);
        // DM.add(EventData.registerListener("ORDER_DELETE_ERROR", this.onError), EventData);
       
        let query = QueryString.parse(this.props.location.search);
        if(query.event_id) {
            let params = {
                event : { id: query.event_id },
            }
            EventData.getRecord(params.event.id).then((eventData) => {
                params.eventData = eventData
                return ServiceRateData.getRecordList(eventData.service.id).then((rates) => {
                    params.serviceRates = rates;
                    return params;
                })
            }).then((compiledParams) => {
                let currentDate = moment(compiledParams.eventData.start_date).hour(0).minute(0).second(0).millisecond(0);
                this.setState({ isEditing: compiledParams, isCreating: false, isError: false, current_date: currentDate });
                this.fetchEvents()
                let calendarAPI = this.calendarRef.current.getApi()
                calendarAPI.gotoDate(currentDate.toDate());
            }).catch((err) => {
                console.log(err)
                this.setState({ isCreating: false, isEditing: false, isError: err });
            })
        } else {
            this.fetchEvents()
        }
        this.reloadInterval = setInterval(() => { self.fetchEvents(); }, (1 * 60 * 1000));
        MetricsData.recordClientMetric({
            metric: "component-mount",
            feature: "single-service-calendar",
            numerical_value: 1,
            precision: 0,
            data: { service_id: params.service_id }
        })
    }
    componentWillUnmount() {
        clearInterval(this.reloadInterval);
        DM.clear();
    }

    eventsLoaded(results) {
        let smallest_duration = results.resource.duration;
        let pad = (string, target_size, padding) => {
            if(string.length < target_size) { 
                return `${Array(target_size - string.length).fill(padding).join("")}${string}`; 
            }
            return string;
        }
        let minutes = pad(`${Math.floor(smallest_duration % 60)}`, 2, "0")
        let hours = pad(`${Math.floor(smallest_duration / 60)}`, 2, "0")
        let slot_duration = `${hours}:${minutes}:00`
        if (smallest_duration > 60) { slot_duration = '01:00:00' }
        if (smallest_duration <= 15) { slot_duration = '00:15:00'  }
        this.setState({
            resource: results.resource,
            events: results.events,
            group: results.group,
            slot_duration: slot_duration
        })
    }

    dateClicked(params) {
        const self = this;
        const resource = self.state.resource;
        if(resource.service.type == service_types.WALKUP ) { return; }
    
        // This coersion fixes an issue with FullCalendar.io not having proper timezone support.
        let utcDate = moment(params.date).subtract(moment(params.date).tz(AppData.timezone()).utcOffset(), "minutes").toDate()
        params.date = utcDate
        // End Coersion

        EventData.canScheduleAtDate(params.date, resource.service).then(() => {
            return UserData.getRecord(AppData.get("userID"));
        }).then((user) => {
            return ServiceRateData.getRecordList(resource.id).then((rates) => {
                return { user: user, rates: rates };
            });
        }).then(({user, rates}) => {
            params.resource = resource;
            params.defaultUser = user;
            params.serviceRates = rates;
            params.defaultAccount = null;
            self.setState({ isCreating: params, isEditing: false, isError: false });
        }).catch((err) => {
            console.log(err)
            self.setState({ isCreating: false, isEditing: false, isError: err });
        })

        
    }

    eventClicked(params) {
        EventData.getRecord(params.event.id).then((eventData) => {
            params.eventData = eventData
            return ServiceRateData.getRecordList(eventData.service.id).then((rates) => {
                params.serviceRates = rates;
                return params;
            })
        }).then((compiledParams) => {
            this.setState({ isEditing: compiledParams, isCreating: false, isError: false });
        }).catch((err) => {
            console.log(err)
            this.setState({ isCreating: false, isEditing: false, isError: err });
        })
        
    }

    onCreate(new_event_data) {
        this.setState({ actionInProgress: true })
        EventData.createRecord(new_event_data).then((event) => {
            if(event.service.reopen_event_on_create === 1) {
                this.eventClicked({ event: event })
            }
        }).catch((err) => {
            this.setState({ actionInProgress: false });
        });
    }

    onUpdate(updated_event_data) {
        this.setState({ actionInProgress: true })
        EventData.updateRecord(updated_event_data).catch((err) => {
            this.setState({ actionInProgress: false });
        });
    }

    onDelete(event) {
        if(confirm("Are you sure you want to delete this event?")) {
            this.setState({ actionInProgress: true })
            EventData.deleteRecord(event).catch((err) => {
                this.setState({ actionInProgress: false });
            });
        }
        
    }

    onEventDragEnd(info) {
        EventData.checkCollision({
            id: info.event.id,
            service_id: this.state.resource.service.id,
            start_date: moment(info.event.start),
            end_date: moment(info.event.end)
        }).then((collisions) => {
            if(collisions.collisions.length > 0) { throw { message: "The attempted move collides with another event. Move Reverted." }; }
            return EventData.getRecord(info.event.id);
        }).then((eventData) => {
            eventData.start_date = moment(info.event.start)
            eventData.end_date = moment(info.event.end)
            return eventData;
        }).then((eventData) => {
            return EventData.updateRecord(eventData);
        }).then((event) => {
            info.event.setStart(moment(event.start_date).toDate())
            info.event.setEnd(moment(event.end_date).toDate())
        }).catch((err) => {
            this.setState({ isError: err });
            info.event.setStart(info.oldEvent.start)
            info.event.setEnd(info.oldEvent.end)
        })
    }

    eventCreated() {
        this.setState({ isCreating: false, isEditing: false, isError: false, actionInProgress: false });
        this.fetchEvents()
    }

    eventDeleted() {
        this.setState({ isCreating: false, isEditing: false, isError: false, actionInProgress: false });
        this.fetchEvents()
    }

    eventUpdated() {
        this.setState({ isCreating: false, isEditing: false, isError: false, actionInProgress: false });
        this.fetchEvents()
    }

    onError (error) {
        this.setState({ isCreating: false, isEditing: false, isError: { message: JSON.stringify(error), actionInProgress: false } });
        this.fetchEvents()
    }

    render() {
        let self = this;
        let isManager = false;
        if (this.state.resource.service !== undefined && this.state.resource.service.group_id !== undefined) { isManager = AppData.authorize(20, this.state.resource.service.group_id) }
        
        let serviceBusinessHours = EventData.getBusinessHours(this.state.current_date, this.state.resource.service);
        let displayElement = (<FullCalendar
            timeZone={AppData.timezone()}
            ref={this.calendarRef}
            initialView={self.state.activeView}
            initialDate={self.state.current_date.toDate()}
            height={"100%"}
            contentHeight={"auto"}
            plugins={[resourceTimeGridPlugin, timeGridPlugin, interactionPlugin]}
            events={self.state.events}
            schedulerLicenseKey={AppData.get("fullcalendar_key")}
            dateClick={self.dateClicked}
            eventClick={self.eventClicked}
            slotDuration={self.state.slot_duration}
            titleFormat={{
                month: 'long',
                year: 'numeric',
                day: 'numeric',
                weekday: 'long'
            }}
            
            eventDurationEditable={false}
            eventStartEditable={isManager}
            eventResourceEditable={false}
            snapDuration={self.state.slot_duration}
            eventOverlap={false}
            eventDrop={this.onEventDragEnd}

            customButtons={{
                datePicker: {
                    text: "pick day",
                    click: () => { self.setState({ isPickingDate: true }); }
                },
                allServices: {
                    text: "all services",
                    click: () => { window.location = `#/group-calendar/${self.state.group.id}`; }
                }
            }}
            headerToolbar={{
                right: "today datePicker allServices prev,next"
            }}
            datesSet={(info) => {
                this.fetchEvents(moment(info.view.currentStart));
            }}
            businessHours={serviceBusinessHours}
            eventTimeFormat={{
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
            }}
            selectAllow={(info) => { console.log(info); return true; }}
        />)
        return (<div>
            <div className="crs calendar title">
                <H1>{self.state.resource.title}</H1>
            </div>
            <div className="crs calendar container">
                {displayElement}
            </div>
            <div>
                <Dialog isOpen={self.state.isCreating !== false}
                    title={"Create New Event"}
                    onClose={() => { self.setState({ isCreating: false }) }}>
                    <div className={Classes.DIALOG_BODY}>
                        <EventDetailForm 
                            clickParams={self.state.isCreating} 
                            resources={[this.state.resource]}
                            onCancel={() => { self.setState({ isCreating: false })}}
                            onSubmit={self.onCreate}
                            actionInProgress={self.state.actionInProgress}
                        />
                    </div>
                </Dialog>

                <Dialog title={(self.state.isEditing !== false ? `Editing ${self.state.isEditing.eventData.service.name} (${self.state.isEditing.eventData.order_id}-${self.state.isEditing.eventData.id})`: "Editing Event")} isOpen={self.state.isEditing !== false} onClose={() => { self.setState({ isEditing: false }) }}>
                    <div className={Classes.DIALOG_BODY}>
                        <EventDetailForm
                            clickParams={self.state.isEditing}
                            events={self.state.events}
                            onCancel={() => { self.setState({ isEditing: false }) }}
                            onSubmit={self.onUpdate}
                            onDelete={self.onDelete}
                            actionInProgress={self.state.actionInProgress}
                        />
                    </div>
                </Dialog>

                <Dialog isOpen={self.state.isError !== false} title="Event Edit/Creation Error" onClose={() => { self.setState({ isError: false, actionInProgress: false }) }}>
                    <div className={Classes.DIALOG_BODY}>
                        {self.state.isError?.message || "Unknown Error - No Message Provided"}
                    </div>
                </Dialog>

                <Dialog isOpen={self.state.isPickingDate !== false} title="Pick Date" onClose={() => { self.setState({ isPickingDate: false }) }}>
                    <div className={Classes.DIALOG_BODY}>
                        <DatePicker 
                            maxDate={moment().add(2, "years").toDate()} 
                            onChange={(newDate) => {
                            let calendarAPI = this.calendarRef.current.getApi()
                            calendarAPI.gotoDate(newDate);
                        }} />
                    </div>
                </Dialog>
            </div>
        </div>
        );
    }
}

import withRouter from "lib/withRouter";
let Page = withRouter(ServiceCalendarPage)
export default Page;


'use strict';

import React from "react";
import CustomFormData from "data-modules/CustomFormData"
import moment from "moment";
import isEmail from "validator/lib/isEmail";

import AppData from "data-modules/AppData";
import { Classes, Spinner, Button, RadioGroup, Radio, Dialog, Intent } from "@blueprintjs/core";
import SecureForm from "generic-components/SecureForm";
import EntryDetailField from "./EntryDetailField"

class EntryDetailForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
           entry : null,
           isDirty: false,
           showing_user_details: false
        };

        this.componentDidMount = this.componentDidMount.bind(this)
        this.getFieldValue = this.getFieldValue.bind(this)
        this.setFieldValue = this.setFieldValue.bind(this)
        this.updateProcessingStatus = this.updateProcessingStatus.bind(this)
        this.updateForm = this.updateForm.bind(this)
        this.optionIsChecked = this.optionIsChecked.bind(this);
        this.setCheckedOption = this.setCheckedOption.bind(this);
        this.resendSignatureRequest = this.resendSignatureRequest.bind(this);
    }

    componentDidMount() {
        let self = this;
        CustomFormData.getForm(self.props.params.entry_id).then((form) => { 
            self.setState({ entry: form }) 
        });
    }

    formIsValid(new_data) {
        let self = this;
        let test_data = ((d) => {
            if (typeof d !== "undefined") { return d; }
            return self.state.data;
        })(new_data);
        let config = this.state.entry.custom_form_config;
        let fields = config.config_data.fields;
        return fields.reduce((isValid, field, index) => {
            let test_value = test_data[field.key];
            if( field.type == "markdown_block") { return isValid; }
            if (field.type == "section_divider") { return isValid; }
            if ( field.required == "yes" || field.type == "signature_block" ) {
                if (test_value == undefined) { return false; }
                if (field.type == "signature_block" && !isEmail(`${test_value}`)) { return false; }
                if (test_value == null || typeof test_value == "undefined") { console.log("Invalid - ud", field); return false; }
                if (test_value.length < 1) { console.log("Invalid - l", field); return false; }
                if (test_value == "") { console.log("Invalid - str", field); return false; }
            }
            return isValid;
        }, true)
    }

    setFieldValue(field, event) {
        let self = this;
        let data = self.state.entry.form_data;
        let config = self.state.entry.custom_form_config.config_data;

        let allow_edit = false;
        if (AppData.authorize(20, self.state.entry.service.group_id)) { allow_edit = true; }
        if (config.allow_editing === "yes:received" && self.state.entry.processing_status === "received") { allow_edit = true; }
        if (!allow_edit) { return; }
        data[field.key] = event.target.value;
        let entry = self.state.entry;
        entry.form_data = data;
        self.setState({ entry: entry, content_valid: self.formIsValid(data), isDirty: true });
    }

    setCheckedOption(field, option, event) {
        let self = this;
        let data = self.state.entry.form_data;
        let value_storage = data[field.key];
        if(typeof value_storage !== "string") { value_storage = ""; }
        let values = value_storage.split(",");
        let optionIndex = values.indexOf(option.value);
        if (optionIndex == -1) { values.push(option.value); }
        if (optionIndex > -1) { values.splice(optionIndex, 1); }
        data[field.key] = values.join(",")
        values.filter((v) => { return v.length > 0 });
        let entry = self.state.entry;
        entry.form_data = data;
        self.setState({ entry: entry, content_valid: self.formIsValid(data), isDirty: true });
    }

    getFieldValue(field) {
        let self = this;
        let data = self.state.entry.form_data;
        return data[field.key];
    }

    optionIsChecked(field, option) {
        let self = this;
        let data = self.state.entry.form_data;
        let field_data = data[field.key];
        if (field_data == null) { return false; }
        if (typeof field_data == "undefined") { return false; }
        if (field_data.length == 0) { return false; }
        let values = field_data.split(",")
        return values.includes(option.value);
    }

    updateProcessingStatus(event) {
        let self = this;
        let new_status = event.target.value;
        CustomFormData.setProcessingStatus(self.state.entry.id, new_status).then((new_status) => {
            let form = self.state.entry;
            form.processing_status = new_status;
            self.setState({ entry: form, isDirty: true });
        }).catch((err) => {
            console.log(err)
            alert("There was an error updating the processing status of this form. Please check the browser developer console for more details");
        })
    }

    updateForm() {
        let self = this;
        if (confirm("Are you sure you want to update this form?")) {
            CustomFormData.saveData(self.state.entry).then((form) => {
                let entry = self.state.entry;
                entry.form_data = form.form_data;
                alert("Updates Saved");
                self.setState({
                    entry: entry,
                    content_valid: true,
                    isDirty: false
                })
            }).catch((err) => {
                console.log(err)
            })
        }
    }

    resendSignatureRequest(field) {
        let self = this;
        let form = self.state.entry;
        let email = self.getFieldValue(field)
        if(confirm("Are you sure you want to resend the signature request to "+email+"?")) {
            CustomFormData.resendSignatureRequest(form, field.key).then((payload) => {
                alert("Signature request resent.")
            }).catch((err) => {
                console.log(err);
                if(err.code == "EENVELOPE") {
                    alert(`Form Updated - Invalid or Missing Signature Target Email. Unable to send signature request email. Please Check Signature Emails For Validity (${err.response})`);
                } else {
                    alert(`Error sending request: ${(err.message || JSON.stringify(err))}`);
                }
                
            })
        }
    }

    render() {
        let self = this;
        if (self.state.entry == null) { return (<div> <Spinner /> </div>); }
        let form = self.state.entry;
        let user = form.user;
        let service = form.service;
        let config = self.state.entry.custom_form_config;
        let tags = form.tags;
        
        let fieldElements = config.config_data.fields.map((field, index) => {
            return <EntryDetailField key={"field_" + index} field={field} 
                getFieldValue={self.getFieldValue} 
                setFieldValue={self.setFieldValue}
                optionIsChecked={self.optionIsChecked}
                setCheckedOption={self.setCheckedOption}
                resendSignatureRequest={self.resendSignatureRequest}
                tags={tags}
            />
        })

        let formControls = (() => {
            let isManager = AppData.authorize(20, service.group_id);
            if(isManager) {
                return (<div style={{ borderRadius: 10, backgroundColor: "rgba(0,0,0,0.1)", padding: 10, marginBottom: 20 }}>
                    <h2 className={Classes.HEADING}>Manager Actions</h2>
                    <RadioGroup inline={true} label="Processing Status" selectedValue={form.processing_status} onChange={self.updateProcessingStatus}>
                        <Radio label="Received" value="received" />
                        <Radio label="In Process" value="in_process" />
                        <Radio label="Completed" value="completed" />
                        <Radio label="Archived" value="archived" />
                    </RadioGroup>
                </div>)
            }
            return (<div></div>);
        })()   

        let updateButton = (() => {
            let data = self.state.entry.form_data;
            let config = self.state.entry.custom_form_config.config_data;
            let allow_edit = false;
            if (AppData.authorize(20, self.state.entry.service.group_id)) { allow_edit = true; }
            if (config.allow_editing === "yes:received" && self.state.entry.processing_status === "received") { allow_edit = true; }
            if (!allow_edit) { return <span></span>; }

            if (self.formIsValid(data) && self.state.isDirty) { return <Button intent="primary" onClick={self.updateForm}>Update</Button>; }
            return (<Button intent="primary" disabled={true}>Update</Button>);
        })()

        return (<div style={{ width: "100%", paddingLeft: "10%", paddingRight: "10%", paddingTop: 20 }}> 
            {formControls}
            <div style={{ borderRadius: 10, backgroundColor: "rgba(0,0,0,0.1)", padding: 10 }}>
                
                <h2 className={Classes.HEADING}> {config.config_data.title} </h2>
                <SecureForm method="post" action={"/form/" + self.state.entry.id + "/print"} token={AppData.get("authToken")}>
                    <Button type="submit" small={true} intent={Intent.PRIMARY} style={{ marginRight: 5 }}> Download PDF </Button>
                </SecureForm>
                <div style={{ paddingBottom: 10, paddingTop: 10 }}>
                    <span style={{ fontWeight: "bold" }}>Submitted By</span> : <span style={{ fontWeight: "bold", color: "blue", textDecoration: "underline" }} onClick={() => { self.setState({ showing_user_details: true }) }}>{user.full_name}</span> on <span style={{ fontWeight: "bold" }}>{moment(form.createdAt).format("YYYY-MM-DD")} [{moment(form.createdAt).format("dddd, MMMM Do, YYYY")}]</span>
                    <Dialog title="Submitter Details" isOpen={self.state.showing_user_details} onClose={() => { self.setState({ showing_user_details: false }) }}>
                        <div style={{padding: 20}}>
                            <div>
                                Name : {user.full_name}
                            </div>
                            <div>
                                Email : {user.email}
                            </div>
                            <div>
                                Phone Number : {user.phone_number}
                            </div>
                        </div>
                    </Dialog>
                </div>
                <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "flex_start" }}>
                    {fieldElements}
                </div>
                {updateButton}
            </div>
        </div>)
    }
}

import withRouter from "lib/withRouter";
let Page = withRouter(EntryDetailForm)
export default Page;
var request = require('superagent');
import AppData from "data-modules/AppData";

let errResponseHandler = (res) => {
    if(res.statusCode >= 400) { 
        let { message, debug } = res?.body || {};
        switch(res.statusCode) {
            case 500: { AppData.notifyListeners("REQUEST_SERVER_ERROR", { message: message, debug: debug }); } break;
            case 502: { AppData.notifyListeners("REQUEST_GATEWAY_ERROR", { message: "Application gateway server is offline. Try again later.", debug: {} }); } break;
            case 400: { AppData.notifyListeners("REQUEST_SUBMISSION ERROR", { message: message, debug: debug }); } break;
            case 401: { AppData.notifyListeners("REQUEST_AUTHORIZATION_DENIED", { message: message, debug: debug }); } break;
            case 404: { AppData.notifyListeners("REQUEST_RESOURCE_MISSING", { message: message, debug: debug }); } break;
        }
        return false; 
    }
    return true;
}

export function get () { return request.get(...arguments).ok(errResponseHandler); } 
export function post () { return request.post(...arguments).ok(errResponseHandler); }
export function put () { return request.put(...arguments).ok(errResponseHandler); }
export function del () { return request.delete(...arguments).ok(errResponseHandler); }
export function patch () { return request.patch(...arguments).ok(errResponseHandler); }
export function getErrorPayload (err) { 
    let payload = err?.response?.body;
    if(payload === undefined) { return { message: "No server error message available", debug: err.response }}
    return payload;
}
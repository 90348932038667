
	'use strict';

	/*

		News Module Event Bus

	*/

	import moment from "moment";
	import Eventer from "../Eventer";
  import queryString from 'query-string';
  import { get, post, put, patch, del, getErrorPayload  } from "data-modules/SystemREST";
  var NewsData = Eventer.createBus("NEWS");
  // AppData.injectJWTErrorListener(NewsData);

	var _dataCache = {};

	NewsData.listMapping = {
		title : function (item) { return item.title; },
		subtitle : function (item) { return moment(item.timestamp, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD HH:mm:ss");}
	};

	NewsData.searchConfig = { options: [
		{value: "title", title: "Title", type: "text"}
	]};

	NewsData.getRecordList = function (opts) {

    let options = opts || {};
    let queryOptions = {};

    // Apply pagination if exists
    if(options._page && options._pageSize) {
      queryOptions._pageSize = options._pageSize;
      queryOptions._page = options._page;
    }

    // Apply query options if it exists
    if(options.where) { queryOptions = Object.assign(queryOptions, options.where) }

    // Apply Group Limiter
    if(options.group_id) { queryOptions.group_id = options.group_id; }

    let requestURL = `/news`;
    if(queryString.stringify(queryOptions).length > 0) { requestURL = `${requestURL}?${queryString.stringify(queryOptions)}`}
    
    return get(requestURL).send().then(function(res) {
      var payload = res.body;
      NewsData.notifyListeners("LIST_LOADED", { records: payload.news, count: payload.count });
      return Promise.resolve({ records: payload.news, count: payload.count });
    }, function (err) {
      NewsData.notifyListeners("LIST_LOAD_ERROR", getErrorPayload(err));
      return Promise.reject(getErrorPayload(err));
    });

  };

	NewsData.getRecord = function (record_id) {
		get("/news/"+record_id).send().then(function(res) {
      var payload = res.body;
      NewsData.notifyListeners("RECORD_LOADED", payload.item);
		}, function (err) {
			NewsData.notifyListeners("RECORD_LOAD_ERROR", getErrorPayload(err));
		});
	};

	NewsData.updateRecord = function (recordData) {
    return post("/news/update").send({item : recordData}).then(function(res) {
      var payload = res.body;
      delete _dataCache.services; // Clear Data Cache
      NewsData.notifyListeners("RECORD_UPDATED", payload.item);
      return Promise.resolve(payload.item);
    }, function (err) {
      NewsData.notifyListeners("RECORD_UPDATE_ERROR", getErrorPayload(err));
      return Promise.reject(getErrorPayload(err));
    });

  };

	NewsData.createRecord = function (recordData) {
    return post("/news/create").send({item : recordData}).then(function(res) {
      var payload = res.body;
      delete _dataCache.services; // Clear Data Cache
      NewsData.notifyListeners("RECORD_CREATED", payload.item);
      return Promise.resolve(payload.item);
    }, function (err) {
      NewsData.notifyListeners("RECORD_CREATE_ERROR", getErrorPayload(err));
      return Promise.reject(getErrorPayload(err));
    });

  };

  export default NewsData;
